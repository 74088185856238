import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {hover, darkGreen} from '../globalStyleVars';
import mainLogo from '../assets/images/Logo.svg';
import hambuerger from '../assets/images/menu-hambuerger.svg';
import hambuergerWhite from '../assets/images/close.svg';
import {Link,useLocation} from 'react-router-dom';
import menuBg from '../assets/images/dynamic/about-detail.jpg';
import mobileMenuBg from '../assets/images/MobileMenuBg.jpg';
import $ from 'jquery';
import {setSectionId} from "../redux/actions/menu";
import {connect} from 'react-redux'
import FacebookLogo from "../assets/images/facebook.svg";
import LinkedinLogo from "../assets/images/linkedin.svg";
import InstagramLogo from "../assets/images/instagram.svg";
import TwitterLogo from "../assets/images/twitter.svg";
import Youtube from "../assets/images/youtube.svg";

const Menu = ({logo, color, img, setSectionId, menuType }) => {
    let [menuItems, setMenuItems] = useState(false);
    let [newClass, setNewClass] = useState(menuType);
    let [mobileSlide, setMobileSlide] = useState('');

    let handleMenuToggle = (e) => {
        e.stopPropagation();
        setMenuItems(!menuItems);
    };

    let handleClose = () => {
        setMenuItems(false)
    };

    let {pathname} = useLocation();
    let splitPathName = pathname.split("/");

    useEffect(() => {
        window.addEventListener('scroll', function () {
            if (window.pageYOffset > 100) {
                setNewClass('menu-fixed')
            } else {
                setNewClass(menuType)
            }
        });

        // menu click on mobile
        $('.mobile-menu .menuItems__top__single').each(function () {
            let $this = $(this);
            if($this.find('ul').length > 0){
                $this.addClass('hasChild')
            }
        });

        $('.hasChild').on('click',function () {
            var $this_ul = $(this).find('ul'),
                $this = $(this);
            $('.hasChild ul').not($this_ul).slideUp();
            $('.hasChild').not($this).removeClass('active');
            $this_ul.slideToggle();
            $this.toggleClass('active');
        });

        $('.mobile-menu .menuItems__top__single li a').click(function () {
            $('.menu-items').removeClass('true');
            $('.menu-bar').removeClass('true');

        })



    }, []);

    return (
        <div>
            <StyledMenu className={`menu-bar ${newClass} ${menuItems}`} color={color}>
                <Container>
                    <Row>
                        <Col className="menu-bar__logo">
                            <Link to="/"><img src={logo || mainLogo} alt=""/></Link>
                        </Col>
                        {/*<Col className="menu-bar__logo"><a href="/"><img src={logo || mainLogo} alt=""/></a> </Col>*/}
                        <Col className="menu-bar__menu-lists">
                            <ul onClick={handleClose}>

                                <li onClick={handleMenuToggle}>
                                    <a href="javascript:void(0)">
                                        <img src={img || hambuerger} alt=""/>
                                        <img src={hambuergerWhite} alt=""/>
                                    </a>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </StyledMenu>

            {/*toggle menu */}


            <StyleToggleMenu className={`menu-items ${menuItems}`}>
                <Container>
                    <div className="desktop-menu">
                        <div className="menuItems__top" onClick={handleMenuToggle}>

                            <div className="menuItems__top__bg">
                                <img src={menuBg} alt=""/>
                            </div>

                            <div className="menuItems__top__single">
                                <Link className={splitPathName[1] === '' ? 'active' : ''} to={'/'}>Home</Link>
                                <Link className={splitPathName[1] === 'about-us' ? 'active' : ''} to={'/about-us'}>About Us</Link>
                                <Link className={splitPathName[1] === 'product' ? 'active' : ''} to={'/product'}>Products</Link>
                                {/*<Link className={pathname === '/equipments' ? 'active' : ''} to={'/equipments'}>Equipments</Link>*/}
                                <Link className={splitPathName[1] === '/quality-assurance' ? 'active' : ''} to={'/quality-assurance'}>Quality Assurance</Link>
                            </div>


                            <div className="menuItems__top__single">
                                <Link className={splitPathName[1] === 'key-information' ? 'active' : ''} to={'/key-information'}>Key Informations</Link>
                                <Link className={splitPathName[1] === 'cost-reduction-chart' ? 'active' : ''} to={'/cost-reduction-chart'}>Cost Reduction Chart</Link>
                                <Link className={splitPathName[1] === 'sister-concerns' ? 'active' : ''} to={'/sister-concerns'}>Group Concerns</Link>
                                <Link className={splitPathName[1] === 'news-events' ? 'active' : ''} to={'/news-events'}>News & Events</Link>
                                <Link className={splitPathName[1] === 'clients' ? 'active' : ''} to={'/clients'}>Clients</Link>
                            </div>


                            <div className="menuItems__top__single">
                                <Link className={splitPathName[1] === 'gallery' ? 'active' : ''} to={'/gallery'}>Gallery</Link>
                                <Link className={splitPathName[1] === 'career' ? 'active' : ''} to={'/career'}>Career</Link>
                                <Link className={splitPathName[1] === 'contact-us' ? 'active' : ''} to={'/contact-us'}>Contact</Link>
                            </div>

                        </div>
                        <ul className="menuItems__social">
                            <li>
                                <Link to={{ pathname: "https://www.facebook.com/mirconcreteblock" }} target="_blank">
                                    <img src={FacebookLogo} alt="Facebook Logo"/>
                                </Link>
                            </li>
                            <li>
                                <Link to={{ pathname: "https://www.linkedin.com/company/72345419/" }} target="_blank">
                                    <img src={LinkedinLogo} alt="LinkedIn Logo"/>
                                </Link>
                            </li>
                            <li>
                                <Link to={{ pathname: "https://www.instagram.com/mirconcreteblock/" }} target="_blank">
                                    <img src={InstagramLogo} alt="Instagram Logo"/>
                                </Link>
                            </li>
                            <li>
                                <Link to={{ pathname: "https://twitter.com/MirConcrete" }} target="_blank">
                                    <img src={TwitterLogo} alt="Instagram Logo"/>
                                </Link>
                            </li>
                            <li>
                                <Link to={{ pathname: "https://www.youtube.com/channel/UCZ9So7llxKfRH8vkkm9TTew" }} target="_blank">
                                    <img src={Youtube} alt="Youtube Logo"/>
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div className="mobile-menu" >
                        <div className="menuItems__top">
                            <div className={`menuItems__top__single`}>
                                <Link className={splitPathName[1] === '' ? 'active' : ''}  to={'/'}>Home</Link>
                                <Link className={splitPathName[1] === 'about-us' ? 'active' : ''}  to={'/about-us'}>About Us</Link>
                                <Link className={splitPathName[1] === 'product' ? 'active' : ''}  to={'/product'}>Products</Link>
                                <Link className={splitPathName[1] === 'equipments' ? 'active' : ''}  to={'/equipments'}>Equipments</Link>
                                <Link className={splitPathName[1] === 'quality-assurance' ? 'active' : ''}  to={'/quality-assurance'}>Quality Assurance</Link>
                                <Link className={splitPathName[1] === 'key-information' ? 'active' : ''}  to={'/key-information'}>Key Informations</Link>
                                <Link className={splitPathName[1] === 'clients' ? 'active' : ''}  to={'/clients'}>Clients</Link>
                                <Link className={splitPathName[1] === 'sister-concerns' ? 'active' : ''}  to={'/sister-concerns'}>Sister Concerns</Link>
                                <Link className={splitPathName[1] === 'career' ? 'active' : ''}  to={'/career'}>Career</Link>
                                <Link className={splitPathName[1] === 'news-events' ? 'active' : ''}  to={'/news-events'}>News & Events</Link>
                                <Link className={splitPathName[1] === 'contact-us' ? 'active' : ''} to={'/contact-us'}>Contact Us</Link>
                            </div>
                            <ul className="menuItems__social">
                                <li>
                                    <Link to={{ pathname: "https://www.facebook.com/mirconcreteblock" }} target="_blank">
                                        <img src={FacebookLogo} alt="Facebook Logo"/>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={{ pathname: "https://www.linkedin.com/company/72345419/" }} target="_blank">
                                        <img src={LinkedinLogo} alt="LinkedIn Logo"/>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={{ pathname: "https://www.instagram.com/mirconcreteblock/" }} target="_blank">
                                        <img src={InstagramLogo} alt="Instagram Logo"/>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={{ pathname: "https://twitter.com/MirConcrete" }} target="_blank">
                                        <img src={TwitterLogo} alt="Instagram Logo"/>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={{ pathname: "https://www.youtube.com/channel/UCZ9So7llxKfRH8vkkm9TTew" }} target="_blank">
                                        <img src={Youtube} alt="Youtube Logo"/>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Container>
            </StyleToggleMenu>


        </div>
    )
};
const StyledMenu = styled.div`
    position: fixed;
    z-index: 9;
    width: 100%;
    top: 0;
    height: 80px;
    align-items: center;
    display: flex;
    transition:background-color .3s ease;
    .row{
      align-items: center;
    }
    &.menu-fixed{
      background-color: #314550;
      .menu-bar__menu-lists{
          ul{
            li{
              a{
                &:hover,&.active{
                  color: ${darkGreen};
                }
              }
              
            }
          }
      }
    }
     &.true{
      background-color: transparent;
    }
    .menu-bar__logo{
      img{
        height: 54px;
        object-fit: contain;
      }
    }
    
    .menu-bar__menu-lists{
      ul {
      display: flex;
      justify-content: flex-end;
      align-items: center;
        li{
          a{
            font-size: 18px;
            font-weight: bold;
            line-height: 27px;
            text-transform: capitalize;
            color:${props => props.color || '#ffffff'};
            transition:color .3s ease;
            &:hover,&.active{
              color:${hover};
            }
          }
          &:nth-last-of-type(1) a{
            transition:opacity .4s ease;
            transition-delay: 0s;
            &:hover{
              opacity:.4 !important;
            }
          }
          &:nth-last-of-type(1) a{
            img:nth-last-of-type(1){
              display: none;
            }
          }
        }
      }
    }
    
    &.true{
      li:not(:last-child) a{
       opacity: 0 !important;
       visibility: hidden !important;
      }
      li:nth-last-of-type(1) a img{
        &:nth-of-type(1){
          display: none;
        }
        &:nth-of-type(2){
          display: block !important;
        }
      }
    }
    
    @media(max-width:991px){
      .menu-bar__menu-lists{
        ul{
          justify-content: flex-end;
           li:not(:last-child){
              display: none;
           }
        }
      }
    }
    
    @media(max-width:767px){
      height: 65px;
      .menu-bar__logo img{
        height: 45px;
      }
    }
`;

const StyleToggleMenu = styled.div`
    position: fixed;
    z-index: 8;
    height: 100vh;
    width: 100%;
    background: #314550;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    transition:all .6s ease;
    &.true{
      opacity:1;
      visibility: visible;
      
    }
  .menuItems__top {
    display: flex;
    margin-top: 200px;
    max-height:600px;
    
    &__bg{
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      height: 100%;
      &:after{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0,0,0,0.8);
        right: 0;
        bottom: 0;
      }
      img{
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    &__single {
      position: relative;
      z-index: 3;
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      height: max-content;
      flex-direction: column;
      padding-right: 8px;
      > a {
        font-size: 25px;
        font-weight: 500;
        color: #ffffff;
        text-transform: capitalize;
        line-height: 30px;
        margin-bottom: 26px;
        transition:letter-spacing .6s ease;
        &:hover{
          letter-spacing: 2px;
        }
        &.active{
          color: ${hover};
        }
      }

      ul {
        text-align: left;
        li {
          a {
            display: block;
            font-size: 16px;
            text-transform: capitalize;
            color: #ffffff;
            margin-bottom: 18px;
            transition:padding .4s ease;
            &:hover{
              padding-left: 10px;
            }
          }

          &:nth-last-of-type(1) a {
            margin-bottom: 0;
          }
        }
      }

    }
  }
  .menuItems__social {
    display: flex;
    position: relative;
    z-index: 1;
    justify-content: flex-end;
    li{
      &:not(:last-child){
        margin-right: 15px;
      }
    }
  }
  
  @media(min-width:1440px){
    @media(min-width:800px){
       display: flex;
        align-items: center;
        .menuItems__top{
          //padding: 100px;
          margin-top: 0;
        }
    }
   
  }
  
  
  @media(min-width: 768px){
    .mobile-menu{
      display: none;
    }
  }
  
  @media(max-width: 767px){
    height: 100vh;
    padding-top: 30px;
    &.menu-items{
        background-image: url(${mobileMenuBg});
        background-position: center center;
        .menuItems__social{
            margin-top: 50px;
        }
    }
    .menuItems__top {
      height:auto;
      display: block;
      padding:0px 0px 0 0px;
      position: relative;
      min-height: 100vh;
      background-color: transparent;
      &__bg{
        display: none !important;
      }
      &__single {
        display: block;
        width: 100%;
        text-align: right;
        >a{
          font-size: 20px;
          font-weight: normal;
          line-height: 30px;
          display: block;
          margin-bottom: 15px
        }
        ul{
          display: none;
        }
      }
    }
    .Footer__social {
      margin-top: 50px;
    }
    .desktop-menu{
      display: none;
    }
    
    .mobile-menu{
      .menuItems__top__single.active > a{
        color: #ffffff;
        opacity: .7;
      }
    }
    
    .menuItems__top{
      margin-top: 60px;
    }
    
  }
  
  @media(max-width: 375px){
      .menuItems__top {
          &__single {
              >a{
                  font-size: 15px;
                  margin-bottom: 10px;
                  line-height: 20px;
              }
          }
      }
  }

  .menuItems__top__single{
    position: relative;
    &:after{
      content: '';
      background-image: url(${hambuergerWhite});
      background-size: 13px;
      position: absolute;
      right: -17px;
        height: 50px;
        width: 50px;
        top: -12px;
      background-repeat: no-repeat;
      background-position: center;
      transform: rotate(45deg);
      margin: auto;
      z-index: 3;
      display: none;
      transition: all .4s ease;
    }
    ul{
      margin-bottom: 30px;
    }
    &.hasChild{
      &:after{
        display: block;
      }
    }
    &.active{
      &:after{
        transform:none;
      }
      > a{
        color: ${hover};
      ul li a{
        color: #d4d4d4 !important;
      }
      }
    }
  

}
`;

function mapDispatchToProps(dispatch) {
    return {
        setSectionId: data => dispatch(setSectionId(data))
    }
}
export default connect(null, mapDispatchToProps)(Menu);
