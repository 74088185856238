import {GALLERY} from "../../constants/gallery";

export function fetchGallery(params) {
    return {
        type: GALLERY.FETCH_GALLERY.MAIN,
        params
    }
}


export function fetchPhoto(params) {
    return {
        type: GALLERY.FETCH_PHOTO_GALLERY.MAIN,
        params
    }
}

export function fetchVideo(params) {
    return {
        type: GALLERY.FETCH_VIDEO_GALLERY.MAIN,
        params
    }
}

