import {ABOUT} from "../../constants/about";

export function fetchAboutMain(params) {
  return {
    type: ABOUT.FETCH_ABOUT_PAGE.MAIN,
    params
  }
}

export function fetchAboutChairman(params) {
  return {
    type: ABOUT.FETCH_CHAIRMAN_MESSAGE.MAIN,
    params
  }
}

export function fetchAboutAchievement(params) {
  return {
    type: ABOUT.FETCH_ACHIEVEMENTS.MAIN,
    params
  }
}

export function fetchAboutDirectors(params) {
  return {
    type: ABOUT.FETCH_DIRECTORS.MAIN,
    params
  }
}
