import React, {Component} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {fetchHomeFeaturedData} from '../../../redux/actions/home/';
import {ApiParams} from "../../../constants/apiParams";
import {connect} from "react-redux";
import VisibilitySensor from "react-visibility-sensor";
import {baseUrl} from '../../../components/BaseUrl';
// Inner component
import Title from '../../../components/Title';
import Button from '../../../components/Button';
//Image
import BtnArrowWhite from '../../../assets/images/btn-arrow-white.svg';

import {
    fetchProductList
} from "../../../redux/actions/home";

class FeaturedProduct extends Component {
    componentDidMount() {
        this.props.getHomeFeaturedData()
        let productList = {
            [ApiParams.page_id]: '97'
        };
        this.props.getProductList(productList);
    }

    render() {
        let featured = this.props.featuredProducts;
        let {catList} = this.props;


        let productList = []

        catList.map(catItem => {
            let catPostlist = catItem?.posts?.list
            catPostlist.map(productPost => {
                productList.push(productPost);
            })
        });

        return (
            <StyledFeaturedProduct className="featured-product-section">
                <Container>
                    <div className="featured-product-wrapper">
                        <div className="featured-product-title">
                            <Title fontSize={'36px'} fontWeight={500} color={'#314550'} lineHeight={'46px'}
                                   margin={'0 0 40px 0'} textAlign={'center'}>
                                Featured <br/>Concrete Blocks
                            </Title>
                        </div>
                        {productList && productList.length > 0 ? (
                            <Row>
                                {productList.map(singleFeatured => {
                                    return singleFeatured.data?.featured_product === 'yes' ? (
                                        <VisibilitySensor key={singleFeatured.data.id}
                                                          partialVisibility={{top: 0, bottom: 60}}>
                                            {({isVisible}) =>
                                                <Col className={`${isVisible ? 'anim-active' : ''} fade-up`}
                                                     md={4} sm={6} xs={6}>
                                                    <div className="featured-project-single">
                                                        <Link to={'/product'}/>
                                                        <div className="featured-project-single__inner">
                                                            <div className="featured-project-single__inner__wrapper">
                                                                {
                                                                    singleFeatured.images ? (
                                                                        <>
                                                                            {
                                                                                singleFeatured.images.slice(0, 5).map((featuredImage, key) =>
                                                                                    featuredImage.is_thumb && featuredImage.is_thumb === "on" && key === 0 ?
                                                                                        <img key={featuredImage.id}
                                                                                             src={baseUrl + featuredImage.path + featuredImage.img_name}
                                                                                             alt=""/>

                                                                                        : ''
                                                                                )}
                                                                        </>
                                                                    ) : ''
                                                                }
                                                                <p>{singleFeatured.data.title}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            }
                                        </VisibilitySensor>
                                    ) : '';
                                })}
                            </Row>
                        ) : ''}
                    </div>
                    <Button text={'All Products'} link={'/product'} color={'#314550'} background={'#008C45'}
                            border={'2px solid #008C45'}
                            hoverBackground={'#008C45'} hoverColor={'#ffffff'} img={BtnArrowWhite} margin={'auto'}/>
                </Container>
            </StyledFeaturedProduct>
        );
    }
}

const StyledFeaturedProduct = styled.section`
  &.featured-product-section {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .featured-product-wrapper {
    padding-left: 130px;
    padding-right: 130px;
    padding-bottom: 10px;

    .featured-product-title {
      text-align: center;
    }

    .featured-project-single {
      padding-top: 100%;
      position: relative;
      background-color: #F5F5F5;
      margin-bottom: 30px;

      a {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 2;
        top: 0;
        left: 0;
      }

      &__inner {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        padding: 30px 0;

        &__wrapper {
          //align-items: center;
          display: block;
          //justify-content: space-between;
          //flex-direction: column;
          text-align: center;
          height: 100%;

          img {
            max-width: 70%;
            object-fit: contain;
            margin-bottom: 30px;
          }

          p {
            width: 70%;
            //position: absolute;
            bottom: 40px;
            margin: 0 auto;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .featured-product-wrapper {
      padding-left: 0;
      padding-right: 0;
    }
  }
  @media (max-width: 767px) {
    .featured-product-wrapper {
      .featured-project-single {
        &__inner {
          p {
            width: 100%;
            bottom: 10px;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: .25px;
          }
        }
      }
    }
  }
`;

function mapStateToProps(state) {
    return {
        featuredProducts: state.homeReducer.featuredData,
        catList: state.productReducer.productList
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getHomeFeaturedData: () => dispatch(fetchHomeFeaturedData()),
        getProductList: params => dispatch(fetchProductList(params)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FeaturedProduct);