import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import Title from '../../../components/Title';
import HtmlParser from 'react-html-parser';
import {baseUrl} from "../../../components/BaseUrl";


// img
import concernImg from '../../../assets/images/dynamic/concern/concern.jpg';
import concernImg1 from '../../../assets/images/dynamic/concern/concern-1.jpg';
import Text from "../../../components/Text";
import MissionImage from "../../../assets/images/dynamic/mission.jpg";

const EquipmentList = ({post}) => {

    return (
        <StyleEquipment className="pt-80 pb-80 mission-vision">
            <Container>
                <Row>
                    {post ? post.map(data => (
                        <div className="mission-vision__single" key={data.data.id}>
                            <Col md={7}>
                                <div className="mission-vision__single__left">
                                    <Title text={data.data.title} fontSize={'24px'} fontWeight={'500'}
                                           lineHeight={'32px'}
                                           letterSpacing={'.5px'} color={'#314550'} margin={'0 0 40px 0'}/>
                                    <Text color={'#314550'} fontSize={'16px'} fontWeight={'300'} lineHeight={'27px'}
                                          letterSpacing={'.5px'}>
                                        {HtmlParser(data.data.description)}
                                    </Text>
                                </div>
                            </Col>
                            <Col md={5}>
                                <div className="mission-vision__single__right">
                                    <img
                                        src={data?.images && baseUrl + data?.images[0]?.path + data?.images[0]?.img_name}
                                        alt=""/>
                                </div>
                            </Col>
                        </div>
                    )) : ''}

                </Row>
            </Container>
        </StyleEquipment>
    )
};

const StyleEquipment = styled.section`
  &.mission-vision {
    .mission-vision__single {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      //align-items: center;
      &:not(:last-child) {
        margin-bottom: 80px;
      }

      &:nth-of-type(odd) {
        flex-direction: row-reverse;

        .mission-vision__single__left {
          margin-left: 40px;
        }
      }

      &__left {
      }

      &__right {
        position: relative;
        padding-top: calc(350 / 500 * 100%);

        img {
          height: 100%;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          object-fit: cover;
        }
      }
    }
  }

  @media (max-width: 767px) {
    &.mission-vision {
      padding-top: 60px !important;
      padding-bottom: 60px !important;

      .mission-vision__single {
        &:not(:last-child) {
          margin-bottom: 60px;
        }

        &:nth-of-type(odd) {
          .mission-vision__single__left {
            margin-left: 0;
          }
        }

        &__left {
          margin-bottom: 20px;
        }
      }
    }
  }
`;
export default EquipmentList;
