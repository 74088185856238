import {HOME} from '../../constants/home'

const initialState = {
    bannerData: [],
    bannerLoading: false,
    bannerErr: '',
    aboutData: [],
    aboutLoading: false,
    aboutErr: '',
    featuredData: [],
    featuredLoading: false,
    featuredErr: '',
    counterData: [],
    counterLoading: false,
    counterErr: '',
    loading: false,
    businessData: [],
    businessLoading: false,
    businessErr: '',
    homeClients: [],
    clientLoading: false,
    clientErr: '',
    homeAchievements: [],
    achieveLoading: false,
    achieveErr: '',
    homeCertifications: [],
    certLoading: false,
    certErr: '',
    newsEvents: [],
    newsLoading: false,
    newsErr: '',
    message: '',
    aboutMessage: '',
    businessMessage: '',
    sustainability: []
};

export default function homeReducer(state = initialState, action) {
    switch (action.type) {
        case HOME.FETCH_HOME_BANNER_SLIDER.MAIN:
            return {
                ...state,
                bannerData: [],
                bannerLoading: true,
                bannerErr: '',
                message: '',
                loading: true
            };
        case HOME.FETCH_HOME_BANNER_SLIDER.SUCCESS:
            return {
                ...state,
                bannerData: action.result,
                bannerLoading: false,
                bannerErr: '',
                message: action.message,
                loading: false
            };
        case HOME.FETCH_HOME_BANNER_SLIDER.FAILURE:
            return {
                ...state,
                bannerData: [],
                bannerLoading: false,
                bannerErr: 'Error in banner slider',
                message: 'failure',
                loading: false
            };

        case HOME.FETCH_HOME_ABOUT_US.MAIN:
            return {
                ...state,
                aboutData: [],
                aboutLoading: true,
                aboutErr: '',
                aboutMessage: ''
            };

        case HOME.FETCH_HOME_ABOUT_US.SUCCESS:
            return {
                ...state,
                aboutData: action.result,
                aboutLoading: false,
                aboutErr: '',
                aboutMessage: action.message
            };

        case HOME.FETCH_HOME_ABOUT_US.FAILURE:
            return {
                ...state,
                aboutData: [],
                aboutLoading: false,
                aboutErr: 'Error in About us',
                aboutMessage: 'failure'
            };


        case HOME.FETCH_HOME_FEATURED.MAIN:
            return {
                ...state,
                featuredData: [],
                featuredLoading: true,
                featuredErr: '',
                featuredMessage: ''
            };

        case HOME.FETCH_HOME_FEATURED.SUCCESS:
            return {
                ...state,
                featuredData: action.result,
                featuredLoading: false,
                featuredErr: '',
                featuredMessage: action.message
            };

        case HOME.FETCH_HOME_FEATURED.FAILURE:
            return {
                ...state,
                featuredData: [],
                featuredLoading: false,
                featuredErr: 'Error in Feature Product',
                featuredMessage: 'failure'
            };

        case HOME.FETCH_HOME_COUNTER.MAIN:
            return {
                ...state,
                counterData: [],
                counterLoading: true,
                counterErr: '',
                counterMessage: ''
            };

        case HOME.FETCH_HOME_COUNTER.SUCCESS:
            return {
                ...state,
                counterData: action.result,
                counterLoading: false,
                counterErr: '',
                counterMessage: action.message
            };

        case HOME.FETCH_HOME_COUNTER.FAILURE:
            return {
                ...state,
                counterData: [],
                counterLoading: false,
                counterErr: 'Error in Home Counter',
                counterMessage: 'failure'
            };


        case HOME.FETCH_HOME_BUSINESS_DIVISIONS.MAIN:
            return {
                ...state,
                businessData: [],
                businessLoading: true,
                businessErr: '',
                businessMessage: ''
            };

        case HOME.FETCH_HOME_BUSINESS_DIVISIONS.SUCCESS:
            return {
                ...state,
                businessData: action.result,
                businessLoading: false,
                businessErr: '',
                businessMessage: action.message
            };
        case HOME.FETCH_HOME_BUSINESS_DIVISIONS.FAILURE:
            return {
                ...state,
                businessData: [],
                businessLoading: false,
                businessErr: '',
                businessMessage: 'failure'
            };

        case HOME.FETCH_HOME_CLIENTS.MAIN:
            return {
                ...state,
                homeClients: [],
                clientLoading: true,
                clientErr: ''
            };

        case HOME.FETCH_HOME_CLIENTS.SUCCESS:
            return {
                ...state,
                homeClients: action.result,
                clientLoading: false,
                clientErr: ''
            };
        case HOME.FETCH_HOME_CLIENTS.FAILURE:
            return {
                ...state,
                homeClients: [],
                clientLoading: false,
                clientErr: 'Error in Client Loading'
            };

        case HOME.FETCH_HOME_ACHIEVEMENTS.MAIN:
            return {
                ...state,
                homeAchievements: [],
                achieveLoading: true,
                achieveErr: ''
            };

        case HOME.FETCH_HOME_ACHIEVEMENTS.SUCCESS:
            return {
                ...state,
                homeAchievements: action.result,
                achieveLoading: false,
                achieveErr: ''
            };

        case HOME.FETCH_HOME_ACHIEVEMENTS.FAILURE:
            return {
                ...state,
                homeAchievements: [],
                achieveLoading: false,
                achieveErr: 'Error in Achievement section'
            };

        case HOME.FETCH_HOME_CERTIFICATIONS.MAIN:
            return {
                ...state,
                homeCertifications: [],
                certLoading: true,
                certErr: ''
            };

        case HOME.FETCH_HOME_CERTIFICATIONS.SUCCESS:
            return {
                ...state,
                homeCertifications: action.result,
                certLoading: false,
                certErr: ''
            };

        case HOME.FETCH_HOME_CERTIFICATIONS.FAILURE:
            return {
                ...state,
                homeCertifications: [],
                certLoading: false,
                certErr: ''
            };

        case HOME.FETCH_HOME_NEWS_EVENTS.MAIN:
            return {
                ...state,
                newsEvents: [],
                newsLoading: true,
                newsErr: ''
            };

        case HOME.FETCH_HOME_NEWS_EVENTS.SUCCESS:
            return {
                ...state,
                newsEvents: action.result,
                newsLoading: false,
                newsErr: ''
            };

        case HOME.FETCH_HOME_NEWS_EVENTS.FAILURE:
            return {
                ...state,
                newsEvents: [],
                newsLoading: false,
                newsErr: 'Error in news & events section'
            };

        case HOME.FETCH_HOME_SUSTAINABILITY.MAIN:
            return {
                ...state,
                sustainability: [],
                sustainabilityLoading: true,
                sustainabilityErr: ''
            };

        case HOME.FETCH_HOME_SUSTAINABILITY.SUCCESS:
            return {
                ...state,
                sustainability: action.result,
                sustainabilityLoading: false,
                sustainabilityErr: ''
            };

        case HOME.FETCH_HOME_SUSTAINABILITY.FAILURE:
            return {
                ...state,
                sustainability: [],
                sustainabilityLoading: false,
                sustainabilityErr: 'Error in news & events section'
            };
        default:
            return state
    }
}
