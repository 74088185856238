import {FORM} from "../../constants/form";

export function fetchFormData(params) {
  return {
    type: FORM.FETCH_FORM_DATA.MAIN,
    params
  }
}

export function postFormSubmit(body) {
  return {
    type: FORM.POST_FORM_DATA.MAIN,
    body
  }
}
