import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import _Button from '../../../components/_Button';
import Lines from '../../../components/VerticalLine';
import {
    LightgalleryProvider,
    LightgalleryItem,
    withLightgallery,
    useLightgallery
} from "react-lightgallery";
import PT from "prop-types";

// css
import "swiper/swiper.scss";
import "lightgallery.js/dist/css/lightgallery.css";

// img
import n1 from '../../../assets/images/dynamic/n1.jpg';
import n2 from '../../../assets/images/dynamic/n1.jpg';
import n3 from '../../../assets/images/dynamic/n1.jpg';
import {connect} from 'react-redux'
import arrowImg from '../../../assets/images/btn-arrow-black.svg'
import {baseUrl} from "../../../components/BaseUrl";
import PreloadImage from "react-preload-image";

const PhotoItem = ({image, thumb, group}) => (
    <div style={{maxWidth: "250px", width: "200px", padding: "5px"}}>
        <LightgalleryItem group={group} src={image} thumb={thumb}>
            <PreloadImage style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                backgroundColor: '#DDD'
            }} src={image}/>
            {/*<img src={image} style={{width: "100%"}}/>*/}
        </LightgalleryItem>
    </div>
);
PhotoItem.propTypes = {
    image: PT.string.isRequired,
    thumb: PT.string,
    group: PT.string.isRequired
};

const OpenButtonWithHoc = withLightgallery(({openGallery, ...props}) => {
    return (
        <button
            {...props}
            onClick={() => {
                openGallery("group1");
            }}
            className={["button is-primary", props.className || ""].join(" ")}
        >
            Open first photos group (using hoc)
        </button>
    );
});

const OpenButtonWithHook = props => {
    const {openGallery} = useLightgallery();
    return (
        <button
            {...props}
            onClick={() => openGallery("group2")}
            className={["button is-primary", props.className || ""].join(" ")}
        >
            Open second photos group (using hook)
        </button>
    );
};
OpenButtonWithHook.propTypes = {
    className: PT.string
};

const Photo = ({slug, mediaPageList}) => {
    let mediaPage = mediaPageList ? mediaPageList.find(media => {
        if (media.page_data.slug === slug) return media
    }) : null

    let mediaImages = mediaPage ? mediaPage.images ? mediaPage.images.list : [] : []

    return (
        <StyledPhotos>
            <Lines/>
            <Container className="photos">
                <Row>
                    <LightgalleryProvider
                        lightgallerySettings={
                            {
                                download: false,
                                thumbnail: false,
                                fullScreen: false,
                                share: false
                            }
                        }
                    >
                        {mediaImages.length ? mediaImages.map(image => {
                            let imageLink = baseUrl + image.path + image.img_name
                            return (
                                <Col key={image.id} md={4}>
                                  <OpenButtonWithHook />
                                </Col>
                            )
                        }) : null}
                    </LightgalleryProvider>
                </Row>

                <Row>
                    <Col>
                        {/*<div onClick={() => handleLoadMore()}>*/}
                        {/*  <Button width={'158px'} text={'Load More'}/>*/}
                        {/*</div>*/}
                    </Col>
                </Row>
            </Container>
        </StyledPhotos>

    )
};


const StyledPhotos = styled.div`
  padding-top: 60px;
  padding-bottom: 110px;
  position: relative;

  .photos {
    &__single {
      margin-bottom: 60px;
      position: relative;
      z-index: 3;

      &__content {
        h4 {
          font-size: 14px;
          text-transform: uppercase;
          font-weight: 500;
          line-height: 21px;
          margin: 0 0 20px 0;
          color: rgba(9, 32, 59, 0.50);
        }

        p {
          font-size: 20px;
          font-weight: bold;
          line-height: 30px;
          margin-bottom: 40px;
        }

        h2 {
          width: 80%;
        }

      }

      &__img {
        padding-top: 67.57%;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        a {
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          bottom: 0;
          z-index: 3;
        }

        img {
          position: absolute;
          height: 100%;
          width: 100%;
          object-fit: cover;
          transform: scale(1.01);
          top: 0;
          left: 0;
          transition: all 2s ease;

        }

        &:hover {
          img {
            transform: scale(1.05);
            filter: contrast(140%);
          }
        }
      }
    }

    &__bottom {
      margin-top: -50px;
    }

    .dc-btn {
      margin: auto;
      position: relative;
      z-index: 3;

      img {
        display: none;
      }
    }
  }

  @media (max-width: 767px) {
    .news__single__content {
      h4 {
        margin-bottom: 10px;
      }

      .title {
        width: 100%;
        margin-bottom: 20px;
      }
    }

    .news__single__img {
      margin-top: 20px;
    }

    .news__single {
      margin-bottom: 40px;

    }

    .col-md-4 {
      &:nth-last-of-type(1) .news__single {
        margin-bottom: 40px;
      }
    }

  }
 
`;

function mapStateToProps(state) {
    return {
        mediaPageList: state.mediaReducer.mediaPageList
    }
}

export default connect(mapStateToProps)(Photo);

