import React, {useState} from 'react';
import {hover, darkGreen} from '../globalStyleVars';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import arrowImg from '../assets/images/btn-arrow-green.svg';
import arrowImgHover from '../assets/images/btn-arrow-white.svg';
import VisibilitySensor from "react-visibility-sensor";

const Button = ({
                    onSubmit,
                    text,
                    link,
                    img,
                    hoverImg,
                    fontSize,
                    fontWeight,
                    color,
                    hoverColor,
                    letterSpacing,
                    lineHeight,
                    margin,
                    background,
                    hoverBackground,
                    border,
                    width
                }) => {
    let [btnArrow, setBtnArrow] = useState(arrowImg)

    let handleArrowImgChange = (img) => {
        setBtnArrow(img)
    };

    return (

        <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
            {({isVisible}) =>
                <StyledBtn
                    fontSize={fontSize}
                    fontWeight={fontWeight}
                    color={color}
                    hoverColor={hoverColor}
                    background={background}
                    hoverBackground={hoverBackground}
                    lineHeight={lineHeight}
                    letterSpacing={letterSpacing}
                    margin={margin}
                    border={border}
                    img={img}
                    width={width}
                    hoverImg={hoverImg}
                    className={`${isVisible ? 'anim-active' : ''} fade-up dc-btn`}
                >

                    <Link to={link || '#'}>
                            <span>
                                {text}
                            </span>
                        <span
                            // onMouseOver={() => handleArrowImgChange(arrowImgHover)}
                            // onMouseLeave={() => handleArrowImgChange(arrowImg)}
                            // onClick={onSubmit}
                            className={`dc-btn-arrow-right`}
                        >
                                <img src={img || btnArrow} alt=""/>
                            </span>
                    </Link>
                </StyledBtn>
            }
        </VisibilitySensor>

    )
};

const StyledBtn = styled.div`
  &.dc-btn {
    a {
      display: flex;
      border: ${props => props.border || `2px solid #ffffff`};
      width: ${props => props.width || `250px`};
      height: 50px;
      align-items: center;
      flex-wrap: nowrap;
      position: relative;
      margin: ${props => props.margin || `0px`};

      &:before {
        position: absolute;
        content: '';
        right: 0;
        width: 0;
        height: 100%;
        background-color: ${props => props.hoverBackground || `#ffffff`};
        transition: width .5s ease;
      }

      span {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: ${props => props.fontSize || `20px`} !important;
        height: 100%;
        z-index: 1;
        color: ${props => props.color || `#000000`} !important;
        font-weight: ${props => props.fontWeight || `300`} !important;

        &.dc-btn-arrow-right {
          width: 80px;
          background-color: ${props => props.background || `#ffffff`};
        }

        img {
          width: 20px;
          transition: margin-left .5s ease;
        }
      }

      &:hover {
        &:before {
          width: 100%;
        }

        span {
          color: ${props => props.hoverColor || `#008C45`} !important;

          img {
            margin-left: 20px;
          }
        }
      }
    }
  }
`;


export default Button;
