import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import Text from '../../../components/Text';
import SubTitle from '../../../components/SubTitle';
import BlurImg from "../../../assets/images/blur.jpg";
import play from "../../../assets/images/play.svg";
import ModalVideo from 'react-modal-video'
import '../../../../node_modules/react-modal-video/scss/modal-video.scss';
import htmlParser from 'react-html-parser';


//Images
import navLeftBlack from "../../../assets/images/navLeftBlack.svg";
import navRightBlack from "../../../assets/images/navRightBlack.svg";
import NewsVideo from "../../../assets/images/dynamic/news/news-video.png";
import {baseUrl} from "../../../components/BaseUrl";
import moment from "moment";


const NewsDetail = ({data}) => {
    let [open, setOpen] = useState(false);

    const params = {
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 30,
        speed: 1000,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        renderPrevButton: () => <div className="swiper-button-prev"><img src={navLeftBlack} alt=""/></div>,
        renderNextButton: () => <div className="swiper-button-next"><img src={navRightBlack} alt=""/></div>,

    };
    return (
        <StyledNewsDetail className="news-detail pt-80">
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="news-detail__video-banner">
                            <img
                                src={data ? data.images ? baseUrl + data.images.find(img => img.is_banner === 'on').path + data.images.find(img => img.is_banner === 'on').img_name : '' : ''}
                                alt=""/>
                            <ModalVideo channel='youtube'
                                        isOpen={open}
                                        videoId={data ? data.images ? data.images.find(img => img.is_banner === 'on').extra_url : '' : ''}
                                        onClose={() => setOpen(false)}/>
                            {data ? data.images ? data.images.find(img => img.is_banner === 'on').extra_url ?
                                <div onClick={() => setOpen(true)} className="news-detail__video-banner__play">
                                    <a><img src={play} alt=""/> </a>
                                </div>
                                : '' : '' : ''}
                        </div>

                        <div className="news-detail__content">
                            <div className="news-detail__content__header">
                                <span>{moment(data ? data.data ? data.data.created_at.split(" ")[0] : '' : '').format("Do MMM  YYYY")}</span>
                                <SubTitle
                                    text={data ? data.data ? data.data.title : '' : ''}
                                    fontSize={'24px'} fontWeight={'500'} color={'#314550'}
                                    lineHeight={'32px'} letterSpacing={'0.5px'} margin={'30px 0 20px 0'}/>
                                <span className="author">- {data ? data.data ? data.data.extra_field : '' : ''}</span>
                            </div>
                            <div className="news-detail__content__desc">
                                <Text fontSize={'16px'} lineHeight={'27px'} fontWeight={'normal'}
                                      letterSpacing={'0.5px'}>

                                </Text>
                            </div>
                            <div className="news-detail__content__desc">
                                <Text fontSize={'16px'} lineHeight={'27px'} fontWeight={'normal'}
                                      letterSpacing={'0.5px'}>
                                    {htmlParser(data ? data.data ? data.data.description : '' : '')}
                                </Text>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledNewsDetail>
    )
};

const StyledNewsDetail = styled.section`
  &.news-detail {
    padding-bottom: 20px;

    .news-detail {
      &__video-banner {
        position: relative;
        padding-top: calc(500 / 1170 * 100%);
        align-items: center;
        justify-content: center;
        background-color: #F9F9F9;
        margin-bottom: 20px;

        > img {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &__play {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          left: 0;
          right: 0;
          top: 0;
          width: 100%;
          height: 100%;

          a {
            width: 50px;
            height: 50px;
          }
        }
      }

      &__content {
        &__header {
          padding-bottom: 10px;
          border-bottom: 1px solid #DBDBDB;
          margin-bottom: 40px;

          span {
            &:not(.author) {
              font-size: 16px;
              font-weight: normal;
              line-height: 16px;
              letter-spacing: 0;
              color: #008C45;
            }

            &.author {
              font-size: 14px;
              font-weight: 300;
              font-style: italic;
              line-height: 16px;
              color: #314550;
            }
          }
        }

        &__gallery {
          &__single {
            img {
              height: 320px;
            }
          }
        }

        &__desc {
          margin-bottom: 60px;
        }
      }
    }
  }
`;

export default NewsDetail;
