export const ABOUT = {
  FETCH_ABOUT_PAGE: {
    MAIN: 'FETCH_ABOUT_PAGE',
    SUCCESS: 'FETCH_ABOUT_PAGE_SUCCESS',
    FAILURE: 'FETCH_ABOUT_PAGE_FAILURE'
  },
  FETCH_CHAIRMAN_MESSAGE: {
    MAIN: 'FETCH_CHAIRMAN_MESSAGE',
    SUCCESS: 'FETCH_CHAIRMAN_MESSAGE_SUCCESS',
    FAILURE: 'FETCH_CHAIRMAN_MESSAGE_FAILURE'
  },
  FETCH_ACHIEVEMENTS: {
    MAIN: 'FETCH_ACHIEVEMENTS',
    SUCCESS: 'FETCH_ACHIEVEMENTS_SUCCESS',
    FAILURE: 'FETCH_ACHIEVEMENTS_FAILURE'
  },
  FETCH_DIRECTORS: {
    MAIN: 'FETCH_DIRECTORS',
    SUCCESS: 'FETCH_DIRECTORS_SUCCESS',
    FAILURE: 'FETCH_DIRECTORS_FAILURE'
  }
};
