import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col, Table} from 'react-bootstrap';
import SubTitle from '../../../components/SubTitle';
import htmlParser from 'react-html-parser';

const CostReductionTable = ({pageData}) => {
    return (
        <StyleCostReductionTable className="pt-80 pb-80 cost-reduction-table">
            <Container>
                <Row>
                    {/* Single */}
                    <Col md={12} className={'cost-reduction-table__single'}>
                        <SubTitle text={'Comparing 90MM Block with 5" Brick Wall'} fontSize={'24px'}
                                  fontWeight={'normal'} letterSpacing={'.5px'} lineHeight={'32px'} color={'#008C45'}
                                  margin={'0 0 10px 0'}/>

                        <p>Use 113 Blocks instead of 500 Bricks</p>

                        <Table striped hover>
                            <thead>
                            <tr>
                                <th>Reduced Cost</th>
                                <th>Reduced Weight</th>
                                <th>Increased Area</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>25%</td>
                                    <td>51.02%</td>
                                    <td>9.41 sft</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                    <Col md={12} className={'cost-reduction-table__single'}>
                        <SubTitle text={'Comparing 100MM Block with 5" Brick Wall'} fontSize={'24px'}
                                  fontWeight={'normal'} letterSpacing={'.5px'} lineHeight={'32px'} color={'#008C45'}
                                  margin={'0 0 10px 0'}/>

                        <p>Use 113 Blocks instead of 500 Bricks</p>

                        <Table striped hover>
                            <thead>
                            <tr>
                                <th>Reduced Cost</th>
                                <th>Reduced Weight</th>
                                <th>Increased Area</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>22%</td>
                                <td>45.39%</td>
                                <td>7.65 sft</td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                    <Col md={12} className={'cost-reduction-table__single'}>
                        <SubTitle text={'Comparing 140MM Block with 10" Brick Wall'} fontSize={'24px'}
                                  fontWeight={'normal'} letterSpacing={'.5px'} lineHeight={'32px'} color={'#008C45'}
                                  margin={'0 0 10px 0'}/>

                        <p>Use 113 Blocks instead of 500 Bricks</p>

                        <Table striped hover>
                            <thead>
                            <tr>
                                <th>Reduced Cost</th>
                                <th>Reduced Weight</th>
                                <th>Increased Area</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>34.48%</td>
                                <td>58.15%</td>
                                <td>14.97 sft</td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>

                    <Col md={12} className={'cost-reduction-table__single'}>
                        <SubTitle text={'Comparing 140MM Block with 10" Brick Wall'} fontSize={'24px'}
                                  fontWeight={'normal'} letterSpacing={'.5px'} lineHeight={'32px'} color={'#008C45'}
                                  margin={'0 0 10px 0'}/>

                        <Table striped hover className="vertical">
                            <thead>
                                <tr>
                                    <th>Reduced rod / iron consumption</th>
                                    <th>Reduced concrete consumption</th>
                                    <th>Reduced shuttering consumption</th>
                                    <th>Total reduction of cost</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>40-50%</td>
                                    <td>25%</td>
                                    <td>20%</td>
                                    <td>20-25%</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </StyleCostReductionTable>
    )
};

const StyleCostReductionTable = styled.section`
    &.cost-reduction-table{
        .cost-reduction-table__single{
            &:not(:last-child){
                margin-bottom: 40px;
            }
            .sub-title{
                border-bottom: 1px solid #DBDBDB;
                padding-bottom: 10px;
            }
            table{
                &.vertical{
                    position: relative;
                    tr{
                        display:block;
                        width: 100%;
                    }
                    th, td{
                        display: block;
                        margin-bottom: 10px;
                    }
                    //thead, tbody, tr {
                    //    display: table-cell;
                    //}
                    thead, tbody {
                        display: table-cell;
                    }
                    thead{
                        width: 60%;
                        th{
                            border-right: 0 !important;
                            display:block;
                        }
                    }
                    tbody{
                        position: absolute;
                        left: 60%;
                        width: 40%;
                        overflow-x: auto;
                        tr{
                            background-color: transparent;
                        }
                        td{
                            //display:block;
                            background-color: #F9F9F9;
                            text-align:center;
                        }
                    }
                }
                th, td{
                    padding: 15px 30px;
                    border: 0;
                    font-size: 20px;
                    letter-spacing: .5px;
                    line-height: 26px;
                }
                thead{
                    th{
                        background-color: #314550;
                        font-weight: 300;
                        color: #FFFFFF;
                        &:not(:last-child){
                            border-right: 2px solid #ffffff;
                        }
                    }
                }
                tr{
                    &:nth-of-type(odd){
                        background-color: #F9F9F9;
                    }
                    td{
                        font-weight: normal;
                        color: #314550;
                    }
                }
            }
        }
    }
    @media(max-width: 767px){
        &.cost-reduction-table{
            .cost-reduction-table__single{
                table{
                    position: relative;
                    tr{
                        display:block;
                        width: 100%;
                    }
                    th, td{
                        display: block;
                        min-height: 80px;
                        margin-bottom: 10px;
                    }
                    //thead, tbody, tr {
                    //    display: table-cell;
                    //}
                    thead, tbody {
                        display: table-cell;
                    }
                    thead{
                        width: 60%;
                        th{
                            border-right: 0 !important;
                            display:block;
                        }
                    }
                    tbody{
                        position: absolute;
                        left: 60%;
                        width: 40%;
                        overflow-x: scroll;
                        tr{
                            background-color: transparent !important;
                        }
                        td{
                            //display:block;
                            background-color: #F9F9F9;
                            text-align:center;
                        }
                    }
                }
            }
        }
    }
    @media(max-width: 428px){
        &.cost-reduction-table{
            .cost-reduction-table__single{
                table{
                    th, td{
                        min-height: 110px;
                    }
                }
            }
        }
    }
`;
export default CostReductionTable;
