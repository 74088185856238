import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import SubTitle from '../../../components/SubTitle'
import Text from '../../../components/Text'
import ArrowGreen from '../../../assets/images/btn-arrow-green.svg'
import Title from "../../../components/Title";
import Popup from "../../../components/Modal";


const AchievementSlider = ({page}) => {
    const size = useWindowSize();
    const params = {
        slidesPerView: 3,
        spaceBetween: 135,
        slidesPerGroup: 3,
        speed: 1000,
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        // autoplay: {
        //     delay: 4500,
        //     disableOnInteraction: false,
        // },
        breakpoints: {
            767: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            991: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 135,
            },
        }

    };

    const [show, setShow] = useState(
        false
    );

    const [modalData, setModalData] = useState(
        ''
    );

    function handleModal(value, modalData) {
        setShow(value);
        setModalData(modalData);
    }

    let getPosts = page ? page.posts ? page.posts.list : '' : '';

    return (
        <StyledAchievement className="pt-80 pb-80 achievement">
            <Container>
                <Row>
                    <Col md={12}>
                        <Title
                            fontSize={'36px'}
                            fontWeight={'500'}
                            margin={'0 0 40px 0'}
                            lineHeight={'46px'}
                            letterSpacing={'1px'}
                            text={'Achievement'}
                            color={'#ffffff'}
                        />
                        {
                            size && size.width && size.width >= 767 ?
                                <Swiper {...params} >

                                    {getPosts ? getPosts.map(data => (
                                        <div key={data.data.id} className="achievement__item">
                                            <div className="achievement__item__content">
                                                <div className="date">
                                                    <p>{data.data.extra_field}</p>
                                                </div>
                                                <SubTitle text={data.data.title} fontSize={'16px'} fontWeight={'bold'}
                                                          color={'#ffffff'} lineHeight={'27px'} letterSpacing={'0.5px'}
                                                          margin={'30px 0 5px 0'}/>
                                                <Text fontSize={'16px'} fontWeight={'300'} color={'#ffffff'}
                                                      letterSpacing={'0.5px'} lineHeight={'27px'} margin={'0 0 20px 0'}>
                                                    <p>{data.data.short_des}</p>
                                                </Text>
                                                <span onClick={() => handleModal(true, data)}>
                                                    Read More
                                                    <img src={ArrowGreen} alt=""/>
                                                </span>
                                            </div>

                                        </div>
                                    )) : ''}

                                </Swiper>
                                :
                                <div className="achievement__mobile">
                                    {getPosts ? getPosts.map(data => (
                                        <div key={data.data.id} className="achievement__item">
                                            <div className="achievement__item__content">
                                                <div className="date">
                                                    <p>{data.data.extra_field}</p>
                                                </div>
                                                <SubTitle text={data.data.title} fontSize={'16px'}
                                                          fontWeight={'bold'} color={'#ffffff'} lineHeight={'27px'}
                                                          letterSpacing={'0.5px'} margin={'30px 0 5px 0'}/>
                                                <Text fontSize={'16px'} fontWeight={'300'} color={'#ffffff'}
                                                      letterSpacing={'0.5px'} lineHeight={'27px'} margin={'0 0 20px 0'}>
                                                    <p>{data.data.short_des}</p>
                                                </Text>
                                                <span onClick={() => handleModal(true, data)}>
                                                    Read More
                                                    <img src={ArrowGreen} alt=""/>
                                                </span>
                                            </div>
                                        </div>
                                    )) : ''}
                                </div>
                        }

                    </Col>
                </Row>
            </Container>
            <Popup
                show={show}
                handleClose={() => handleModal(false, null)}
                post={modalData ? modalData.data ? modalData.data.description:'':''}
                title={modalData ? modalData.data? modalData.data.title:'':''}/>
        </StyledAchievement>
    )
};


// Hook
function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}


const StyledAchievement = styled.section`
  &.achievement {
    background-color: #314550;

    .swiper-wrapper {
      padding-top: 15px;
      padding-bottom: 65px;
    }

    .swiper-container {
      .swiper-pagination {
        bottom: 0 !important;

        .swiper-pagination-bullet {
          width: 50px;
          height: 3px;
          border-radius: 0;
          margin: 0 8px;
          background-color: #707070;

          &-active {
            background-color: #ffffff;
          }
        }
      }
    }

    .achievement__item {
      padding: 5px;

      &__content {
        padding: 20px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 8%);

        .texts {
          margin-bottom: 20px;
          height: 190px;
          overflow: hidden;
        }

        .date {
          p {
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.5px;
            line-height: 26px;
            color: #008C45;
            margin-bottom: 0;
          }
        }

        span {
          font-size: 16px;
          font-weight: 500;
          line-height: 26px;
          letter-spacing: 0.5px;
          color: #008C45;
          cursor: pointer;

          img {
            margin-left: 30px;
            height: 12px;
          }
        }
      }
    }

    .achievement__mobile {
      .achievement__item {
        padding: 0;
        margin-bottom: 30px;
      }
    }
  }

  @media (max-width: 767px) {
    &.achievement {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }
`;
export default AchievementSlider;