import React from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import Title from "../../../components/Title";
import Text from "../../../components/Text";
import {baseUrl} from "../../../components/BaseUrl";


//Image
import BlurImg from "../../../assets/images/blur.jpg";

const MissionVision = ({post}) => {

    let MissionVisionFilter = post ? post.find(findPost => findPost.data.slug === 'mission--vision') : [];
    let ImageList = MissionVisionFilter ? MissionVisionFilter.images : '';

    return (
        <StyleMissionVision className="pt-80 pb-80 mission-vision">
            <Container>
                <Row>

                    {ImageList ? ImageList.map(data => (
                        <div key={data.id} className="mission-vision__single">
                            <Col md={6}>
                                <div className="mission-vision__single__left">
                                    <Title text={data.short_title} fontSize={'24px'} fontWeight={'normal'}
                                           lineHeight={'32px'}
                                           letterSpacing={'1px'} color={'#FFFFFF'} margin={'0 0 40px 0'}/>
                                    <Text color={'#ffffff'} fontSize={'16px'} fontWeight={'300'} lineHeight={'27px'}
                                          letterSpacing={'.5px'}>
                                        <p>{data.short_desc}</p>
                                    </Text>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mission-vision__single__right">
                                    <img src={data ? baseUrl + data.path + data.img_name : BlurImg} alt=""/>
                                </div>
                            </Col>
                        </div>
                    )) : ''}
                </Row>
            </Container>
        </StyleMissionVision>
    )
};

const StyleMissionVision = styled.section`
  &.mission-vision {
    background-color: #008C45;

    .mission-vision__single {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 80px;
      }

      &:nth-of-type(even) {
        flex-direction: row-reverse;

        .mission-vision__single__left {
          margin-left: 70px;
        }
      }

      &__left {
      }

      &__right {
        position: relative;
        padding-top: 70%;

        img {
          height: 100%;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          object-fit: cover;
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
        }
      }
    }
  }

  @media (max-width: 767px) {
    &.mission-vision {
      padding-top: 60px !important;
      padding-bottom: 60px !important;

      .mission-vision__single {
        &:not(:last-child) {
          margin-bottom: 60px;
        }

        &:nth-of-type(even) {
          .mission-vision__single__left {
            margin-left: 0;
          }
        }

        &__left {
          margin-bottom: 20px;
        }
      }
    }
  }
`;

export default MissionVision;
