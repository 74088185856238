import {NEWS} from "../../constants/news-events";

let initialState = {
    news: [],
    newsLoading: false,
    newsErr: '',
    newsList:[],
    newsListLoading: false,
    newsListErr: '',
    newsDetail: [],
    newsDetailLoading: false,
    newsDetailErr: '',
};

export default function newsReducer(state = initialState, action) {
    switch(action.type) {
        case NEWS.FETCH_NEWS.MAIN:
            return {
                ...state,
                news: [],
                newsLoading: true,
                newsErr: '',
            }

        case NEWS.FETCH_NEWS.SUCCESS:
            return {
                ...state,
                news: action.result,
                newsLoading: false,
                newsErr: ''
            }

        case NEWS.FETCH_NEWS.FAILURE:
            return {
                ...state,
                news: [],
                newsLoading: false,
                newsErr: 'Error from media page fetching'
            }

        case NEWS.FETCH_NEWS_LIST.MAIN:
            return {
                ...state,
                newsList: [],
                newsListLoading: true,
                newsListErr: '',
            }

        case NEWS.FETCH_NEWS_LIST.SUCCESS:
            return {
                ...state,
                newsList: action.result,
                newsListLoading: false,
                newsListErr: ''
            }

        case NEWS.FETCH_NEWS_LIST.FAILURE:
            return {
                ...state,
                newsList: [],
                newsListLoading: false,
                newsListErr: 'Error from media page fetching'
            }

        case NEWS.FETCH_NEWS_DETAIL.MAIN:
            return {
                ...state,
                newsDetail: [],
                newsDetailLoading: true,
                newsDetailErr: '',
            }

        case NEWS.FETCH_NEWS_DETAIL.SUCCESS:
            return {
                ...state,
                newsDetail: action.result,
                newsDetailLoading: false,
                newsDetailErr: ''
            }

        case NEWS.FETCH_NEWS_DETAIL.FAILURE:
            return {
                ...state,
                newsDetail: [],
                newsDetailLoading: false,
                newsDetailErr: 'Error from media page fetching'
            }


        default:
            return state
    }
}
