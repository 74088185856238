import React, {Component} from 'react';
import InnerBanner from '../../components/InnerBanner';
import Menu from "../../components/Menu";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import {baseUrl} from "../../components/BaseUrl";
import PageDescription from "../../components/PageDescription";
import CostReductionTable from "./components/CostReductionTable";
import {fetchCost} from "../../redux/actions/cost";
import {connect} from "react-redux";
import {ApiParams} from "../../constants/apiParams";
import BlurImg from "../../assets/images/blur.jpg";
import {Helmet} from "react-helmet";
import {Loading} from "../../components/Loading";


class CostReduction extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);

        let mainParam = {
            [ApiParams.page_type]: 'cost-reduction-chart'
        };

        this.props.getCost(mainParam);

        const defData = 'How the Chart Works'

    }

    render() {

        let {cost} = this.props

        let pageData = cost ? cost.page_data : '';
        let Images = cost ? cost.images ? cost.images.list[0] : null : null;

        let bannerImage = Images ? baseUrl + Images.path + Images.img_name : BlurImg;

        return (
            <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                {this.props.loading && <Loading/>}
                <Helmet>
                    <meta name="description"
                          content={cost?.page_data?.meta_description}/>
                    <link rel="canonical" href="http://mirconcreteblock.com/cost-reduction-chart"/>
                    <title>{cost?.page_data?.meta_title ? cost.page_data.meta_title : 'Cost Reduction Chart | Mir Concrete Block'}</title>
                    <meta property="og:title"
                          content={cost?.page_data?.og_title ? cost.page_data.og_title : 'Cost Reduction Chart | Mir Concrete Block'}/>
                    <meta property="og:description" content={cost?.page_data?.og_description}/>
                </Helmet>
                <Menu menuType={'menu-fixed'}/>
                <InnerBanner
                    title={pageData ? pageData.title : ''}
                    image={bannerImage}
                />
                <PageDescription pageDesc={{
                    title: `${pageData ? pageData.sub_title : ''}`,
                    description: `${pageData ? pageData.description : ''}`
                }}/>

                <CostReductionTable/>
            </motion.div>
        )
    }
}


function mapStateToProps(state) {
    return {
        cost: state.costReducer.cost,
        loading: state.costReducer.costLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getCost: params => dispatch(fetchCost(params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CostReduction);





