import React, {Component} from 'react';
import InnerBanner from '../../components/InnerBanner';
import PageDescription from "../../components/PageDescription";
import QualityReport from './components/QualityReport';
import LabFacilities from './components/LabFacilities';
import Certification from './components/Certification';
import TestReports from './components/TestReports';
import Menu from "../../components/Menu";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import QABannerImg from '../../assets/images/dynamic/QA/Banner Img.jpg'
import {fetchQuality} from "../../redux/actions/qualityAssurance";
import {connect} from "react-redux";
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import BlurImg from "../../assets/images/blur.jpg";
import {Helmet} from "react-helmet";
import {Loading} from "../../components/Loading";


class QualityAssurance extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        let mainParam = {
            [ApiParams.page_type]: 'quality-assurance'
        };

        this.props.getQuality(mainParam);
    }

    render() {
        let {quality} = this.props;

        let getPosts = quality ? quality.posts ? quality.posts.list : '' : '';

        let getFiles = quality ? quality.files ? quality.files.list : '' : '';

        let PageData = quality ? quality.page_data : '';
        let BannerImage = quality ? quality.images ? quality.images.list[0] : '' : '';

        let AboutData = getPosts ? getPosts.find(data => data.data.slug === 'about-our-quality-assurance') : '';

        return (
            <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                {this.props.loading && <Loading/>}
                <Helmet>
                    <meta name="description"
                          content={quality?.page_data?.meta_description}/>
                    <link rel="canonical" href="http://mirconcreteblock.com/quality-assurance"/>
                    <title>{quality?.page_data?.meta_title ? quality.page_data.meta_title : 'Quality Assurance | Mir Concrete Block'}</title>
                    <meta property="og:title"
                          content={quality?.page_data?.og_title ? quality.page_data.og_title : 'Quality Assurance | Mir Concrete Block'}/>
                    <meta property="og:description" content={quality?.page_data?.og_description}/>
                </Helmet>
                <Menu menuType={'menu-fixed'}/>
                <InnerBanner
                    title={PageData ? PageData.title : ''}
                    image={BannerImage ? baseUrl + BannerImage.path + BannerImage.img_name : BlurImg}
                />
                <PageDescription pageDesc={{
                    title: 'About Our Quality Assurance',
                    description: `${AboutData ? AboutData.data.description : ''}`
                }}/>
                <QualityReport post={getPosts}/>
                <LabFacilities post={getPosts}/>
                <Certification post={getPosts}/>
                <TestReports post={getFiles}/>
            </motion.div>
        )
    }
}


function mapStateToProps(state) {
    return {
        quality: state.qualityReducer.quality,
        loading: state.qualityReducer.qualityLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getQuality: params => dispatch(fetchQuality(params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QualityAssurance);

