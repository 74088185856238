import {takeLatest, put, call} from 'redux-saga/effects'
import {AxiosServices} from "../../../network/AxiosService";
import {ApiServices} from "../../../network/ApiServices";
import {KEY_INFORMATION} from "../../constants/keyInformations";
import Logger from "../../../helpers/logger";

function* keyInformation(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.GET_PAGE_BY_TYPE_DATA,
            actions.params
        )

        yield put({
            type: KEY_INFORMATION.FETCH_KEY_INFORMATION.SUCCESS,
            result:  result.data
        })
    } catch(err) {
        Logger(err)
        yield put({
            type: KEY_INFORMATION.FETCH_KEY_INFORMATION.FAILURE
        })
    }
}

export default function* keyInformationSaga() {
    yield takeLatest(KEY_INFORMATION.FETCH_KEY_INFORMATION.MAIN, keyInformation);
}