import Index from './container/home/';
import React, {useEffect} from 'react';
import About from './container/about/';
import Career from './container/career/';
import CareerApply from './container/career/apply';
import News from './container/news-events/';
import NewsDetail from './container/news-events/detail';
import ConcernList from './container/concern/';
import EquipmentList from './container/equipment/';
import CostReduction from './container/cost-reduction/';
import ProductListing from './container/product-listing/';
import Client from './container/client';
import QualityAssurance from './container/quality-assurance';
import KeyInformation from './container/key-information';
import StyledGlobal from './globalStyle';
import Footer from './components/Footer';
import ContactUs from './container/contact-us';
import Media from './container/media-news';
import PhotoGallery from './container/gallery';
import VideoGallery from './container/gallery/video';
import Error from './container/error';
import {Route, Switch, useLocation} from 'react-router-dom';
import {AnimatePresence} from 'framer-motion';
import {Helmet} from 'react-helmet';

function App() {
    const location = useLocation();
    return (
        <React.Fragment>
            <Helmet>
                <title>Mir Concrete Block | Concrete Manufacturing Company in Bangladesh</title>
                <meta name="description"
                      content="Mir Concrete Block is one of the business concerns of Mir Group of Companies, which was inaugurated in 2004. We are specialized in the manufacturing of high standard concrete hollow blocks, paving blocks and paving/parking tiles, etc."/>
                <link rel="canonical" href="http://mirconcreteblock.com/" />
            </Helmet>
            <StyledGlobal/>
            <AnimatePresence exitBeforeEnter>
                <Switch location={location} key={location.pathname}>
                    <Route exact path="/" component={Index}/>
                    <Route exact path="/about-us" component={About}/>
                    <Route exact path="/product" component={ProductListing}/>
                    <Route exact path="/clients" component={Client}/>
                    <Route exact path="/news-events" component={News}/>
                    <Route exact path="/news-events/:slug" component={NewsDetail}/>
                    <Route exact path="/career" component={Career}/>
                    <Route exact path="/career/apply/:slug" component={CareerApply}/>
                    <Route exact path="/sister-concerns" component={ConcernList}/>
                    <Route exact path="/equipments" component={EquipmentList}/>
                    <Route exact path="/cost-reduction-chart" component={CostReduction}/>
                    <Route exact path="/key-information" component={KeyInformation}/>
                    <Route exact path="/quality-assurance" component={QualityAssurance}/>
                    <Route exact path="/contact-us" component={ContactUs}/>
                    <Route exact path="/media" component={Media}/>
                    <Route exact path="/gallery" component={PhotoGallery}/>
                    <Route exact path="/gallery/photos" component={PhotoGallery}/>
                    <Route exact path="/gallery/videos" component={VideoGallery}/>
                    <Route component={Error}/>
                </Switch>
            </AnimatePresence>
            <Footer/>
        </React.Fragment>
    );
}


export default App;
