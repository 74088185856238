export const SERVICES = {
  FETCH_SERVICES_PAGE: {
    MAIN: 'FETCH_SERVICES_PAGE',
    SUCCESS: 'FETCH_SERVICES_PAGE_SUCCESS',
    FAILURE: 'FETCH_SERVICES_PAGE_FAILURE'
  },
  FETCH_SERVICES_LIST: {
    MAIN: 'FETCH_SERVICES_LIST',
    SUCCESS: 'FETCH_SERVICES_LIST_SUCCESS',
    FAILURE: 'FETCH_SERVICES_LIST_FAILURE'
  },
  FETCH_SERVICES_CHILD: {
    MAIN: 'FETCH_SERVICES_CHILD',
    SUCCESS: 'FETCH_SERVICES_CHILD_SUCCESS',
    FAILURE: 'FETCH_SERVICES_CHILD_FAILURE'
  }
}
