import React, {Component, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col, Form} from 'react-bootstrap';
import Title from '../../../components/Title';

// img
import leftBg from '../../../assets/images/home-about-pattern.svg';
import callIcon from '../../../assets/images/call.svg';
import emailIcon from '../../../assets/images/email.svg';
import locationIcon from '../../../assets/images/location.svg';
import mobileIcon from '../../../assets/images/mobile.svg';
import BtnArrowWhite from '../../../assets/images/btn-arrow-white.svg';
import Button from "../../../components/Button";
import SubTitle from "../../../components/SubTitle";
import {fetchFormData, postFormSubmit} from "../../../redux/actions/form";
import {connect} from "react-redux";
import {ApiParams} from "../../../constants/apiParams";
import Message from '../../../components/PopupMessage';
import {Loading} from '../../../components/Loading';
import {emailValidation, emptyValidation, emptyNumber} from '../../../config/validator';
import helpCenter from '../../../assets/images/call-center.svg';



const ContactUs = (props)=> {
    useEffect(() => {

        let mainParam = {
            [ApiParams.form_slug]: 'contact-form'

        };
        props.getFormData(mainParam);

    }, []);

    let { formFields, submitForm, formSubmitErr,popup } = props;
    let [state, setState] = useState({
        name:'',
        email:'',
        phone: '',
        message: ''
    });

    let handleChange = e => {
        let value = e.target.value
        setState({
            ...state,
            [e.target.name]: value
        })
    };

    let handleSelect = (name,value)=>{
        setState({
            ...state,
            [name]: value
        })
    }

    let handleInit = () => {
        setState({
            name:'',
            email:'',
            phone: '',
            message: ''
        })
    }



    let handleSubmit = (e) => {
        e.preventDefault()

        let formField = {
            form_id: 'contact-form'
        }
        let len = props.formFields.length;

        for(let i = 0; i < len; i++) {
            formField[props.formFields[i]["field_key"]] = ""
        }

        formField["name"] = state.name;
        formField["email"] = state.email;
        formField["phone"] = state.phone;
        formField["message"] = state.message;

        let bodyFormData = new FormData()

        for(let key in formField) {
            bodyFormData.append(`${key}`, formField[key])
        }

        props.postFormSubmit(bodyFormData);
        // submitForm(formField)
        // handleInit()
        handleInit();
        handleEmailBlur();
        handleEmptyName();
        handleEmptyNum();
        handleLoaderBeforeSubmit();

    };

    let [emailErr, setEmailErr] = useState(true);
    let [empty, setEmpty] = useState(false);
    let [emptyNum, setEmptyNum] = useState('');

    let handleEmailBlur = () => {
        let isValid = emailValidation(state.email);
        isValid ? setEmailErr(true) : setEmailErr(false)
    };

    let handleEmptyName = () => {
        let isEmpty = emptyValidation(state.name);
        isEmpty ? setEmpty(true) : setEmpty(false)
    };

    let handleEmptyNum = () => {
        let isEmptyNum = emptyValidation(state.phone);
        isEmptyNum ? setEmptyNum(true) : setEmptyNum(false)
    };

    let [loading, setLoading] = useState(false);

    let handleLoaderBeforeSubmit = () => {
        setLoading(true)
    };


    return (
            <StyledAboutUs>

                {!popup ? loading ? <Loading/> : '' : null}
                <Message/>

                <Container/>
                <Container fluid>

                    <Row>
                        <Col sm={5} className="contact-us-left pt-110 pb-110">

                            <img src={leftBg} alt=""/>
                            <Title
                                color={'#FFF'}
                                fontSize={'35px'}
                                fontWeight={'500'}
                                lineHeight={'46px'}
                                letterSpacing={'1px'}
                                margin={'0 0 60px 0'}
                                text={'Contact Us'}/>
                            <div className="contact-us-left__content">
                                <ul>
                                    <li><span><img src={helpCenter} alt=""/></span> <span><a href="tel:16657">16657/09612996600 </a></span></li>
                                    {/*<li><span><img src={callIcon} alt=""/></span> <span><a href="#">+88 01313021166</a></span></li>*/}
                                    {/*<li><span><img src={mobileIcon} alt=""/></span> <span><a href="tel:+8801313021166">+88 01313021166</a></span></li>*/}
                                    <li><span><img src={emailIcon} alt=""/></span> <span><a href="mailto:info@mirconcreteproducts.com">info@mirconcreteproducts.com</a></span></li>
                                    <li><span><img src={locationIcon} alt=""/></span> <span>House # B-147, Road # 22, Mohakhali DOHS, Dhaka 1206.</span></li>
                                </ul>
                            </div>
                        </Col>

                        <Col sm={7} className="contact-us-right pt-110 pb-110">
                            <SubTitle text={'Send us a message'} fontSize={'20px'} fontWeight={500} lineHeight={'26px'}
                                      color={'#314550'} letterSpacing={'normal'} margin={'0 0 40px 0'}/>
                            <div className="contact-form__form">
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group>
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            type='text'
                                            placeholder="Enter your name here"
                                            name="name"
                                            value={state.name}
                                            onChange={handleChange}
                                            className={empty ? 'has-error' : ''} onBlur={handleEmptyName}
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Email ID</Form.Label>
                                        <Form.Control
                                            type='email'
                                            placeholder="Enter your email ID here"
                                            name="email"
                                            value={state.email}
                                            onChange={handleChange}
                                            className={!emailErr ? 'has-error' : ''} onBlur={handleEmailBlur}
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Contact number</Form.Label>
                                        <Form.Control
                                            type='text'
                                            placeholder="Enter your contact number here"
                                            name="phone"
                                            value={state.phone}
                                            onChange={handleChange}
                                            className={emptyNum ? 'has-error' : ''} onBlur={handleEmptyNum}
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Message</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            placeholder='Enter your message here'
                                            name="message"
                                            value={state.message}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <div onClick={handleSubmit}>
                                            <Button onSubmit={handleSubmit} img={BtnArrowWhite} margin={'40px 0 0 0'} text={'Send'}
                                                    background={'#008C45'} width={'100%'}
                                                    color={'#008C45'} border={'2px solid #008C45'}
                                                    hoverBackground={'#008C45'} hoverColor={'#ffffff'}/>
                                        </div>

                                        {/*<button onSubmit={handleSubmit}>submit</button>*/}
                                    </Form.Group>

                                </Form>
                            </div>

                        </Col>

                    </Row>


                </Container>
            </StyledAboutUs>
        )

};

const StyledAboutUs = styled.section`
     .contact-us-left{
        background-color: #008C45;
        position: relative;
        padding-left: ${props => props.offset + 15 || '100'}px;
        overflow: hidden;
            >img{
                height: 100%;
                position: absolute;
                object-fit: contain;
                top: 0;
                left: -50px;
                pointer-events: none;
            }
            .title{
                z-index: 2;
                position: relative;
            }
            &__content{
              ul{
                li{
                display:flex;
                align-items: center;
                padding-bottom: 30px;
                  span{
                    &:first-child{
                      width: 60px;
                    }
                    a, &:last-child{
                      font-size: 20px;
                      font-weight: 300;
                      line-height: 26px;
                      letter-spacing: 0.5px;
                      color: #FFFFFF;
                      width: calc(100% - 60px);
                    }
                  }
                }
              }
            }
     }
     .contact-us-right{
      padding-right:${props => props.offset + 15 || '100'}px;
      padding-left: 70px;
      .contact-form__form{
        input, textarea{
          border: none;
          border-radius: 0;
          border-bottom: 1px solid #C1C7CA;
          &::placeholder{
            font-size: 14px;
            font-weight: 300;
            line-height: 45px;
            letter-spacing: 0.5px;
            color: rgba(49,69,80,0.31);
          }
        }
        textarea{
          height: 110px;
        }
      }
     }
     @media(max-width:1024px){
      .contact-us-left{
        padding-left: 45px;
      }
      .contact-us-right{
        padding-left: 45px;
        padding-right: 45px;
      }
     }
     @media(max-width:991px){
        .contact-us-left{
          padding-left: 40px;
        }
        .contact-us-right{
            padding-left: 40px;
            padding-right: 40px;
        }
     }
     
     @media(max-width:767px){
        .title{
          margin-bottom: 40px;
        }
        .contact-us-left{
            min-width: 100%;
            padding: 60px 15px;
            &__content{
               padding-right: 0;
               :after{
                  right: 0;
               }
               ul {
                  li {
                      span {
                          &:first-child{
                              width: 50px;
                          }
                          &:last-child, a{
                              font-size: 16px;
                          }
                      }
                  }
               }
            }
        }
        .contact-us-right{
            min-width: 100%;
            min-height: 400px;
            padding: 60px 15px;
            .sub-title{
                font-size: 16px;
                font-weight: 300;
            }
            .contact-form__form{
                .dc-btn{
                    a{
                        width: 100%;
                    }
                }
            }
        }
        .row{
            flex-direction: column;
        }
     }
   
`;

function mapStateToProps(state) {
    return {
        footerOffset: state.footerReducer.footerOffset,
        formFields: state.formReducer.formFields,
        popup: state.formReducer.popup
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getFormData: params => dispatch(fetchFormData(params)),
        postFormSubmit: data=> dispatch(postFormSubmit(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);



















