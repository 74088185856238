import React, {useState} from 'react';
import {Container, Row, Col} from "react-bootstrap";
import styled from 'styled-components';
import ModalVideo from "react-modal-video";
import play from "../../../assets/images/play.svg";
import PreloadImage from "react-preload-image";
import '../../../../node_modules/react-modal-video/scss/modal-video.scss';
import videoImage from '../../../assets/images/dynamic/about-detail.jpg'
import {baseUrl} from "../../../components/BaseUrl";


const Video = ({thumb}) => {

    let [open, setOpen] = useState(false);

    let videoThumb = thumb ? baseUrl + thumb.path + thumb.img_name : '';

    return (
        <StyledVideo>
            <Container>
                <Row>
                    <Col>
                        {videoThumb.length ?
                            <div className="video-wrap">
                                <ModalVideo
                                    channel='youtube'
                                    isOpen={open}
                                    videoId={thumb?.extra_url? thumb.extra_url:''}
                                    onClose={() => setOpen(false)}
                                />
                                <div onClick={() => setOpen(true)} className="videos__single__img">
                                    <a><img src={play} alt=""/> </a>
                                    {/*<img src={videoImage} alt=""/>*/}
                                    <PreloadImage style={{
                                        position: 'absolute',
                                        width: '100%',
                                        height: '100%',
                                        top: 0,
                                        left: 0,
                                        backgroundColor: '#DDD'
                                    }} src={videoThumb || videoImage}/>
                                </div>
                            </div>
                        :null}
                    </Col>
                </Row>
            </Container>
        </StyledVideo>
    );
};

const StyledVideo = styled.section`
  margin-top: 80px;
  margin-bottom: 80px;
    .videos__single__img{
      padding-top: 50%;
      position: relative;
      cursor: pointer;
      transition: filter .6s ease;
      
      a{
        position: absolute;
        z-index: 2;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          transform: scale(1.01);
          transition: transform .4s ease;
        }
      }
      &:hover{
        img{
          transform: scale(1.05);
        }
      }
    }
  
  
  @media(max-width:767px){
    margin-top: 40px;
    margin-bottom: 40px;
    .videos__single__img{
      padding-top: 60%;
      img{
        height: 40px;
      }
    }
  }
`;

export default Video;
