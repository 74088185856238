import React, {Component} from 'react';
import InnerBanner from '../../components/InnerBanner';
import ContactDetail from './components/ContactDetail';
import ContactForm from './components/ContactForm';
import Menu from "../../components/Menu";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import ContactBannerImg from '../../assets/images/dynamic/contact-us/Banner Img.jpg'
import {ApiParams} from "../../constants/apiParams";
import {fetchFormData} from "../../redux/actions/form";
import {fetchContactPageData} from "../../redux/actions/contact";
import {connect} from "react-redux";
import {baseUrl} from "../../components/BaseUrl";
import BlurImg from "../../assets/images/blur.jpg";
import {Helmet} from "react-helmet";
import {Loading} from "../../components/Loading";


class ContactUs extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        let param = {
            [ApiParams.form_slug]: 'contact-form'
        };
        let contactData = {
            [ApiParams.page_type]: 'contact'
        };
        this.props.getFormData(param);
        this.props.getContactData(contactData)
    }


    render() {
        let {address} = this.props;
        let postList = address ? address.posts ? address.posts.list : [] : [];

        let bannerImage = address ? address.images ? address.images.list[0] : '' : '';


        return (
            <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                {this.props.loading && <Loading/>}
                <Helmet>
                    <meta name="description"
                          content={address?.page_data?.meta_description}/>
                    <link rel="canonical" href="http://mirconcreteblock.com/contact-us"/>
                    <title>{address?.page_data?.meta_title ? address.page_data.meta_title : 'Contact Us | Mir Concrete Block'}</title>
                    <meta property="og:title"
                          content={address?.page_data?.og_title ? address.page_data.og_title : 'Contact Us | Mir Concrete Block'}/>
                    <meta property="og:description" content={address?.page_data?.og_description}/>
                </Helmet>
                <Menu menuType={'menu-fixed'}/>
                <InnerBanner
                    title={'Contact Us'}
                    image={bannerImage ? baseUrl + bannerImage.path + bannerImage.img_name : BlurImg}
                />
                <ContactDetail post={postList}/>
                <ContactForm/>
            </motion.div>
        )
    }
}

let mapStateToProps = state => {
    return {
        address: state.contactReducer.address,
        loading: state.contactReducer.addressMainLoading
    }
};

let mapDispatchToProps = dispatch => {
    return {
        getFormData: params => dispatch(fetchFormData(params)),
        getContactData: params => dispatch(fetchContactPageData(params))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);


