import React, {Component,useEffect, useLayoutEffect} from 'react';
import InnerBanner from '../../components/InnerBanner';
import NewsDetail from './components/NewsDetail';
import Menu from "../../components/Menu";
import {connect} from 'react-redux'
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import {fetchNews, fetchNewsDetail, fetchNewsList} from "../../redux/actions/news-events";
import {withRouter} from 'react-router-dom'
class News extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        let newsList = {
            [ApiParams.page_id]: '95'
        };

        this.props.getNewsList(newsList);
    }


    render() {
        let { newsList } = this.props;

        let news = newsList ? newsList.find(f=>f.page_data ? f.page_data.slug === 'news-':''):'';
        let events = newsList ? newsList.find(f=>f.page_data ? f.page_data.slug === 'events-':''):'';
        let csr = newsList ? newsList.find(f=>f.page_data ? f.page_data.slug === 'csr-':''):'';

        let newsPost = news?news.posts? news.posts.list:[]:[];
        let eventsPost = events?events.posts? events.posts.list:[]:[];
        let csrPost = csr?csr.posts? csr.posts.list:[]:[];


        let postList = [...newsPost, ...eventsPost, ...csrPost]
        let {slug} = this.props.match.params;
        let filterNews = postList ? postList.find(filtered=>filtered.data.slug === slug): [] ;

        return (
            <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                <Menu menuType={'menu-fixed'}/>
                <>
                    <NewsDetail data={filterNews}/>
                </>
            </motion.div>
        )
    }
}


function mapStateToProps(state) {
    return {
        newsList: state.newsReducer.newsList,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getNewsList: params => dispatch(fetchNewsList(params)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(News));

