import {takeLatest, put, call} from 'redux-saga/effects'
import {AxiosServices} from "../../../network/AxiosService";
import {ApiServices} from "../../../network/ApiServices";
import {EQUIPMENTS} from "../../constants/equipments";
import Logger from "../../../helpers/logger";

function* fetchEquipments(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.GET_PAGE_BY_TYPE_DATA,
            actions.params
        )

        yield put({
            type: EQUIPMENTS.FETCH_EQUIPMENTS.SUCCESS,
            result:  result.data
        })
    } catch(err) {
        Logger(err)
        yield put({
            type: EQUIPMENTS.FETCH_EQUIPMENTS.FAILURE
        })
    }
}

export default function* EquipmentsSagas() {
    yield takeLatest(EQUIPMENTS.FETCH_EQUIPMENTS.MAIN, fetchEquipments);
}