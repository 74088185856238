import React from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import Title from './Title';


const InnerBanner = ({title, image}) => {
    return (
        <StyledBanner className="inner-banner">
            {/*<PreloadImage style={{*/}
            {/*    position: 'absolute',*/}
            {/*    width: '100%',*/}
            {/*    height: '100%',*/}
            {/*    top: 0,*/}
            {/*    left: 0,*/}
            {/*    backgroundColor: '#DDD'*/}
            {/*}} src={image} lazy/>*/}
            <img src={image} alt=""/>
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="inner-banner__content">
                            <Title lineHeight={'52px'} text={title} color={'#FFF'} fontSize={'40px'} fontWeight={600} letterSpacing={'0.5px'} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledBanner>
    )
};

const StyledBanner = styled.div`
    //height: 100vh;
    padding-top: 25.62%;
    position: relative;
    overflow: hidden;
    margin-top: 80px;
    &:after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0,0,0,0.6);
    }
      
    .container{
      height: 100%;
      position: relative;
      .row{
        height: 100%;
      }
      .col-md-12{
        height: 100%;
      }
      p{
        width: 80%;
      }
    }
    >img{
      height: 100%;
      width: 100%;
      top: 0;
      object-fit: cover;
      position: absolute;
    }
    .inner-banner__content{
      position: absolute;
      bottom: 60px;
      z-index: 2;
      width: 50%;
    }
    @media(max-width:767px){
      margin-top: 65px;
      padding-top: 60.39%;
      .container{
        position: absolute;
        top: 0;
      }
      .inner-banner__content{
        bottom: 0;
        left: 15px;
        width: calc(100% - 30px);
        p{
          width: 100%;
        }
      }
      .title{
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
      }
    }
`;

export default InnerBanner;
