import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import Button from '../../../components/ButtonSubmit';
import SubTitle from '../../../components/SubTitle';
import BtnDownArrowWhite from "../../../assets/images/white-arrow-down.svg";


import {baseUrl} from "../../../components/BaseUrl";

let setInit = len => {
    let retArr = [];
    for (let i = 0; i < len; i++) retArr.push(3);

    return retArr;
};


const ProductListing = ({page}) => {

    const [visible, setVisible] = useState(
        setInit(page.length)
    );


    // const [visible, setVisible] = useState(3);


    // const HandleLoadMore  = () =>{
    //     setVisible(visible+30)
    // };

    const HandleLoadMore = (key) => {
        let newVisible = [...visible];

        newVisible[key] += 30;

        setVisible(newVisible)
    };


    return (
        <StyledProductListing className="product-listing">
            <Container fluid>
                <Row>

                    {page ? page.map((pData, index) => (
                        <div key={pData.page_data.id} className="product-listing__cat-single">
                            <div className="product-listing__cat-single__title">
                                <Container>
                                    <SubTitle text={pData.page_data ? pData.page_data.title : ''} fontSize={'24px'}
                                              fontWeight={'normal'}
                                              letterSpacing={'0.5px'} color={'#FFFFFF'} lineHeight={'60px'}/>
                                </Container>
                            </div>
                            <div className="product-listing__cat-single__products">
                                <Container>
                                    <Row>

                                        {pData.posts ? pData.posts.list.slice(0, visible[index]).map(cData => (
                                            <Col key={cData.data.id} md={4} sm={6} xs={6}>
                                                <div className="product-single">
                                                    <a href="#"></a>
                                                    <div className="product-single__image">
                                                        <img
                                                            src={cData.images ? baseUrl + cData.images[0].path + cData.images[0].img_name : ''}
                                                            alt=""/>
                                                    </div>
                                                    <div className="product-single__content">
                                                        <h4>{cData.data.title}</h4>
                                                        <p>Size: {cData.data.extra_field}</p>
                                                    </div>
                                                </div>
                                            </Col>
                                        )) : []}
                                        <Col md={12}>
                                            {pData.posts ? visible[index] < pData.posts.list.length && (
                                                <div onClick={() => HandleLoadMore(index)}>
                                                    <Button link={'#'}
                                                            text={'Load more'}
                                                            color={'#314550'}
                                                            background={'#008C45'}
                                                            border={'2px solid #008C45'}
                                                            hoverBackground={'#008C45'}
                                                            hoverColor={'#ffffff'}
                                                            img={BtnDownArrowWhite}
                                                            margin={'10px auto 0 auto'}/>
                                                </div>) : ''}

                                        </Col>

                                    </Row>
                                </Container>
                            </div>
                        </div>
                    )) : []}


                </Row>
            </Container>
        </StyledProductListing>
    )
};

const StyledProductListing = styled.section`
  &.product-listing {
    .product-listing__cat-single {
      width: 100%;

      &__title {
        background-color: #314550;
        width: 100%;
        height: 60px;
      }

      &__products {
        padding-top: 60px;
        padding-bottom: 60px;

        .product-single {
          position: relative;
          margin-bottom: 30px;

          a {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
          }

          &__image {
            padding-top: 100%;
            position: relative;
            background-color: #F5F5F5;
            align-items: center;
            justify-content: center;

            img {
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              height: 100%;
              max-width: 80%;
              margin: 0 auto;
              object-fit: contain;
            }
          }

          &__content {
            border-bottom: 1px solid #CECECE;

            h4 {
              font-size: 16px;
              font-weight: 500;
              line-height: 25px;
              letter-spacing: 0.5px;
              margin: 10px 0;
              height: 24px;
              overflow: hidden;
              text-align: center;
            }

            p {
              font-size: 14px;
              font-weight: 300;
              line-height: 20px;
              height: 45px;
              overflow: hidden;
              text-align: center;
              margin-bottom: 10px;
            }
          }
        }
        
        .dc-btn{
          img{
            width: 10px;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    &.product-listing {
      .product-listing__cat-single {
        &__title {
          background-color: #008C45;
        }

        &__products {
          .product-single {
            &__content {
              h4 {
                font-size: 14px;
                font-weight: 500;
                line-height: 16px;
                height: 36px;
              }

              p {
                font-size: 12px;
                font-weight: 300;
              }
            }
          }

          .dc-btn {
            a {
              width: 100%;

              span {
                font-size: 16px !important;
                font-weight: 300 !important;
              }
            }
          }
        }
      }
    }
  }
`;

export default ProductListing;
