import {createGlobalStyle} from 'styled-components';
import {darkGreen, hover} from './globalStyleVars';
import modalClose from './assets/images/close.svg';
import React from 'react';


export default createGlobalStyle`

  @font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Bold.woff2') format('woff2'),
    url('./assets/fonts/Poppins-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Black.woff2') format('woff2'),
    url('./assets/fonts/Poppins-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-BlackItalic.woff2') format('woff2'),
    url('./assets/fonts/Poppins-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-BoldItalic.woff2') format('woff2'),
    url('./assets/fonts/Poppins-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-ExtraLight.woff2') format('woff2'),
    url('./assets/fonts/Poppins-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-ExtraBoldItalic.woff2') format('woff2'),
    url('./assets/fonts/Poppins-ExtraBoldItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-ExtraBold.woff2') format('woff2'),
    url('./assets/fonts/Poppins-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Italic.woff2') format('woff2'),
    url('./assets/fonts/Poppins-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-ExtraLightItalic.woff2') format('woff2'),
    url('./assets/fonts/Poppins-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Light.woff2') format('woff2'),
    url('./assets/fonts/Poppins-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-LightItalic.woff2') format('woff2'),
    url('./assets/fonts/Poppins-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-MediumItalic.woff2') format('woff2'),
    url('./assets/fonts/Poppins-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Medium.woff2') format('woff2'),
    url('./assets/fonts/Poppins-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Regular.woff2') format('woff2'),
    url('./assets/fonts/Poppins-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-SemiBold.woff2') format('woff2'),
    url('./assets/fonts/Poppins-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Thin.woff2') format('woff2'),
    url('./assets/fonts/Poppins-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-SemiBoldItalic.woff2') format('woff2'),
    url('./assets/fonts/Poppins-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-ThinItalic.woff2') format('woff2'),
    url('./assets/fonts/Poppins-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
  }

  body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    color: #1B1D1D;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a:hover {
    color: ${hover};
  }

  ::selection {
    background: ${hover};
    color: #FFF;
  }

  p, a, h1, h2, h4, h3 {
    color: #1B1D1D;

  }

  ul {
    margin: 0;
    padding: 0
  }

  li {
    list-style: none;
  }

  a:hover, a:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
  }

  .btn:focus, button:focus, button:active:focus, .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: none;
    box-shadow: none;
  }

  table {
    width: 100%;
  }

  .form-control {
    box-shadow: none;
    outline: 0;

    &:focus {
      box-shadow: none;
    }
  }

  .p-0 {
    padding: 0 !important;
  }

  .pt-150 {
    padding-top: 150px;
  }

  .pb-150 {
    padding-bottom: 150px;
  }

  .pt-110 {
    padding-top: 110px;
  }

  .pb-110 {
    padding-bottom: 110px;
  }

  .pt-80 {
    padding-top: 80px;
  }

  .pb-80 {
    padding-bottom: 80px;
  }

  .swiper-button-disabled {
    opacity: .2;
  }

  .gmnoprint, .gm-control-active {
    opacity: 0;
  }

  .swiper-button-next:after {
    display: none;
  }

  .swiper-button-prev:after {
    display: none;
  }

  @media (min-width: 1440px) {
    .container {
      //min-width: 1366px;
      min-width: 80%;
      margin: auto;
    }
  }

  @media (max-width: 991px) {

    .container {
      min-width: 95%;
    }
  }

  @media (max-width: 768px) {
    .container, .container-md, .container-sm {
      max-width: 90%;
      overflow: hidden;
    }
  }

  @media (max-width: 767px) {
    .container, .container-sm {
      max-width: 100%;
    }

    .pt-110, .pt-150 {
      padding-top: 99px;
    }

    .pb-110, .pb-150 {
      padding-bottom: 99px;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  #root {
    overflow-x: hidden;
  }


  .modal-header {
    padding: 0;
    padding-bottom: 27px;
    margin-bottom: 40px;
  }

  .modal-dialog {
    width: 80%;
    max-width: unset;
  }

  .modal-content {
    border-radius: 0;
    padding: 50px 70px;

    .close {
      height: 50px;
      width: 50px;
      background-image: url(${modalClose});
      background-color: ${hover};
      background-size: 25px;
      background-position: center;
      padding: 0;
      background-repeat: no-repeat;
      position: absolute;
      right: -34px;
      opacity: 1;
      top: 16px;
      transition: all .3s ease;

      &:hover {
        background-color: ${darkGreen};
      }

      span {
        display: none;
      }

    }
  }

  .modal-body {
    padding: 0;

    tbody {
      tr {
        &:nth-of-type(1) td {
          border-top: 0;
        }

        td {
          padding: 20px;
          font-size: 14px;
          font-weight: 300;
          border-color: #DFE6E5;

          &:nth-of-type(1) {
            padding-left: 0;
            font-size: 16px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.51);
          }

          p {
            font-size: 14px;
            font-weight: 300;
            margin-bottom: 10px;
          }
        }

        &:nth-last-of-type(1) {
          td p:last-child {
            margin-bottom: 0;
          }

          border-bottom: 1px solid #DFE6E5;
        }
      }
    }
  }

  .modal-title {
    h2 {
      font-size: 42px !important;
      font-weight: bold;
      line-height: 42px;
      margin-bottom: 0;
    }
  }

  @media (min-width: 768px) {
    .modal-content .table-responsive {
      overflow: visible;
    }
  }

  @media (max-width: 767px) {
    .modal-dialog {
      width: 100%;
      margin: 0;
    }

    .modal-content {
      padding: 50px 15px;

      .close {
        height: 40px;
        width: 40px;
        right: 16px;
        background-size: 20px;
      }
    }

    .modal-title h2 {
      font-size: 25px !important;
      line-height: 30px;
    }
  }


  .css-yk16xz-control, .css-1pahdxg-control {
    height: 50px;
    border-radius: 0 !important;
    padding-left: 5px;
    font-size: 16px;
    outline: none !important;
    border-color: #D9D9D9 !important;
    box-shadow: none !important;

    .css-1wa3eu0-placeholder {
      font-weight: 300;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.5);
      outline: none;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
      margin-right: 10px;
    }
  }

  .css-2613qy-menu {
    border-radius: 0 !important;
    margin-top: 0 !important;
  }

  .fade-up {
    transition: opacity .2s ease, transform .2s ease;
    opacity: 0;
    transform: translateY(40px);
    transition-delay: 0s;
  }

  .anim-active {
    opacity: 1;
    transform: translateY(0px);
    transition-delay: .2s;
    transition: opacity .6s ease, transform .6s ease;
  }

  .info-window {
    max-width: 200px;
  }

  .gm-style-iw {
    border-radius: 0 !important;
  }

  .loading-before-submit {
    position: fixed;
    height: 100vh;
    width: 100%;
    bottom: 0;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 40px;
    }
  }

  .form-control.has-error {
    border-color: #dc004e !important;
  }

  .loading-before-content {
    position: fixed;
    height: 100vh;
    width: 100vw;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;

    img {
      height: 40px;
    }

    @keyframes ldio-2p01d405ya4 {
      0% {
        transform: translate(-50%, -50%) rotate(0deg);
      }
      100% {
        transform: translate(-50%, -50%) rotate(360deg);
      }
    }

    .ldio-2p01d405ya4 div {
      position: absolute;
      width: 40px;
      height: 40px;
      border: 5px solid #FFF;
      border-top-color: ${hover};
      border-radius: 50%;
    }

    .ldio-2p01d405ya4 div {
      animation: ldio-2p01d405ya4 1s linear infinite;
      top: 100px;
      left: 100px
    }

    .loadingio-spinner-rolling-qx6ynrq7t2 {
      width: 200px;
      height: 200px;
      display: inline-block;
      overflow: hidden;
      background: none;
    }

    .ldio-2p01d405ya4 {
      width: 100%;
      height: 100%;
      position: relative;
      transform: translateZ(0) scale(1);
      backface-visibility: hidden;
      transform-origin: 0 0; /* see note above */
    }

    .ldio-2p01d405ya4 div {
      box-sizing: content-box;
    }

  }
`;



