import {QUALITY} from "../../constants/qualityAssurance";

let initialState = {
    quality: [],
    qualityLoading: false,
    qualityErr: ''
};

export default function qualityReducer(state = initialState, action) {
    switch (action.type) {
        case QUALITY.FETCH_QUALITY.MAIN:
            return {
                ...state,
                quality: [],
                qualityLoading: true,
                qualityErr: ''
            };

        case QUALITY.FETCH_QUALITY.SUCCESS:
            return {
                ...state,
                quality: action.result,
                qualityLoading: false,
                qualityErr: ''
            };

        case QUALITY.FETCH_QUALITY.FAILURE:
            return {
                ...state,
                quality: [],
                qualityLoading: false,
                qualityErr: 'err from server'
            };
        default:
            return state
    }
}