import React, {useContext} from 'react';
import styled from 'styled-components';
import {Container, Row, Col, Accordion, Card, useAccordionToggle, AccordionContext} from 'react-bootstrap';
import Title from '../../../components/Title';
import htmlParser from 'react-html-parser';
import {baseUrl} from "../../../components/BaseUrl";

// img
import IconCheck from '../../../assets/images/icon-check-green.svg';
import Text from "../../../components/Text";
import MissionImage from "../../../assets/images/dynamic/mission.jpg";


const QualityReport = ({post}) => {

    let getPost = post ? post.find(postfilter => postfilter.data.slug === 'quality-report') : '';
    let getImg = getPost ? getPost.images ? getPost.images[0] : '' : '';

    return (
        <StyleQualityReport className="pt-80 pb-80 quality-report">
            <Container>
                <Title text={getPost? getPost.data.title:''} fontSize={'36px'} letterSpacing={'1px'}
                       lineHeight={'46px'} fontWeight={'500'} color={'#314550'} margin={'0 0 40px 0'}/>
                <Row>
                    <div className="quality-report__single">
                        <Col md={8}>
                            <div className="quality-report__single__left">
                                <Text color={'#314550'} fontSize={'16px'} fontWeight={'300'} lineHeight={'27px'}
                                      letterSpacing={'.5px'}>
                                    {htmlParser(getPost ? getPost.data.description : '')}
                                </Text>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="quality-report__single__right">
                                <img src={baseUrl + getImg.path + getImg.img_name} alt=""/>
                            </div>
                        </Col>
                    </div>
                </Row>
            </Container>
        </StyleQualityReport>
    )
};

const StyleQualityReport = styled.section`
  &.quality-report {
    .quality-report__single {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      //align-items: center;
      &:not(:last-child) {
        margin-bottom: 80px;
      }

      &:nth-of-type(odd) {
        //flex-direction: row;
        .mission-vision__single__left {
          margin-left: 40px;
        }
      }

      &__left {
        .texts {
          p {
            margin-bottom: 30px;
          }

          ul {
            padding-left: 40px;
            margin-bottom: 40px;

            li {
              position: relative;

              &:before {
                content: '';
                position: absolute;
                left: -40px;
                top: 0;
                width: 20px;
                height: 15px;
                background-image: url(${IconCheck});
              }

              strong {
                font-weight: 500;
              }
            }
          }
        }
      }

      &__right {
        position: relative;
        padding-top: calc(362 / 400 * 100%);

        img {
          height: 100%;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          object-fit: cover;
        }
      }
    }
  }

  @media (max-width: 767px) {
    &.quality-report {
      padding-top: 60px !important;
      padding-bottom: 60px !important;

      .quality-report__single {
        &:not(:last-child) {
          margin-bottom: 60px;
        }

        &:nth-of-type(odd) {
          .quality-report__single__left {
            margin-left: 0;
          }
        }

        &__left {
          margin-bottom: 20px;
        }
      }
    }
  }
`;
export default QualityReport;
