import React from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {Link} from "react-router-dom";
import {baseUrl} from "../../../components/BaseUrl";

const ClientListing = ({images}) => {
    return (
        <StyledClientListing className="client-listing pt-80 pb-80">
            <Container>
                <Row>

                    {images ? images.map(data=>(
                        <Col md={3} key={data.id} sm={6} xs={6}>
                            <div className="client-listing__single">
                                <Link to={'#'}></Link>
                                <img src={baseUrl+data.path+data.img_name} alt=""/>
                            </div>
                        </Col>
                    )):[]}


                </Row>
            </Container>
        </StyledClientListing>
    )
};

const StyledClientListing = styled.section`
    &.client-listing{
        .client-listing__single{
            position:relative;
            padding-top: calc(250 / 270 * 100%);
            align-items: center;
            justify-content: center;
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 5%);
            margin-bottom: 30px;            
            a{
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                z-index: 1;
            }
            img {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                height: 100%;
                max-width: 80%;
                margin: 0 auto;
                object-fit: contain;
            }
        }
    }
`;

export default ClientListing;
