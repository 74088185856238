import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col, Form} from 'react-bootstrap';
import SubTitle from '../../../components/SubTitle';
import Title from '../../../components/Title';
import Link from '../../../components/Link';
import {hover, darkGreen} from '../../../globalStyleVars';
import ContactMap from "./ContactMap";
import htmlParser from 'react-html-parser';

// img
import Text from "../../../components/Text";
import MissionImage from "../../../assets/images/dynamic/mission.jpg";
import leftBg from "../../../assets/images/home-about-pattern.svg";
import helpCenter from '../../../assets/images/call-center.svg';
import callIcon from "../../../assets/images/call.svg";
import mobileIcon from "../../../assets/images/mobile.svg";
import emailIcon from "../../../assets/images/email.svg";
import locationIcon from "../../../assets/images/location.svg";
import Button from "../../../components/Button";
import BtnArrowWhite from "../../../assets/images/btn-arrow-white.svg";
import locationImage from "../../../assets/images/mir-location.jpeg";
import {baseUrl} from "../../../components/BaseUrl";

const ContactDetail = ({post}) => {
    const container = useRef(null);
    const fluid = useRef(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [fluidWidth, setFluidWidth] = useState(0);
    const [offsetWidth, setOffsetWidth] = useState(0);
    useEffect(() => {
        setContainerWidth (container.current ? container.current.offsetWidth : 0);
        setFluidWidth (fluid.current ? fluid.current.offsetWidth : 0);
        setOffsetWidth(((fluidWidth - containerWidth) / 2));
    }, [container.current, fluid.current]);

    return (
        <StyleContactDetail offset={offsetWidth}>
            <Container ref={container}/>
            <Container fluid  ref={fluid}>
                <Row>
                    <Col sm={6} className="contact-us-left pt-80 pb-80">

                        <img src={leftBg} alt=""/>
                        {/*<Title*/}
                        {/*    color={'#FFF'}*/}
                        {/*    fontSize={'35px'}*/}
                        {/*    fontWeight={'500'}*/}
                        {/*    lineHeight={'46px'}*/}
                        {/*    letterSpacing={'1px'}*/}
                        {/*    margin={'0 0 60px 0'}*/}
                        {/*    text={'Contact Us'}/>*/}
                        <div className="contact-us-left__content">
                            <ul>

                                {post ? post.map(data=>(
                                    <li key={data.data.id}><span><img src={data.images? baseUrl+ data.images[0].path+data.images[0].img_name:''} alt=""/></span> <span><a href={data.data.description}>{data.data.description}</a></span></li>
                                )):''}
                            </ul>
                        </div>
                    </Col>

                    <Col sm={6} className="contact-us-right">
                        {/*<Row>*/}
                            <div className="map-wrapper">
                                <img src={locationImage} alt=""/>
                            </div>
                            {/*<ContactMap />*/}
                        {/*</Row>*/}
                    </Col>

                </Row>
            </Container>
        </StyleContactDetail>
    )
};

const StyleContactDetail = styled.section`
  .contact-us-left{
        background-color: #008C45;
        position: relative;
        padding-left: ${props => props.offset + 15 || '100'}px;
        overflow: hidden;
            >img{
                height: 100%;
                position: absolute;
                object-fit: contain;
                top: 0;
                left: -50px;
                pointer-events: none;
            }
            .title{
                z-index: 2;
                position: relative;
            }
            &__content{
            padding-right: 30px;
              ul{
                li{
                display:flex;
                align-items: center;
                padding-bottom: 30px;
                  span{
                    &:first-child{
                      width: 60px;
                    }
                    a, &:last-child{
                      font-size: 20px;
                      font-weight: 300;
                      line-height: 26px;
                      letter-spacing: 0.5px;
                      color: #FFFFFF;
                      width: calc(100% - 60px);
                    }
                  }
                }
              }
            }
     }
     .contact-us-right{
      .map-wrapper{
        margin-left: -15px;
        margin-right: -15px;
        width: 100%;
        height: 100%;
        position: relative;
        img{
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
     }
     @media(max-width:1024px){
      .contact-us-left{
        padding-left: 45px;
      }
     }
     @media(max-width:991px){
        .contact-us-left{
          padding-left: 40px;
        }
     }
     
     @media(max-width:767px){
        .title{
          margin-bottom: 40px;
        }
        .contact-us-left{
            min-width: 100%;
            padding-left: 15px;
            padding-right: 15px;
            &__content{
               padding-right: 0;
            }
        }
      .contact-us-left__content:after{
        right: 0;
      }
       .row{
          flex-direction: column;
       }
     }
`;
export default ContactDetail;
