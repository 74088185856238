import {PRODUCT} from "../../constants/product";

export function fetchProductPage(params) {
    return {
        type: PRODUCT.FETCH_PRODUCT_PAGE.MAIN,
        params
    }
}

export function fetchProductList(params) {
    return {
        type: PRODUCT.FETCH_PRODUCT_LIST.MAIN,
        params
    }
}
