import React, {Component} from 'react';
import InnerBanner from '../../components/InnerBanner';
import EquipmentList from './components/EquipmentList';
import Menu from "../../components/Menu";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import BlurImg from "../../assets/images/blur.jpg";

import {
    fetchEquipments
} from "../../redux/actions/Equipments";
import {connect} from "react-redux";
import {Loading} from "../../components/Loading";


class Equipment extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        let mainParam = {
            [ApiParams.page_type]: 'equipments'
        };

        this.props.getEquipment(mainParam);
    }

    render() {
        let {Equipments} = this.props;

        let PageData = Equipments ? Equipments.page_data : [];
        let BannerImage = Equipments ? Equipments.images ? Equipments.images.list[0] : '' : '';
        let Posts = Equipments ? Equipments.posts ? Equipments.posts.list : [] : [];


        return (
            <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                {this.props.loading && <Loading/>}
                <Menu menuType={'menu-fixed'}/>
                <InnerBanner
                    title={PageData ? PageData.title : ''}
                    image={BannerImage ? baseUrl + BannerImage.path + BannerImage.img_name : BlurImg}
                />
                <EquipmentList post={Posts}/>
            </motion.div>
        )
    }
}


function mapStateToProps(state) {
    return {
        Equipments: state.equipmentsReducer.equipments,
        loading: state.equipmentsReducer.equipmentsLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getEquipment: params => dispatch(fetchEquipments(params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Equipment);

