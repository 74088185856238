import {takeLatest, put, call} from 'redux-saga/effects'
import Logger from '../../../helpers/logger'
import {AxiosServices} from '../../../network/AxiosService';
import {FORM} from "../../constants/form";
import {ApiServices} from "../../../network/ApiServices";

function* fetchFormData(actions) {
  try {
    let result = yield call(
        AxiosServices.get,
        ApiServices.GET_FORM_FIELDS,
        actions.params
    )

    yield put({
      type: FORM.FETCH_FORM_DATA.SUCCESS,
      result: result.data
    })
  } catch(err) {
    Logger(err)
    yield put({
      type: FORM.FETCH_FORM_DATA.FAILURE
    })
  }
}

function* postFormSubmit(actions) {
  try {
    let result = yield call(
        AxiosServices.post,
        ApiServices.POST_FORM_SUBMIT,
        actions.body
    )

    yield put({
      type: FORM.POST_FORM_DATA.SUCCESS,
      result: result.data
    })
  } catch(err) {
    Logger(err)
    yield put({
      type: FORM.POST_FORM_DATA.FAILURE
    })
  }
}

export default function* formSaga() {
  yield takeLatest(FORM.FETCH_FORM_DATA.MAIN, fetchFormData)
  yield takeLatest(FORM.POST_FORM_DATA.MAIN, postFormSubmit)
}
