import {takeLatest, put, call} from 'redux-saga/effects'
import Logger from '../../../helpers/logger'
import {AxiosServices} from '../../../network/AxiosService';
import {ApiServices} from '../../../network/ApiServices';
import {FOOTER} from '../../constants/footer'

function* setFooterOffset(actions) {
  try {
    yield put({
      type: FOOTER.SET_OFFSET_LEFT.SUCCESS,
      result: actions.data
    })
  } catch (err) {
    yield put({
      type: FOOTER.SET_OFFSET_LEFT.FAILURE,
    })
  }
}

export default function* footerSagas() {
  yield takeLatest(FOOTER.SET_OFFSET_LEFT.MAIN, setFooterOffset)
}


