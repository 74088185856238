import React, {Component, useEffect, useLayoutEffect} from 'react';
import InnerBanner from '../../components/InnerBanner';
import JobDesc from './components/JobDescription';
import Menu from "../../components/Menu";
import BlurImg from "../../assets/images/blur.jpg";
import {motion} from "framer-motion";
import {ApiParams} from "../../constants/apiParams";
import {fetchCareerListData, fetchCareerPageData} from "../../redux/actions/career";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import {Helmet} from "react-helmet";


class Apply extends Component {

    componentDidMount() {
        let params = {
            [ApiParams.page_id]: '62'
        };
        this.props.getCareerChildPage(params)
    }

    render() {
        let {slug} = this.props.match.params;
        let {careerPageList} = this.props;

        let filterCareer = careerPageList ? careerPageList.find(found=> found.page_data.slug === slug):[]

        return(
            <div>
                <Helmet>
                    <meta name="description"
                          content={filterCareer?.page_data?.meta_description}/>
                    <link rel="canonical" href={`http://mirconcreteblock.com/career/${slug}`} />
                    <title>{filterCareer?.page_data?.meta_title ? filterCareer.page_data.meta_title : 'Career | Mir Concrete Block'}</title>
                    <meta property="og:title" content={filterCareer?.page_data?.og_title ? filterCareer.page_data.og_title : 'Career | Mir Concrete Block'} />
                    <meta property="og:description" content={filterCareer?.page_data?.og_description} />
                </Helmet>
                <Menu menuType={'menu-fixed'}/>
                <JobDesc job={filterCareer} />
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        careerPageList: state.careerReducer.careerPageList
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getCareerChildPage: params => dispatch(fetchCareerListData(params))

    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Apply));
