import {GALLERY} from "../../constants/gallery";

let initialState = {
    gallery: [],
    galleryLoading: false,
    galleryErr: '',
    photoGallery: [],
    photoGalleryLoading: false,
    photoGalleryErr: '',
    videoGallery: [],
    videoGalleryLoading: false,
    videoGalleryErr: ''
};

export default function galleryReducer(state = initialState, action) {
    switch (action.type) {
        case GALLERY.FETCH_GALLERY.MAIN:
            return {
                ...state,
                gallery: [],
                galleryLoading: true,
                galleryErr: ''
            };

        case GALLERY.FETCH_GALLERY.SUCCESS:
            return {
                ...state,
                gallery: action.result,
                galleryLoading: false,
                galleryErr: ''
            };

        case GALLERY.FETCH_GALLERY.FAILURE:
            return {
                ...state,
                gallery: [],
                galleryLoading: false,
                galleryErr: 'err from server'
            };
        case GALLERY.FETCH_PHOTO_GALLERY.MAIN:
            return {
                ...state,
                photoGallery: [],
                photoGalleryLoading: true,
                photoGalleryErr: ''
            };

        case GALLERY.FETCH_PHOTO_GALLERY.SUCCESS:
            return {
                ...state,
                photoGallery: action.result,
                photoGalleryLoading: false,
                photoGalleryErr: ''
            };

        case GALLERY.FETCH_PHOTO_GALLERY.FAILURE:
            return {
                ...state,
                photoGallery: [],
                photoGalleryLoading: false,
                photoGalleryErr: 'err from server'
            };
        case GALLERY.FETCH_VIDEO_GALLERY.MAIN:
            return {
                ...state,
                videoGallery: [],
                videoGalleryLoading: true,
                videoGalleryErr: ''
            };

        case GALLERY.FETCH_VIDEO_GALLERY.SUCCESS:
            return {
                ...state,
                videoGallery: action.result,
                videoGalleryLoading: false,
                videoGalleryErr: ''
            };

        case GALLERY.FETCH_VIDEO_GALLERY.FAILURE:
            return {
                ...state,
                videoGallery: [],
                videoGalleryLoading: false,
                videoGalleryErr: 'err from server'
            };
        default:
            return state
    }
}