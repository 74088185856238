export const PRODUCT = {
    FETCH_PRODUCT_PAGE: {
        MAIN: 'FETCH_PRODUCT_PAGE',
        SUCCESS: 'FETCH_PRODUCT_PAGE_SUCCESS',
        FAILURE: 'FETCH_PRODUCT_PAGE_FAILURE'
    },
    FETCH_PRODUCT_LIST: {
        MAIN: 'FETCH_PRODUCT_LIST',
        SUCCESS: 'FETCH_PRODUCT_LIST_SUCCESS',
        FAILURE: 'FETCH_PRODUCT_LIST_FAILURE'
    }

};