export const NEWS = {
    FETCH_NEWS: {
        MAIN: 'FETCH_NEWS',
        SUCCESS: 'FETCH_NEWS_SUCCESS',
        FAILURE: 'FETCH_NEWS_FAILURE'
    },
    FETCH_NEWS_LIST: {
        MAIN: 'FETCH_NEWS_LIST',
        SUCCESS: 'FETCH_NEWS_LIST_SUCCESS',
        FAILURE: 'FETCH_NEWS_LIST_FAILURE'
    },
    FETCH_NEWS_DETAIL: {
        MAIN: 'FETCH_NEWS_DETAIL',
        SUCCESS: 'FETCH_NEWS_DETAIL_SUCCESS',
        FAILURE: 'FETCH_NEWS_DETAIL_FAILURE'
    }
};