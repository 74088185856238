import React, {Component} from 'react';
import InnerBanner from '../../components/InnerBanner';
import PageDescription from '../../components/PageDescription';
import MissionVision from './components/MissionVision';
import BoardOfDirector from './components/BoardOfDirector';
import {Helmet} from 'react-helmet';
import Menu from '../../components/Menu';
import BlurImg from "../../assets/images/blur.jpg";

import {ApiParams} from "../../constants/apiParams";
import {
    fetchAboutMain,
    fetchAboutChairman,
    fetchAboutAchievement,
    fetchAboutDirectors
} from "../../redux/actions/about";
import {connect} from "react-redux";
import {baseUrl} from "../../components/BaseUrl";
import {motion} from 'framer-motion';
import {PageAnimation} from "../../components/PageAnimation";
import ChairmanMessage from "./components/ChairmanMessage";
import AchievementSlider from "./components/AchievementSlider";
import {Loading} from "../../components/Loading";

class About extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        let aboutMain = {
            [ApiParams.page_type]: 'about-us-main'
        };
        let Chairman = {
            [ApiParams.page_type]: 'about-chairman'
        };
        let Achievement = {
            [ApiParams.page_type]: 'about-achievements'
        };
        let Directors = {
            [ApiParams.page_type]: 'about-directors'
        };
        this.props.getAboutMain(aboutMain);
        this.props.getAboutChairman(Chairman);
        this.props.getAboutAchievement(Achievement);
        this.props.getAboutDirectors(Directors);

        if (this.props.sectionId) {
            let elem = document.getElementById(this.props.sectionId)
            if (elem) elem.scrollIntoView({behavior: "smooth", block: "start"})
        }
    }


    render() {

        let {aboutMain} = this.props;
        let {aboutChairman} = this.props;
        let {aboutAchievement} = this.props;
        let {aboutDirectors} = this.props;

        let AboutPageData = aboutMain ? aboutMain.page_data : [];
        let AboutBannerImage = aboutMain ? aboutMain.images ? aboutMain.images.list[0] : '' : '';

        let AboutPosts = aboutMain ? aboutMain.posts ? aboutMain.posts.list : '' : '';

        let WhoWeAre = AboutPosts ? AboutPosts.find(post => post.data.slug === 'who-we-are') : '';

        return (
            <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                {this.props.loading && <Loading/>}
                <Helmet>
                    <meta name="description"
                          content={aboutMain?.page_data?.meta_description}/>
                    <link rel="canonical" href="http://mirconcreteblock.com/about-us"/>
                    <title>{aboutMain?.page_data?.meta_title ? aboutMain.page_data.meta_title : 'About Us | Mir Concrete Block'}</title>
                    <meta property="og:title"
                          content={aboutMain?.page_data?.og_title ? aboutMain.page_data.og_title : 'About Us | Mir Concrete Block'}/>
                    <meta property="og:description" content={aboutMain?.page_data?.og_description}/>
                </Helmet>
                <Menu menuType={'menu-fixed'}/>
                <InnerBanner
                    title={AboutPageData ? AboutPageData.sub_title : ''}
                    image={AboutBannerImage ? baseUrl + AboutBannerImage.path + AboutBannerImage.img_name : BlurImg}
                />
                <PageDescription pageDesc={{
                    title: `${WhoWeAre ? WhoWeAre.data ? WhoWeAre.data.title : '' : ''}`,
                    description: `${WhoWeAre ? WhoWeAre.data ? WhoWeAre.data.description : '' : ''}`
                }}/>

                <MissionVision post={AboutPosts}/>
                <ChairmanMessage page={aboutChairman}/>
                {
                    aboutAchievement?.posts?.list ?
                        <AchievementSlider page={aboutAchievement}/>
                        :
                        ''
                }
                <BoardOfDirector page={aboutDirectors}/>

            </motion.div>
        )
    }
}


function mapStateToProps(state) {
    return {
        aboutMain: state.aboutReducer.aboutMain,
        aboutChairman: state.aboutReducer.aboutChairmanMessage,
        aboutAchievement: state.aboutReducer.aboutAchievement,
        aboutDirectors: state.aboutReducer.aboutDirectors,
        loading: state.aboutReducer.aboutMainLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAboutMain: params => dispatch(fetchAboutMain(params)),
        getAboutChairman: params => dispatch(fetchAboutChairman(params)),
        getAboutAchievement: params => dispatch(fetchAboutAchievement(params)),
        getAboutDirectors: params => dispatch(fetchAboutDirectors(params))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(About);
