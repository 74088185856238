import React, {Component, useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import {fetchHomeClientData} from "../../../redux/actions/home";
import {baseUrl} from '../../../components/BaseUrl';
import {connect} from 'react-redux';
import {ApiParams} from "../../../constants/apiParams";
import VisibilitySensor from "react-visibility-sensor";
//Inner Component
import SubTitle from '../../../components/SubTitle';
//Images
import navLeftBlack from '../../../assets/images/navLeftBlack.svg';
import navRightBlack from '../../../assets/images/navRightBlack.svg';
import Title from "../../../components/Title";

class ClientSlider extends Component {
    componentDidMount() {
        let params = {
            [ApiParams.page_type]: 'home-client'
        }
        this.props.getHomeClientData(params)
    }

    render() {
        let {homeClients} = this.props;
        const clientList = homeClients ? homeClients.images ? homeClients.images.list : [] : [];
        let len = clientList.length;


        const params = {
            slidesPerView: 2,
            spaceBetween: 20,
            speed: 1000,
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            renderPrevButton: () => <div className="swiper-button-prev"><img src={navLeftBlack} alt=""/></div>,
            renderNextButton: () => <div className="swiper-button-next"><img src={navRightBlack} alt=""/></div>,
            // autoplay: {
            //     delay: 4500,
            //     disableOnInteraction: false,
            // },
            breakpoints: {
                220: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                767: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                },
                769: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                },
            }

        };

        return (
            <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                {({isVisible}) =>
                    <StyledClient className="client-slider">
                        <Container>
                            <Row>
                                <Col sm={12}>
                                    <div className="client-slider__title">
                                        <Title fontSize={'36px'} fontWeight={500} color={'#314550'} lineHeight={'46px'}
                                               margin={'0 0 40px 0'} textAlign={'center'}>
                                            Group Concerns
                                        </Title>
                                    </div>

                                    <div className={`${isVisible ? 'anim-active' : ''} fade-up client-slider-wrapper`}>
                                        {
                                            len && len === clientList.length ?
                                                <Swiper  {...params} >
                                                    {clientList.map(data => (
                                                        <div key={data.id} className="client-single">
                                                            <div className="client-single__inner">
                                                                <img src={baseUrl + data.path + data.img_name} alt=""/>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </Swiper>
                                                : null
                                        }
                                    </div>

                                </Col>
                            </Row>
                        </Container>
                    </StyledClient>
                }
            </VisibilitySensor>
        )
    }
};

const StyledClient = styled.section`
    &.client-slider{
      background-color: #F6F6F6;
      padding-top: 80px;
      padding-bottom: 80px;
      .client-slider__title{
        text-align: center;
      }
    }
    .client-slider-wrapper{
      .swiper-container{
        padding-left: 70px;
        padding-right: 70px;
        &:before{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 70px;
          height: 100%;
          background-color: #F6F6F6;
          z-index: 2;
        }
        &:after{
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: 70px;
          height: 100%;
          background-color: #F6F6F6;
          z-index: 2;
        }
        .swiper-button-prev{
          left: 0;
        }
        .swiper-button-next{
          right: 0;
        }
      }
    }
    .client-single{
      &__inner{
        padding-top: 100%;
        background-color: #FFFFFF;
        img{
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin: auto;
          bottom:0;
          object-fit: contain;
          max-width:80%;
        }
      }
    }
    //.swiper-slide{
    //  margin-bottom: 10px;
    //}
    
    @media(min-width:769px){
      .swiper-pagination{
        display: none !important;
      }
      
    }    
    @media(max-width:768px){
     .swiper-pagination{
        display: block !important;
      }
      //.swiper-container{
      //  padding-bottom: 45px;
      //}
      .swiper-pagination{
        bottom: 0px;
        .swiper-pagination-bullet{
            height: 5px;
            width: 5px;
            background-color: #000;
            position: relative;
            margin-right: 20px;
            opacity: 1;
          &:after{
            content: '';
            height: 17px;
            width: 17px;
            border-radius: 50%;
            background-color: rgba(0,0,0,0.5);
            position: absolute;
            left: -6px;
            top: -6px;
            opacity: 0;
            z-index: -1;
          }
          &.swiper-pagination-bullet-active{
            &:after{
              opacity: 1;
            }
          }
        }
      }
      .sub-title{
        text-align: center;
      }
      
    }
    @media(min-width:767px){
        .client-slider-wrapper{
            .swiper-container{
                .swiper-pagination{
                    display: none;                
                }
            }
        }
    }
    @media(max-width:767px){
        &.client-slider{
            padding-top: 60px;
            padding-bottom: 60px;
        }
        .client-slider-wrapper{
            .swiper-container{
                padding-left: 0;
                padding-right: 0;
                padding-bottom: 55px;
                &:before, &:after{
                  content: none;
                }
                .swiper-button-next, .swiper-button-prev{
                    display: none;
                }
                .swiper-pagination{
                    display:block;                
                }
            }
        }
    }
`;

function mapStateToProps(state) {
    return {
        homeClients: state.homeReducer.homeClients
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getHomeClientData: (params) => dispatch(fetchHomeClientData(params))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientSlider);
