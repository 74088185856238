import React, {useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import Title from "../../../components/Title";
import Text from "../../../components/Text";
import Popup from "../../../components/Modal";
import Button from "../../../components/Button";
import RightPattern from '../../../assets/images/right-pattern.svg';
import Chairman from '../../../assets/images/dynamic/chairman.jpg';
import HtmlParser from 'react-html-parser';
import {baseUrl} from "../../../components/BaseUrl";
import BlurImg from "../../../assets/images/blur.jpg";

const ChairmanMessage = ({page}) => {
   let PageData = page ? page.page_data ? page.page_data : '' : '';
   let posts = page ? page.posts ? page.posts.list? page.posts.list[0] :'' :'' :'';
   let CeoImage = page ? page.images ? page.images.list[0] :'' :'';


    const [show, setShow] = useState(
        false
    );

    function handleModal(value) {
        setShow(value);
    }

    return (
        <StyledChairmanMessage className="pt-80 pb-80 chairman-message">
            <Container>
                <Row className="justify-content-between">
                    <Col md={7}>
                        <Title text={PageData.title} fontSize={'36px'} fontWeight={500} lineHeight={'46px'}
                               letterSpacing={'1px'} color={'#314550'} margin={'0 0 40px 0'}/>

                        <Text color={'#070707'} fontSize={'16px'} fontWeight={300} lineHeight={'27px'}
                              letterSpacing={'0.5px'}>
                            {HtmlParser(PageData.description)}
                        </Text>

                        <div
                            className="popup-on"
                            onClick={() => handleModal(true)}
                        >
                            <Button text={'Read More'} color={'#008C45'} border={'0px'} background={'transparent'}
                                    width={'160px'} hoverBackground={'transparent'} fontSize={'16px'} fontWeight={'normal'} margin={'40px 0 0 0'} />
                        </div>

                    </Col>
                    <Col md={4}>
                        <div className="chairman-message__image">
                            <img src={CeoImage ? baseUrl+ CeoImage.path + CeoImage.img_name :BlurImg} alt=""/>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Popup
                show={show}
                handleClose={() => handleModal(false, null)}
                post={posts ? posts.data ? posts.data.description:'':''}
                title={'Message from Chairman'}/>
        </StyledChairmanMessage>
    )
};

const StyledChairmanMessage = styled.section`
  &.chairman-message{
    position:relative;
    background-color: #F9F9F9;
    &:before{
      position: absolute;
      content: '';
      right: -30px;
      top: 50%;
      transform: translateY(-50%);
      background-image: url(${RightPattern});
      height: 100%;
      width: 510px;
      background-position: center;
      opacity: 0.2;
    }
    .chairman-message__image{
      position:relative;
      padding-top: 150%;
      overflow: hidden;
      img{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
      }
    }
  }
  @media(max-width:676px){
    &.chairman-message{
      padding-top: 60px;
      padding-bottom: 60px;
    }
    
    .justify-content-between{
      .col-md-7{
        margin-bottom: 50px;
      }
    }
  }
`;
export default ChairmanMessage;