import React, {useContext} from 'react';
import styled from 'styled-components';
import {Container, Row, Col, Accordion, Card, useAccordionToggle, AccordionContext} from 'react-bootstrap';
import Title from '../../../components/Title';
import htmlParser from "react-html-parser";
import {baseUrl} from "../../../components/BaseUrl";


function ContextAwareToggle({children, eventKey, callback, post}) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <button
            type="button"
            onClick={decoratedOnClick}
            className={'accordion-btn'}
        >
            {children}
            {/*<span>{isCurrentEventKey ? '-' : '+'}</span>*/}
            <span className={isCurrentEventKey ? 'open' : ''}></span>
        </button>
    );
}

const AccordionList = ({post}) => {
    return (
        <StyleAccordion className="pt-80 pb-80">
            <Container>
                <Title text={'Benefits of Concrete Block'} fontSize={'36px'} letterSpacing={'1px'}
                       lineHeight={'46px'} fontWeight={'500'} color={'#314550'} margin={'0 0 40px 0'}/>
                <Row>
                    <Col md={12}>
                        <Accordion className="accordion-wrapper" defaultActiveKey="0">
                            {post ? post.map((data, index) => (
                                <Card key={data.data.id}>
                                    <Card.Header>
                                        <ContextAwareToggle eventKey={data.data.id}>
                                            <h3>{data.data.title}</h3>
                                        </ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={data.data.id}>
                                        <Card.Body>
                                            {htmlParser(data.data.description)}
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            )) : ''}
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </StyleAccordion>
    )
};

const StyleAccordion = styled.section`
  background-color: #F9F9F9;

  .accordion-wrapper {
    .card {
      border: none;
      border-radius: 0;
      margin-bottom: 10px;

      .card-header {
        padding: 0;
        border-bottom: 0;
        background-color: transparent;

        .accordion-btn {
          display: flex;
          justify-content: space-between;
          width: 100%;
          border: 0;
          background-color: #314550;
          align-items: center;
          padding: 15px 30px;

          h3 {
            font-size: 20px;
            font-weight: 300;
            line-height: 26px;
            letter-spacing: .25px;
            color: #FFFFFF;
            margin-bottom: 0;
          }

          span {
            position: relative;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            &:before {
              content: '';
              position: absolute;
              left: 0;
              width: 20px;
              height: 3px;
              background-color: #fff;
              border-radius: 5px;
            }

            &:after {
              content: '';
              position: absolute;
              left: 0;
              width: 3px;
              height: 20px;
              background-color: #fff;
              border-radius: 5px;
              margin: 0 auto;
              right: 0;
              transition: all ease-in .2s;
            }

            &.open {
              &:after {
                transform: rotate(90deg);
              }
            }
          }
        }
      }

      .card-body {
        padding: 30px;
        font-size: 16px;
        font-weight: 300;
        line-height: 27px;
        letter-spacing: .25px;

        p {
          font-size: 16px;
          font-weight: 300;
          line-height: 27px;
          letter-spacing: .25px;
        }
      }
    }
  }

  @media (max-width: 414px) {
    .accordion-wrapper {
      .card {
        .card-header {
          .accordion-btn {
            padding-left: 15px;
            padding-right: 15px;

            h3 {
              text-align: left;
              width: 100%;
            }
          }
        }

        .card-body {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
  }
`;
export default AccordionList;
