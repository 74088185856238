import {takeLatest, put, call} from 'redux-saga/effects'
import {AxiosServices} from "../../../network/AxiosService";
import {ApiServices} from "../../../network/ApiServices";
import {NEWS} from "../../constants/news-events";
import Logger from "../../../helpers/logger";

function* fetchNews(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.GET_PAGE_BY_TYPE_DATA,
            actions.params
        )

        yield put({
            type: NEWS.FETCH_NEWS.SUCCESS,
            result:  result.data
        })
    } catch(err) {
        Logger(err)
        yield put({
            type: NEWS.FETCH_NEWS.FAILURE
        })
    }
}

function* fetchNewsList(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.GET_CHILD_BY_PAGE_ID,
            actions.params
        )

        yield put({
            type: NEWS.FETCH_NEWS_LIST.SUCCESS,
            result:  result.data
        })
    } catch(err) {
        Logger(err)
        yield put({
            type: NEWS.FETCH_NEWS_LIST.FAILURE
        })
    }
}

function* fetchNewsDetail(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.GET_CHILD_BY_PAGE_ID,
            actions.params
        )

        yield put({
            type: NEWS.FETCH_NEWS_DETAIL.SUCCESS,
            result:  result.data
        })
    } catch(err) {
        Logger(err)
        yield put({
            type: NEWS.FETCH_NEWS_DETAIL.FAILURE
        })
    }
}

export default function* newsSaga() {
    yield takeLatest(NEWS.FETCH_NEWS.MAIN, fetchNews);
    yield takeLatest(NEWS.FETCH_NEWS_LIST.MAIN, fetchNewsList);
    yield takeLatest(NEWS.FETCH_NEWS_DETAIL.MAIN, fetchNewsDetail);
}