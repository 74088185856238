import {all} from 'redux-saga/effects'
import homeSagas from './home'
import divisionSagas from "./division";
import footerSagas from "./footer";
import aboutSagas from "./about";
import serviceSaga from "./services";
import careerSaga from "./career";
import mediaSaga from "./media";
import formSaga from './form'
import contactSaga from './contact'
import menuSaga from "./menu";
import equipmentsSaga from "./equipments";
import qualitySaga from "./qualityAssurance";
import keyInformationSaga from './keyInformations';
import concernSaga from './concern';
import clientSaga from './client';
import newsSaga from './news-events';
import productSaga from './product';
import gallerySaga from './gallery';
import costSaga from './cost';

function* rootSaga() {
  yield all([
    homeSagas(),
    divisionSagas(),
    footerSagas(),
    aboutSagas(),
    serviceSaga(),
    careerSaga(),
    mediaSaga(),
    formSaga(),
    contactSaga(),
    menuSaga(),
    equipmentsSaga(),
    qualitySaga(),
    keyInformationSaga(),
    concernSaga(),
    clientSaga(),
    newsSaga(),
    productSaga(),
    gallerySaga(),
    costSaga()
  ])
}

export default rootSaga
