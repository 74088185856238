import {FOOTER} from "../../constants/footer";

const initialState = {
  footerOffset: null,
  offsetError: ''
}

export default function footerReducer(state=initialState, action) {
  switch (action.type) {
    case FOOTER.SET_OFFSET_LEFT.MAIN:
      return {
        ...state,
        footerOffset: null,
        offsetError: ''
      }

    case FOOTER.SET_OFFSET_LEFT.SUCCESS:
      return {
        ...state,
        footerOffset: action.result,
        offsetError: ''
    }

    case FOOTER.SET_OFFSET_LEFT.FAILURE:
      return {
        ...state,
        footerOffset: null,
        offsetError: 'Error from Offset Settings'
      }

    default:
      return state
  }
}
