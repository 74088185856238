import React, {Component} from "react";
import styled from 'styled-components';
import {Container, Row, Col} from "react-bootstrap";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import {fetchHomeCounterData} from "../../../redux/actions/home";
import {connect} from "react-redux";

import {ApiParams} from "../../../constants/apiParams";
// Internal Component
import Text from "../../../components/Text";


class Counter extends Component {
    componentDidMount() {
        let params = {
            [ApiParams.page_type]: 'home-counter'
        };
        this.props.getHomeCounterData(params)
    }

    render() {
        const {counterData} = this.props;
        const counterPosts = counterData ? counterData.posts ? counterData.posts.list : [] : [];
        // const counterPosts = counterData ? counterData.posts : null;

        return (
            <StyledCounter className="counter-section">
                <Container>
                    <div className="counter-wrapper">
                        <Row>
                            {
                                counterPosts && counterPosts.length > 0 ?
                                    counterPosts.map((counter, key) => (
                                        <Col key={key} className="container-col" md={4}>
                                            <h4><CountUp start={0} end={counter.data.extra_field}>
                                                {({countUpRef, start}) => (
                                                    <VisibilitySensor onChange={start}
                                                                      partialVisibility={{top: 0, bottom: 20}}
                                                                      delayedCall>
                                                        <span ref={countUpRef}></span>
                                                    </VisibilitySensor>
                                                )}
                                            </CountUp>+</h4>
                                            <Text fontSize={'20px'} lineHeight={'26px'} color={'#ffffff'}
                                                  letterSpacing={'1px'} margin={'30px 0 0 0'} textAlign={'center'}>
                                                <p>{counter.data.title}</p>
                                            </Text>

                                        </Col>
                                    ))
                                    : ''
                            }
                        </Row>
                    </div>
                </Container>
            </StyledCounter>
        );
    }
}

const StyledCounter = styled.section`
  &.counter-section{
    background-color: #314550;
    padding-top: 80px;
    padding-bottom: 80px;
    .counter-wrapper{
      padding-left: 130px;
      padding-right: 130px;
      .container-col{
        padding-top: 55px;
        padding-bottom: 55px;
        text-align: center;
        &:not(:last-child){
          border-right: 1px solid #E8E8E8;
        }
      }
      h4{
        font-size: 50px;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 45px;
        margin-bottom: 0;
      }
    }
  }
  @media(max-width:767px){
    &.counter-section{
      padding-top: 30px;
      padding-bottom: 30px;
      .counter-wrapper{
        padding-left: 0;
        padding-right: 0;
        .container-col{
          &:not(:last-child){
            border-right: none;
            border-bottom: 1px solid rgba(232,232,232,0.25);
          }
        }
      }
    }
  }
`;

function mapStateToProps(state) {
    return {
        counterData: state.homeReducer.counterData
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getHomeCounterData: (params) => dispatch(fetchHomeCounterData(params))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Counter);
