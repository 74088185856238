import {CLIENT} from "../../constants/client";

let initialState = {
    client: [],
    clientLoading: false,
    clientErr: '',
};

export default function clientReducer(state = initialState, action) {
    switch (action.type) {
        case CLIENT.FETCH_CLIENT.MAIN:
            return {
                ...state,
                client: [],
                clientLoading: true,
                clientErr: ''
            };

        case CLIENT.FETCH_CLIENT.SUCCESS:
            return {
                ...state,
                client: action.result,
                clientLoading: false,
                clientErr: ''
            };

        case CLIENT.FETCH_CLIENT.FAILURE:
            return {
                ...state,
                client: [],
                clientLoading: false,
                clientErr: 'err from server'
            };
        default:
            return state
    }
}