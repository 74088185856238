import React, {Component, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col, Form} from "react-bootstrap";
import Title from '../../../components/Title';
import Button from "../../../components/Button";
import Select from 'react-select'
import BtnArrowWhite from "../../../assets/images/btn-arrow-white.svg";
import htmlParser from 'react-html-parser';
import {ApiParams} from "../../../constants/apiParams";
import {connect} from 'react-redux'
import {fetchFormData, postFormSubmit} from "../../../redux/actions/form";
import Message from '../../../components/PopupMessage';
import {Loading} from '../../../components/Loading';
import {emailValidation, emptyValidation, emptyNumber} from '../../../config/validator';


const options = [
    {value: 'chocolate', label: 'Chocolate'},
    {value: 'strawberry', label: 'Strawberry'},
    {value: 'vanilla', label: 'Vanilla'}
];

const JobDescription = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0);
        let param = {
            [ApiParams.form_slug]: 'career-form'
        };
        props.getFormData(param)
    }, []);


    let {formFields, submitForm, formSubmitErr, popup} = props;
    let [state, setState] = useState({
        name: '',
        contact_no: '',
        email: '',
        cv: '',
        message: '',
    });

    let handleChange = e => {
        let value = e.target.value
        setState({
            ...state,
            [e.target.name]: value
        })
    };

    let handleState = e => {
        if(e.target.name !== 'cv') {
            setState({ ...state, [e.target.name]: e.target.value})
        } else {
            setState({...state, [e.target.name]: e.target.files[0]})
        }
    }




    let handleSelect = (name, value) => {
        setState({
            ...state,
            [name]: value
        })
    }

    let handleInit = () => {
        setState({
            name: '',
            contact_no: '',
            email: '',
            cv: '',
            message: '',
        })
    }



    let handleSubmit = (e) => {
        e.preventDefault()
        let formField = {
            form_id: 'career-form'
        }
        let len = props.formFields.length;

        for (let i = 0; i < len; i++) {
            formField[props.formFields[i]["field_key"]] = ""
        }


        formField["name"] = state.name;
        formField["contact_no"] = state.contact_no;
        formField["email"] = state.email;
        formField["message"] = state.message;
        formField["position"] = jobTitle;
        formField["cv"] = state.cv;

        let bodyFormData = new FormData()

        for (let key in formField) {
            bodyFormData.append(`${key}`, formField[key])
        }

        props.postFormSubmit(bodyFormData);
        // submitForm(formField)
        // handleInit()
        handleInit();
        handleEmailBlur();
        handleEmptyName();
        handleEmptyNum();
        handleLoaderBeforeSubmit();

    };

    let [emailErr, setEmailErr] = useState(true);
    let [empty, setEmpty] = useState(false);
    let [emptyNum, setEmptyNum] = useState('');

    let handleEmailBlur = () => {
        let isValid = emailValidation(state.email);
        isValid ? setEmailErr(true) : setEmailErr(false)
    };

    let handleEmptyName = () => {
        let isEmpty = emptyValidation(state.name);
        isEmpty ? setEmpty(true) : setEmpty(false)
    };

    let handleEmptyNum = () => {
        let isEmptyNum = emptyValidation(state.contact_no);
        isEmptyNum ? setEmptyNum(true) : setEmptyNum(false)
    };

    let [loading, setLoading] = useState(false);

    let handleLoaderBeforeSubmit = () => {
        setLoading(true)
    };


    let {job} = props;
    let jobPost = job ? job.posts ? job.posts.list[0] : [] : [];

    let jobTitle = jobPost ? jobPost.data ? jobPost.data.title : '' : '';
    return (
        <StyledJobDescription className="pt-80">
            {!popup ? loading ? <Loading/> : '' : null}
            <Message/>
            <Container>
                <Row>
                    <Col md={8}>
                        <div className="job-description">
                            <Title text={jobPost ? jobPost.data ? jobPost.data.title : '' : ''} fontSize={'36px'}
                                   fontWeight={500}
                                   lineHeight={'46px'} letterSpacing={'1px'} color={'#314550'}
                                   margin={'0 0 40px 0'}/>
                            <div className="job-description-fields">

                                {htmlParser(jobPost ? jobPost.data ? jobPost.data.description : '' : '')}
                            </div>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="job-form">
                            <Title text={'Apply Now'} fontSize={'36px'} fontWeight={500}
                                   lineHeight={'46px'} letterSpacing={'1px'} color={'#314550'}
                                   margin={'0 0 40px 0'}/>

                            <div className="job-form-fields">
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group>
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            type='text'
                                            placeholder="Enter your name here"
                                            name="name"
                                            className={empty ? 'has-error' : ''} onBlur={handleEmptyName}
                                            value={state.name} onChange={handleChange}
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Email ID</Form.Label>
                                        <Form.Control
                                            type='email'
                                            placeholder="Enter your email ID here"
                                            name="email"
                                            className={!emailErr ? 'has-error' : ''} onBlur={handleEmailBlur}
                                            value={state.email} onChange={handleChange}
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Contact number</Form.Label>
                                        <Form.Control
                                            type='text'
                                            placeholder="Enter your contact number here"
                                            name="contact_no"
                                            className={emptyNum ? 'has-error' : ''} onBlur={handleEmptyNum}
                                            value={state.contact_no} onChange={handleChange}
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Position</Form.Label>


                                        <Form.Control
                                            type='text'
                                            placeholder="Enter your contact number here"
                                            name="position"
                                            value={jobTitle} disabled
                                            onChange={handleChange}
                                        />
                                        {/*<Select options={options} className={'job-form-fields__select'}/>*/}
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Message</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            placeholder='Enter your message here'
                                            name="message"
                                            value={state.message}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Upload CV</Form.Label>
                                        <Form.File id="exampleFormControlFile1"
                                                   className="job-form-fields__file"
                                                   name="cv"
                                                   onChange={handleState}
                                                   />
                                    </Form.Group>

                                    <Form.Group>
                                        <div onClick={handleSubmit}>
                                            <Button img={BtnArrowWhite} margin={'40px 0 0 0'} text={'Send'}
                                                    background={'#008C45'} width={'100%'}
                                                    color={'#008C45'} border={'2px solid #008C45'}
                                                    hoverBackground={'#008C45'} hoverColor={'#ffffff'}/>
                                        </div>

                                        {/*<button onSubmit={this.handleSubmit} type='submit'>submit</button>*/}
                                    </Form.Group>

                                </Form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledJobDescription>
    )

};


const StyledJobDescription = styled.section`
    margin-top: 80px;
    padding-bottom: 60px;
    .job-description{
        .job-description-fields{
            tr{
                display: block;
                padding-bottom: 20px;
                border-bottom: 1px solid #DFE6E5;
                margin-bottom: 20px;
                td{
                    &:nth-of-type(1){
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 24px; 
                        width: 200px;
                    }
                    &:nth-of-type(2){
                        font-size: 14px;
                        font-weight: 300;
                        line-height: 21px;
                    }
                }
            }
        }
    }
    .job-form{
        padding: 30px;
        background-color: #F9F9F9;
        .job-form-fields{
            label{
                font-size: 14px;
                line-height: 24px;
                font-weight: 300;
                color: #000000;
            }
            input, textarea{
                height: 50px;
                border-radius: 0;
                background-color: transparent;
                border-color: #D9D9D9;
                &::placeholder {
                    font-size: 14px;
                    line-height: 24px;
                    color: rgba(0,0,0,0.51);
                }
            }
            textarea{
                height: 150px;                
            }
            &__select{
                .css-yk16xz-control , .css-1pahdxg-control{
                    background-color: transparent;
                }
                .css-g1d714-ValueContainer{
                    padding: 0;
                    .css-b8ldur-Input{
                        margin: 0;
                        padding: 0;
                    }
                }
            }
            &__file{
                input{
                position: relative;
                height: 50px;
                  &:after{
                    content: 'Upload CV/Drag your CV';
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    border:1px solid #D9D9D9;
                    color:rgba(0,0,0,0.5);
                    background-color: #F9F9F9;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                }
                .form-file-label{
                    margin-bottom: 0;
                    display: block;
                    height: 100%;
                    line-height: 50px;
                    text-align: center;
                    font-size: 14px;
                    color: rgba(0,0,0,0.51);
                }
            }
        }
    }
    @media(max-width:767px){
        .job-description{
            .job-description-fields{
                tr{
                    td{
                        &:nth-of-type(1){
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 24px; 
                            width: 150px;
                        }
                    }
                }
            }
        }
    }
`;


function mapStateToProps(state) {
    return {
        formFields: state.formReducer.formFields,
        popup: state.formReducer.popup
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getFormData: params => dispatch(fetchFormData(params)),
        postFormSubmit: data => dispatch(postFormSubmit(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(JobDescription);

