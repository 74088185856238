import React, {useContext} from 'react';
import styled from 'styled-components';
import {Container, Row, Col, Accordion, Card, useAccordionToggle, AccordionContext} from 'react-bootstrap';
import Title from '../../../components/Title';
import htmlParser from 'react-html-parser';
import {baseUrl} from "../../../components/BaseUrl";
import Text from "../../../components/Text";

// img
import IconCheck from '../../../assets/images/icon-check-green.svg';
import IconPdf from '../../../assets/images/pdf.svg';


const TestReports = ({post}) => {
    // let getPost = post ? post.find(postfilter => postfilter.data.slug === 'quality-checking-points') : '';
    // let getImg = post ? post.images ? getPost.images : '' : '';

    return (
        <StyleCertification className="pt-80 certification">
            <Container>
                <Title text={'Test Reports & certification'} fontSize={'36px'} letterSpacing={'1px'}
                       lineHeight={'46px'} fontWeight={'500'} color={'#314550'} margin={'0 0 40px 0'}/>
                <Row>

                    <Col md={12}>
                        <div className="certification__single testReport__single">
                            <ul>
                                {post ? post.map(data => (
                                    <li key={data?.id}>
                                        <a href={baseUrl + data.path + data.img_name}>
                                            {data.short_title}
                                        </a>
                                    </li>
                                )) : ''}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyleCertification>
    )
};

const StyleCertification = styled.section`
  background-color: #F9F9F9;

  &.certification {
    padding-bottom: 70px;

    .certification__text-single {
      .texts {
        margin-bottom: 40px;
      }
    }

    .certification__single {
      ul {
        column-count: 3;
        column-gap: 30px;
        margin-bottom: 50px;

        li {
          font-size: 16px;
          font-weight: 500;
          line-height: 25px;
          letter-spacing: .25px;
          color: #000000;
          break-inside: avoid-column;
          margin-bottom: 30px;
          position: relative;
          padding-left: 40px;

          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }

      &.testReport__single {
        ul {
          margin-bottom: 0;

          li {
            padding-left: 70px;
            line-height: 55px;

            &:before {
              width: 50px;
              height: 53px;
              background-image: url(${IconPdf});
            }
          }
        }
      }
    }

    .certification__image {
      &__single {
        padding-top: calc(250 / 370 * 100%);
        position: relative;
        margin-bottom: 10px;

        img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      h4 {
        font-size: 20px;
        line-height: 26px;
        letter-spacing: .25px;
        font-weight: 500;
        color: #314550;
      }

      hr {
        border-color: #DBDBDB;
      }
    }
  }

  @media (max-width: 768px) {
    &.certification {
      .certification__single {
        ul {
          column-count: 2;
        }
      }
    }
  }
  @media (max-width: 414px) {
    &.certification {
      .certification__single {
        ul {
          column-count: 1;
        }
      }
    }
  }
`;
export default TestReports;
