import React, {useState} from 'react';
import {hover, darkGreen} from '../../../globalStyleVars';
import styled from 'styled-components';
import {Container, Row, Col} from "react-bootstrap";
import errorImg from '../../../assets/images/404.png';
import Button from '../../../components/Button';

const ErrorPage = () => {
    return (
        <StyledErrorPage>
            <Container>
                <div className="StyledErrorPage__wrap">
                    <img src={errorImg} alt=""/>
                    <h4>Looks like something went wrong...</h4>
                    <p>The page you are looking for was removed, moved, renamed, or has never existed</p>
                    <div className="btn-group">
                        {/*<Button link={'/'} text="Home" width='150px' />*/}
                        {/*<Button link={'/contact'} text="Contact" width='150px' />*/}
                        <Button border={'1px solid #314550'} link={'/'} margin={'40px 0 0 0'} text={'Home'} background={'#314550'}
                                color={'#000'}/>
                        <Button border={'1px solid #314550'} link={'/contact-us'} margin={'40px 0 0 0'} text={'Contact us'} background={'#314550'}
                                color={'#000'}/>
                    </div>
                </div>
            </Container>
        </StyledErrorPage>
    )
};

const StyledErrorPage = styled.div`
  .StyledErrorPage__wrap{
    margin-top: 120px;
    min-height: 70vh;
    border: 1px solid #DDD;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 78px;
    padding: 40px 15px 50px 15px;
    h4{
      font-size: 18px;
      font-weight: 400; 
      margin: 0 0 10px 0;
      line-height: 27px;
    }
    p{
      font-size: 14px;
      font-weight: 300;
      margin: 0;
      line-height: 21px;
    }
    .btn-group{
    margin-top: 50px;
        .dc-btn{
          a{
            transition:color .3s ease !important;
           &:before{
             background-color: #314550;
           } 
          }
          &:nth-of-type( 1){
            margin-right: 30px;
          }
          &:nth-of-type(2){
            // background-color: ${hover};
            // a{
            //   span{color: #ffffff !important;}
            //     &:after{
            //       background-color: ${darkGreen};
            //     }
            // }
            //
              
          }
          //img{
          //  display: none;
          //}
          &:hover{
            span{
              color: #ffffff !important;
            }
          }
        }
    }
      
  }
  @media(max-width:767px){
    .StyledErrorPage__wrap{
      text-align: center;
      margin-top: 80px;
    }
    .btn-group{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
      .dc-btn{
        width: 100%;
        margin-right: 0 !important;
        margin-bottom: 20px;
        a{
          margin: 0 auto 10px !important
        }
        &:nth-last-of-type(1){
          margin-bottom: 0 !important;
        }
      }
    }
  }
`;
export default ErrorPage;