import {SERVICES} from "../../constants/services";

let initialState = {
  servicePage: [],
  servicePageLoading: false,
  servicePageErr: '',
  servicePageList: [],
  servicePageListLoading: false,
  servicePageListErr: '',
  serviceListChild: [],
  serviceListChildLoading: false,
  serviceListChildErr: ''
}

export default function serviceReducer(state = initialState, action) {
  switch(action.type) {
    case SERVICES.FETCH_SERVICES_PAGE.MAIN:
      return {
        ...state,
        servicePage: [],
        servicePageLoading: true,
        servicePageErr: ''
      }

    case SERVICES.FETCH_SERVICES_PAGE.SUCCESS:
      return {
        ...state,
        servicePage: action.result,
        servicePageLoading: false,
        servicePageErr: ''
      }

    case SERVICES.FETCH_SERVICES_PAGE.FAILURE:
      return {
        ...state,
        servicePage: [],
        servicePageLoading: false,
        servicePageErr: 'Error from service page fetching'
      }

    case SERVICES.FETCH_SERVICES_LIST.MAIN:
      return {
        ...state,
        servicePageList: [],
        servicePageListLoading: true,
        servicePageListErr: ''
      }

    case SERVICES.FETCH_SERVICES_LIST.SUCCESS:
      return {
        ...state,
        servicePageList: action.result,
        servicePageListLoading: false,
        servicePageListErr: ''
      }

    case SERVICES.FETCH_SERVICES_LIST.FAILURE:
      return {
        ...state,
        servicePageList: [],
        servicePageListLoading: false,
        servicePageListErr: 'Error from service list fetching'
      }

    case SERVICES.FETCH_SERVICES_CHILD.MAIN:
      return {
        ...state,
        serviceListChild: [],
        serviceListChildLoading: true,
        serviceListChildErr: ''
      }

    case SERVICES.FETCH_SERVICES_CHILD.SUCCESS:
      return {
        ...state,
        serviceListChild: action.result,
        serviceListChildLoading: false,
        serviceListChildErr: ''
      }

    case SERVICES.FETCH_SERVICES_CHILD.FAILURE:
      return {
        ...state,
        serviceListChild: [],
        serviceListChildLoading: false,
        serviceListChildErr: 'Error from service list child fetching'
      }

    default:
      return state
  }
}
