import {takeLatest, put, call} from 'redux-saga/effects'
import Logger from '../../../helpers/logger'
import {AxiosServices} from '../../../network/AxiosService';
import {ApiServices} from '../../../network/ApiServices';
import {PRODUCT} from "../../constants/product";

function* fetchProductPage(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.GET_PAGE_BY_TYPE_DATA,
            actions.params
        )

        yield put({
            type: PRODUCT.FETCH_PRODUCT_PAGE.SUCCESS,
            result:  result.data
        })
    } catch(err) {
        Logger(err)
        yield put({
            type: PRODUCT.FETCH_PRODUCT_PAGE.FAILURE
        })
    }
}
function* fetchProductList(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.GET_CHILD_BY_PAGE_ID,
            actions.params
        );

        yield put({
            type: PRODUCT.FETCH_PRODUCT_LIST.SUCCESS,
            result:  result.data
        })
    } catch(err) {
        Logger(err)
        yield put({
            type: PRODUCT.FETCH_PRODUCT_LIST.FAILURE
        })
    }
}


export default function* productSaga() {
    yield takeLatest(PRODUCT.FETCH_PRODUCT_PAGE.MAIN, fetchProductPage)
    yield takeLatest(PRODUCT.FETCH_PRODUCT_LIST.MAIN, fetchProductList)
}
