import {KEY_INFORMATION} from "../../constants/keyInformations";

let initialState = {
    key_information: [],
    key_informationLoading: false,
    key_informationErr: ''
};

export default function keyInformationReducer(state = initialState, action) {
    switch (action.type) {
        case KEY_INFORMATION.FETCH_KEY_INFORMATION.MAIN:
            return {
                ...state,
                key_information: [],
                key_informationLoading: true,
                key_informationErr: ''
            };

        case KEY_INFORMATION.FETCH_KEY_INFORMATION.SUCCESS:
            return {
                ...state,
                key_information: action.result,
                key_informationLoading: false,
                key_informationErr: ''
            };

        case KEY_INFORMATION.FETCH_KEY_INFORMATION.FAILURE:
            return {
                ...state,
                key_information: [],
                key_informationLoading: false,
                key_informationErr: 'err from server'
            };
        default:
            return state
    }
}