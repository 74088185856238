import React from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import Title from './Title';
import Text from './Text';
import ReactHtmlParser from "react-html-parser";
import Button from "./Button";
import AboutPattern from '../assets/images/home-about-pattern.svg';

const PageDescription = ({pageDesc, sectionId}) => {
    let pageDescTitle = pageDesc ? pageDesc.title ? pageDesc.title : '' : '';
    let pageDescData = pageDesc ? pageDesc.description ? pageDesc.description : '' : '';

    return (
        <StyledAbout className="pt-80 pb-80 page-description">
            <Container>
                <Row>
                    <Col md={12}>
                        <Title
                            fontSize={'36px'}
                            fontWeight={'500'}
                            margin={'0 0 40px 0'}
                            lineHeight={'46px'}
                            letterSpacing={'1px'}
                            text={pageDescTitle}
                            color={'#314550'}
                            textAlign={'center'}
                        />
                        <Text textAlign={'center'}
                              lineHeight={'27px'}
                              letterSpacing={'.25px'}
                        >
                            <p>
                                {ReactHtmlParser(pageDescData)}
                            </p>
                        </Text>
                        {/*<Button color={'#008C45'}*/}
                        {/*        background={'transparent'}*/}
                        {/*        text={'Read More'}*/}
                        {/*        fontSize={'16px'}*/}
                        {/*        fontWeight={'normal'}*/}
                        {/*        letterSpacing={'.5px'} border={'none'}*/}
                        {/*        hoverBackground={'transparent'}*/}
                        {/*        margin={'0 auto'}*/}
                        {/*        width={'150px'}*/}
                        {/*/>*/}
                    </Col>
                </Row>
            </Container>

        </StyledAbout>

    )
};

const StyledAbout = styled.section`
  &.page-description{
    position:relative;
    background-color: #F9F9F9;
    &:before{
      position: absolute;
      content: '';
      top: 50%;
      transform: translateY(-50%);
      background-image: url(${AboutPattern});
      height: 100%;
      width: 510px;
      background-position: center;
      opacity: 0.2;
    }
    .texts{
      margin-bottom: 30px;
    }
  }
  @media(max-width:991px){
    &.pb-110{
      padding-bottom: 60px;
    }
    .col-md-7{
      min-width:100%;
    }
    .title{
      margin-bottom: 30px;
    }
  }
  @media(max-width: 767px){
    &.page-description{
      padding-top: 60px;
      padding-bottom: 60px;
      .title{
        text-align: left;
      }
      .texts{
        p{
          text-align:left;
        }
      }
      .dc-btn{
        a{
          margin-left: 0;
        }
      }
    }
  }
`;

export default PageDescription;
