export const HOME = {
  FETCH_HOME_BANNER_SLIDER: {
    MAIN: 'FETCH_HOME_BANNER_SLIDER',
    SUCCESS: 'FETCH_HOME_BANNER_SLIDER_SUCCESS',
    FAILURE: 'FETCH_HOME_BANNER_SLIDER_FAILURE',
  },
  FETCH_HOME_ABOUT_US: {
    MAIN: 'FETCH_HOME_ABOUT_US',
    SUCCESS: 'FETCH_HOME_ABOUT_US_SUCCESS',
    FAILURE: 'FETCH_HOME_ABOUT_US_FAILURE',
  },
  FETCH_HOME_FEATURED: {
    MAIN: 'FETCH_HOME_FEATURED',
    SUCCESS: 'FETCH_HOME_FEATURED_SUCCESS',
    FAILURE: 'FETCH_HOME_FEATURED_FAILURE',
  },
  FETCH_HOME_COUNTER: {
    MAIN: 'FETCH_HOME_COUNTER',
    SUCCESS: 'FETCH_HOME_COUNTER_SUCCESS',
    FAILURE: 'FETCH_HOME_COUNTER_FAILURE',
  },
  FETCH_HOME_BUSINESS_DIVISIONS: {
    MAIN: 'FETCH_HOME_BUSINESS_DIVISIONS',
    SUCCESS: 'FETCH_HOME_BUSINESS_DIVISIONS_SUCCESS',
    FAILURE: 'FETCH_HOME_BUSINESS_DIVISIONS_FAILURE',
  },
  FETCH_HOME_CLIENTS: {
    MAIN: 'FETCH_HOME_CLIENTS',
    SUCCESS: 'FETCH_HOME_CLIENTS_SUCCESS',
    FAILURE: 'FETCH_HOME_CLIENTS_FAILURE',
  },
  FETCH_HOME_ACHIEVEMENTS: {
    MAIN: 'FETCH_HOME_ACHIEVEMENTS',
    SUCCESS: 'FETCH_HOME_ACHIEVEMENTS_SUCCESS',
    FAILURE: 'FETCH_HOME_ACHIEVEMENTS_FAILURE',
  },
  FETCH_HOME_CERTIFICATIONS: {
    MAIN: 'FETCH_HOME_CERTIFICATIONS',
    SUCCESS: 'FETCH_HOME_CERTIFICATIONS_SUCCESS',
    FAILURE: 'FETCH_HOME_CERTIFICATIONS_FAILURE',
  },
  FETCH_HOME_NEWS_EVENTS: {
    MAIN: 'FETCH_HOME_NEWS_EVENTS',
    SUCCESS: 'FETCH_HOME_NEWS_EVENTS_SUCCESS',
    FAILURE: 'FETCH_HOME_NEWS_EVENTS_FAILURE',
  },
  FETCH_HOME_SUSTAINABILITY: {
    MAIN: 'FETCH_HOME_SUSTAINABILITY',
    SUCCESS: 'FETCH_HOME_SUSTAINABILITY_SUCCESS',
    FAILURE: 'FETCH_HOME_SUSTAINABILITY_FAILURE',
  }
};
