import {takeLatest, put, call} from 'redux-saga/effects'
import {AxiosServices} from "../../../network/AxiosService";
import {ApiServices} from "../../../network/ApiServices";
import {CLIENT} from "../../constants/client";
import Logger from "../../../helpers/logger";

function* fetchClient(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.GET_PAGE_BY_TYPE_DATA,
            actions.params
        )

        yield put({
            type: CLIENT.FETCH_CLIENT.SUCCESS,
            result:  result.data
        })
    } catch(err) {
        Logger(err)
        yield put({
            type: CLIENT.FETCH_CLIENT.FAILURE
        })
    }
}

export default function* clientSagas() {
    yield takeLatest(CLIENT.FETCH_CLIENT.MAIN, fetchClient);
}