import React, {Component} from 'react';
import InnerBanner from '../../components/InnerBanner';
import VideoGallery from './components/VideoGallery';
import Menu from "../../components/Menu";
import BannerImg from "../../assets/images/dynamic/inner-banner.jpg";
import {connect} from 'react-redux'
import {fetchServicesPageData} from "../../redux/actions/services";
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import BlurImg from "../../assets/images/blur.jpg";
import PageDescription from "../../components/PageDescription";
import NewsBannerImg from "../../assets/images/dynamic/news/Banner Img.jpg";
import {fetchVideo} from "../../redux/actions/gallery";
import {Helmet} from "react-helmet";
import {Loading} from "../../components/Loading";

class Video extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        let video = {
            [ApiParams.page_type]: 'video-gallery'
        };

        this.props.getVideo(video);
    }

    render() {

        let {videoGallery} = this.props;
        let BannerImage = videoGallery ? videoGallery.images ? videoGallery.images.list ? videoGallery.images.list[0] : BlurImg : BlurImg : BlurImg;

        let postList = videoGallery ? videoGallery.posts ? videoGallery.posts.list : [] : [];


        return (
            <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                {this.props.loading && <Loading/>}
                <Helmet>
                    <meta name="description"
                          content={videoGallery?.page_data?.meta_description}/>
                    <link rel="canonical" href="http://mirconcreteblock.com/gallery/video"/>
                    <title>{videoGallery?.page_data?.meta_title ? videoGallery.page_data.meta_title : 'Video Gallery | Mir Concrete Block'}</title>
                    <meta property="og:title"
                          content={videoGallery?.page_data?.og_title ? videoGallery.page_data.og_title : 'Video Gallery | Mir Concrete Block'}/>
                    <meta property="og:description" content={videoGallery?.page_data?.og_description}/>
                </Helmet>
                <Menu menuType={'menu-fixed'}/>
                <InnerBanner
                    title={'Video Gallery'}
                    image={BannerImage ? baseUrl + BannerImage.path + BannerImage.img_name : BlurImg}
                />
                <>
                    <VideoGallery post={postList}/>
                </>
            </motion.div>
        )
    }
}


function mapStateToProps(state) {
    return {
        videoGallery: state.galleryReducer.videoGallery,
        loading: state.galleryReducer.videoGalleryLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getVideo: params => dispatch(fetchVideo(params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Video);

