import React from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import SubTitle from '../../../components/SubTitle';
import VisibilitySensor from "react-visibility-sensor";
import ReactHtmlParser from "react-html-parser";
import Title from "../../../components/Title";
import Text from "../../../components/Text";
import {baseUrl} from "../../../components/BaseUrl";
import BlurImg from "../../../assets/images/blur.jpg";

const BoardOfDirector = ({page}) => {

    let getPosts = page ? page.posts ? page.posts.list : [] : [];

    return (
        <StyledBoardOfDirector className="pt-80 pb-80 board-of-director">
            <Container>
                <Row>
                    <Col md={12}>
                        <Title
                            fontSize={'36px'}
                            fontWeight={'500'}
                            margin={'0 0 40px 0'}
                            lineHeight={'46px'}
                            letterSpacing={'1px'}
                            text={'Board of director'}
                            color={'#314550'}
                        />
                    </Col>

                    {getPosts ? getPosts.map(data => (
                        <Col key={data.data.id} md={3}>
                            <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                                {({isVisible}) =>
                                    <div
                                        className={`${isVisible ? 'anim-active' : ''} fade-up board-of-director__single`}>
                                        <div
                                            className="board-of-director__single__image">
                                            <img
                                                src={data.images[0] ? baseUrl + data.images[0].path + data.images[0].img_name : BlurImg}
                                                alt=""/>
                                        </div>

                                        <div className="board-of-director__single__content">
                                            <SubTitle text={data.data.title} fontWeight={500} fontSize={'20px'}
                                                      letterSpacing={'0.5px'} color={'#314550'} lineHeight={'26px'}
                                                      margin={'0 0 5px 0'}/>
                                            <Text lineHeight={'27px'} color={'#314550'} letterSpacing={'0.5px'}
                                                  fontSize={'16px'} fontWeight={'normal'}><p>{data.data.extra_field}</p>
                                            </Text>
                                        </div>
                                    </div>
                                }
                            </VisibilitySensor>
                        </Col>
                    )) : []}


                </Row>
            </Container>
        </StyledBoardOfDirector>
    )
};

const StyledBoardOfDirector = styled.section`
  &.board-of-director {
    .board-of-director__single {
      margin-bottom: 30px;

      &__image {
        position: relative;
        padding-top: 111.11%;
        margin-bottom: 10px;

        img {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          object-fit: cover;
        }
      }

      &__content {

      }
    }
  }

  @media (max-width: 767px) {
    &.board-of-director {
      padding-top: 60px;
      padding-bottom: 60px;

      .board-of-director__single {
        &__content {
          .sub-title {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
`;
export default BoardOfDirector;
