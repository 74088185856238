import React, {Component} from 'react';
import InnerBanner from '../../components/InnerBanner';
import ClientListing from './components/ClientListing';
import Menu from "../../components/Menu";
import BannerImg from "../../assets/images/dynamic/inner-banner.jpg";
import {connect} from 'react-redux'
import {fetchServicesPageData} from "../../redux/actions/services";
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import PageDescription from "../../components/PageDescription";
import BlurImg from "../../assets/images/blur.jpg";
import ClientBannerImg from "../../assets/images/dynamic/client-page/Banner Img.png";
import {fetchClient} from "../../redux/actions/client";
import {Helmet} from "react-helmet";
import {Loading} from "../../components/Loading";

class Client extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        let mainParam = {
            [ApiParams.page_type]: 'client'
        };

        this.props.getClient(mainParam);
    }

    render() {

        let {client} = this.props;

        let pageData = client ? client.page_data : '';
        let images = client ? client.images ? client.images.list : [] : [];

        let clientImage = images.filter((client) => client.is_banner !== "on");
        let bannerImage = images.find((banner) => banner.is_banner === "on");
        return (
            <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                {this.props.loading && <Loading/>}
                <Helmet>
                    <meta name="description"
                          content={client?.page_data?.meta_description}/>
                    <link rel="canonical" href="http://mirconcreteblock.com/clients"/>
                    <title>{client?.page_data?.meta_title ? client.page_data.meta_title : 'Clients | Mir Concrete Block'}</title>
                    <meta property="og:title"
                          content={client?.page_data?.og_title ? client.page_data.og_title : 'Clients | Mir Concrete Block'}/>
                    <meta property="og:description" content={client?.page_data?.og_description}/>
                </Helmet>
                <Menu menuType={'menu-fixed'}/>
                <InnerBanner
                    title={pageData ? pageData.title : ''}
                    image={bannerImage ? baseUrl + bannerImage?.path + bannerImage?.img_name : BlurImg}
                />
                <PageDescription pageDesc={{
                    title: `${pageData ? pageData.sub_title : ''}`,
                    description: `${pageData ? pageData.description : ''}`
                }}/>
                <>
                    <ClientListing images={clientImage}/>
                </>
            </motion.div>
        )
    }
}

function mapStateToProps(state) {
    return {
        client: state.clientReducer.client,
        loading: state.clientReducer.clientLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getClient: params => dispatch(fetchClient(params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Client);