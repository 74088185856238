import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import '../../../../node_modules/react-modal-video/scss/modal-video.scss';
import {Link} from "react-router-dom";
import BlurImg from "../../../assets/images/blur.jpg";
import ModalVideo from "react-modal-video";
import play from "../../../assets/images/play.svg";
import SubTitle from "../../../components/SubTitle";
import {baseUrl} from "../../../components/BaseUrl";

const VideoGallery = ({post}) => {
    let [open, setOpen] = useState(false);
    let [videoId, setVideo] = useState('');

    let handelOpen = (open, id) => {
        setOpen(open);
        setVideo(id);
    };
    return (
        <StyledVideoGallery className="gallery pt-80">
            <Container>
                <ModalVideo channel='youtube' isOpen={open} videoId={videoId}
                            onClose={() => handelOpen(false, '')}/>
                <Row>
                    <Col md={12} className="gallery__tab">
                        <Link to={'/gallery/photos'}>Photos</Link>
                        <Link to={'#'} className={'active'}>Videos</Link>
                    </Col>
                    <div className="gallery__wrapper">
                        {post ? post.map(data => (
                            <Col key={data.data.id} md={4}>
                                <div className="gallery__wrapper__single">
                                    <img
                                        src={data.images ? baseUrl + data.images[0].path + data.images[0].img_name : BlurImg}
                                        alt=""/>
                                    <div
                                        onClick={() => handelOpen(true, `${data.images ? data.images[0].extra_url : ''}`)}
                                        className="gallery__wrapper__single__play">
                                        <a><img src={play} alt=""/> </a>
                                    </div>
                                </div>
                                <div className="gallery__wrapper__title">
                                    <SubTitle text={data.data.title} fontSize={'20px'} fontWeight={'500'}
                                              color={'#314550'} lineHeight={'26px'} letterSpacing={'.25px'}/>
                                </div>
                            </Col>
                        )) : []}

                    </div>
                </Row>
            </Container>
        </StyledVideoGallery>
    )
};

const StyledVideoGallery = styled.section`
  &.gallery {
    .gallery__tab {
      text-align: center;
      margin-bottom: 60px;

      a {
        padding: 6px 50px;
        background-color: #F9F9F9;
        font-size: 20px;
        color: #314550;
        font-weight: 300;
        line-height: 26px;
        letter-spacing: .25px;

        &.active {
          background-color: #008C45;
          color: #FFFFFF;
        }
      }
    }

    .gallery__wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;

      &__single {
        position: relative;
        padding-top: calc(250 / 370 * 100%);
        width: 100%;
        cursor: pointer;
        background-color: #DDD;

        img {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &__play {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          left: 0;
          right: 0;
          top: 0;
          width: 100%;
          height: 100%;


          a {
            width: 50px;
            height: 50px;

            & > img {
              position: relative;
            }
          }
        }
      }

      &__title {
        padding-top: 10px;
        padding-bottom: 20px;
        margin-bottom: 60px;
        border-bottom: 1px solid #DBDBDB;
      }
    }
  }

  @media (max-width: 767px) {
    &.gallery {
      .gallery__wrapper {
        &__title {
          margin-bottom: 30px;
          padding-bottom: 10px;

          h3 {
            margin-bottom: 0;
          }
        }
      }

    }
  }
  @media (max-width: 414px) {
    &.gallery {
      .gallery__tab {
        display: flex;

        a {
          width: 100%;
        }
      }
    }
  }
`;

export default VideoGallery;
