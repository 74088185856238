import React, {Component} from 'react';
import InnerBanner from '../../components/InnerBanner';
import ConcernList from './components/ConcernList';
import {connect} from 'react-redux';
import {ApiParams} from "../../constants/apiParams";
import Menu from "../../components/Menu";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import ConcernBannerImage from '../../assets/images/dynamic/concern/Banner Img.jpg';
import {fetchConcern} from "../../redux/actions/concern";
import {baseUrl} from "../../components/BaseUrl";
import BlurImg from "../../assets/images/blur.jpg";
import {Helmet} from "react-helmet";
import {Loading} from "../../components/Loading";

class Concern extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        let mainParam = {
            [ApiParams.page_type]: 'concerns'
        };

        this.props.getConcern(mainParam);
    }

    render() {

        let {concern} = this.props;

        let pageData = concern ? concern.page_data : '';
        let bannerImage = concern ? concern.images ? concern.images.list[0] : '' : '';
        let posts = concern ? concern.posts ? concern.posts.list : [] : [];

        return (
            <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                {this.props.loading && <Loading/>}
                <Helmet>
                    <meta name="description"
                          content={concern?.page_data?.meta_description}/>
                    <link rel="canonical" href="http://mirconcreteblock.com/sister-concerns"/>
                    <title>{concern?.page_data?.meta_title ? concern.page_data.meta_title : 'Group Concerns | Mir Concrete Block'}</title>
                    <meta property="og:title"
                          content={concern?.page_data?.og_title ? concern.page_data.og_title : 'Group Concerns | Mir Concrete Block'}/>
                    <meta property="og:description" content={concern?.page_data?.og_description}/>
                </Helmet>
                <Menu menuType={'menu-fixed'}/>
                <InnerBanner
                    title={pageData ? pageData.title : ''}
                    image={bannerImage ? baseUrl + bannerImage.path + bannerImage.img_name : BlurImg}
                />
                <ConcernList post={posts}/>
            </motion.div>
        )
    }
}

function mapStateToProps(state) {
    return {
        concern: state.concernReducer.concern,
        loading: state.concernReducer.concernLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getConcern: params => dispatch(fetchConcern(params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Concern);
