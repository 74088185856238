import React, {Component} from 'react';
import InnerBanner from '../../components/InnerBanner';
import NewsListing from './components/NewsListing';
import Menu from "../../components/Menu";
import {connect} from 'react-redux'
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import NewsBannerImg from "../../assets/images/dynamic/news/Banner Img.jpg";
import BlurImg from "../../assets/images/blur.jpg";
import {fetchNews, fetchNewsList} from "../../redux/actions/news-events";
import {Helmet} from "react-helmet";
import {Loading} from "../../components/Loading";

class News extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        let mainParam = {
            [ApiParams.page_type]: 'news--events'
        };
        let newsList = {
            [ApiParams.page_id]: '95'
        };

        this.props.getNews(mainParam);
        this.props.getNewsList(newsList);
    }


    render() {

        let {news} = this.props;
        let {newsList} = this.props;

        let pageData = news ? news.page_data : '';
        let BannerImage = news ? news.images ? news.images.list[0] : [] : [];
        let PostList = news ? news.posts ? news.posts.list : [] : [];

        return (
            <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                {this.props.loading && <Loading/>}
                <Helmet>
                    <meta name="description"
                          content={news?.page_data?.meta_description}/>
                    <link rel="canonical" href="http://mirconcreteblock.com/news-events"/>
                    <title>{news?.page_data?.meta_title ? news.page_data.meta_title : 'News & Events | Mir Concrete Block'}</title>
                    <meta property="og:title"
                          content={news?.page_data?.og_title ? news.page_data.og_title : 'News & Events | Mir Concrete Block'}/>
                    <meta property="og:description" content={news?.page_data?.og_description}/>
                </Helmet>
                <Menu menuType={'menu-fixed'}/>
                <InnerBanner
                    title={pageData ? pageData.title : ''}
                    image={BannerImage ? baseUrl + BannerImage.path + BannerImage.img_name : BlurImg}
                />
                <>
                    <NewsListing post={newsList}/>
                </>
            </motion.div>
        )
    }
}


function mapStateToProps(state) {
    return {
        news: state.newsReducer.news,
        loading: state.newsReducer.newsLoading,
        newsList: state.newsReducer.newsList,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getNews: params => dispatch(fetchNews(params)),
        getNewsList: params => dispatch(fetchNewsList(params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(News);



