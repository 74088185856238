import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {connect} from 'react-redux'
import {setFooterOffset} from '../redux/actions/footer'
import FooterLogo from '../assets/images/logo-footer.svg'
import FacebookLogo from '../assets/images/facebook.svg'
import InstagramLogo from '../assets/images/instagram.svg'
import LinkedinLogo from '../assets/images/linkedin.svg'
// import TwitterLogo from '../assets/images/twitter.svg'
import {Link} from "react-router-dom";
import {setSectionId} from "../redux/actions/menu";

import Youtube from '../assets/images/youtube.svg'
import TwitterLogo from "../assets/images/twitter.svg";

const Footer = (props) => {
    let {setSectionId} = props;
    useEffect(() => {
        let elem = document.getElementById("main-container")
        if (elem && elem.offsetLeft) {
            props.getFooterOffset(elem.offsetLeft)
        }
    }, []);
    return (
        <StyledFooter id="footer" className="footer pt-80">
            <Container>
                <Row className="desktop-menu">
                    <div className="footer__menu">
                        <div className="footer__menu__single">
                            <Link to={'/'}>
                                <img src={FooterLogo} alt=""/>
                            </Link>
                            <ul className="footer__menu__single__social">
                                <li>
                                    <Link to={{ pathname: "https://www.facebook.com/mirconcreteblock" }} target="_blank">
                                        <img src={FacebookLogo} alt="Facebook Logo"/>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={{ pathname: "https://www.linkedin.com/company/72345419/" }} target="_blank">
                                        <img src={LinkedinLogo} alt="LinkedIn Logo"/>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={{ pathname: "https://www.instagram.com/mirconcreteblock/" }} target="_blank">
                                        <img src={InstagramLogo} alt="Instagram Logo"/>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={{ pathname: "https://twitter.com/MirConcrete" }} target="_blank">
                                        <img src={TwitterLogo} alt="Instagram Logo"/>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={{ pathname: "https://www.youtube.com/channel/UCZ9So7llxKfRH8vkkm9TTew" }} target="_blank">
                                        <img src={Youtube} alt="Youtube Logo"/>
                                    </Link>
                                </li>
                            </ul>
                        </div>

                        <div className="footer__menu__single">
                            <h4>Menu</h4>
                            <ul>
                                <li onClick={() => setSectionId('home')}>
                                    <Link to={'/'}>Home</Link>
                                </li>
                                <li onClick={() => setSectionId('about-us')}>
                                    <Link to={'/about-us'}>About Us</Link>
                                </li>
                                <li onClick={() => setSectionId('products')}>
                                    <Link to={'/product'}>Products</Link>
                                </li>
                                <li onClick={() => setSectionId('')}>
                                    <Link to={'/key-information'}>Key Informations</Link>
                                </li>
                                <li onClick={() => setSectionId('')}>
                                    <Link to={'/quality-assurance'}>Quality Assurance</Link>
                                </li>
                            </ul>
                        </div>

                        <div className="footer__menu__single">
                            <h4>Important Links</h4>
                            <ul>
                                <li onClick={() => setSectionId('')}>
                                    <Link to={'/clients'}>Clients</Link>
                                </li>
                                <li onClick={() => setSectionId('')}>
                                    <Link to={'/sister-concerns'}>Group Concerns</Link>
                                </li>
                                <li onClick={() => setSectionId('')}>
                                    <Link to={'/career'}>Career</Link>
                                </li>
                                <li onClick={() => setSectionId('')}>
                                    <Link to={'/news-events'}>News & Events</Link>
                                </li>
                                <li onClick={() => setSectionId('')}>
                                    <Link to={'/contact-us'}>Contact Us</Link>
                                </li>
                            </ul>
                        </div>

                        <div className="footer__menu__single">
                            <h4>&nbsp;</h4>
                            <ul>
                                <li onClick={() => setSectionId('')}><Link to={'/gallery'}>Gallery</Link></li>
                                <li onClick={() => setSectionId('')}><Link to={'/cost-reduction-chart'}>Cost Reduction Chart</Link></li>
                            </ul>
                        </div>
                    </div>
                </Row>

                <Row className="mobile-menu">

                    <div className="mobile-menu__logo col">
                        <img src={FooterLogo} alt="Mir Concrete Block"/>
                        <ul className="mobile-menu__social">
                            <li>
                                <Link to={{ pathname: "https://www.facebook.com/mirconcreteblock" }} target="_blank">
                                    <img src={FacebookLogo} alt="Facebook Logo"/>
                                </Link>
                            </li>
                            <li>
                                <Link to={{ pathname: "https://www.linkedin.com/company/72345419/" }} target="_blank">
                                    <img src={LinkedinLogo} alt="LinkedIn Logo"/>
                                </Link>
                            </li>
                            <li>
                                <Link to={{ pathname: "https://www.instagram.com/mirconcreteblock/" }} target="_blank">
                                    <img src={InstagramLogo} alt="Instagram Logo"/>
                                </Link>
                            </li>
                            <li>
                                <Link to={{ pathname: "https://twitter.com/MirConcrete" }} target="_blank">
                                    <img src={TwitterLogo} alt="Instagram Logo"/>
                                </Link>
                            </li>
                            <li>
                                <Link to={{ pathname: "https://www.youtube.com/channel/UCZ9So7llxKfRH8vkkm9TTew" }} target="_blank">
                                    <img src={Youtube} alt="Youtube Logo"/>
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div className="mobile-menu__items col">
                        <Row>
                            <Col sm={6} xs={6}>
                                <h4>Menu</h4>
                                <ul>
                                    <li onClick={() => setSectionId('home')}>
                                        <Link to={'/home'}>Home</Link>
                                    </li>
                                    <li onClick={() => setSectionId('about-us')}>
                                        <Link to={'/about-us'}>About Us</Link>
                                    </li>
                                    <li onClick={() => setSectionId('products')}>
                                        <Link to={'/product'}>Products</Link>
                                    </li>
                                    <li onClick={() => setSectionId('')}>
                                        <Link to={'/key-information'}>Key Informations</Link>
                                    </li>
                                    <li onClick={() => setSectionId('')}>
                                        <Link to={'/quality-assurance'}>Quality Assurance</Link>
                                    </li>
                                </ul>
                            </Col>
                            <Col sm={6} xs={6}>
                                <h4>Important Links</h4>
                                <ul>
                                    <li onClick={() => setSectionId('')}>
                                        <Link to={'/clients'}>Clients</Link>
                                    </li>
                                    <li onClick={() => setSectionId('')}>
                                        <Link to={'/sister-concerns'}>Group Concerns</Link>
                                    </li>
                                    <li onClick={() => setSectionId('')}>
                                        <Link to={'/career'}>Career</Link>
                                    </li>
                                    <li onClick={() => setSectionId('')}>
                                        <Link to={'/news-events'}>News & Events</Link>
                                    </li>
                                    <li onClick={() => setSectionId('')}>
                                        <Link to={'/contact-us'}>Contact Us</Link>
                                    </li>
                                </ul>
                            </Col>
                            <Col sm={6} xs={6}>
                                <h4>&nbsp;</h4>
                                <ul>
                                    <li onClick={() => setSectionId('')}><Link to={'/gallery'}>Gallery</Link></li>
                                    <li onClick={() => setSectionId('')}><Link to={'/cost-reduction-chart'}>Cost Reduction Chart</Link></li>
                                </ul>
                            </Col>
                        </Row>
                    </div>

                </Row>


                <Row>
                    <Col className="footer__bottom">
                        <p>© {new Date().getFullYear()} Mir Concrete Block. All Rights Reserved.</p>
                        <p>Design & Developed by <a target="_blank" href="http://dcastalia.com/" rel="nofollow">Dcastalia</a></p>
                    </Col>
                </Row>

            </Container>

        </StyledFooter>
    )
};


const StyledFooter = styled.div`
    background-color: #314550;
    .container{
        position: relative;
        &:after{
            content: '';
            position: absolute;
            bottom: 80px;
            height: 1px;
            left: 0;
            width: 100%;
            background-color:rgba(255,255,255,0.2) ;
        }
    }
  
    .footer__top{
      margin-bottom: 58px;
        img{
          height: 55px;
        }
    }
    .footer__menu{
       display: flex;
       flex-wrap: nowrap;
       width: 100%;
       margin-bottom: 80px;
      &__single{
        &__social{
          display:flex;
          margin-top: 40px;
          li{
            padding-right: 15px;
          }
        }
        &:first-child{
          flex: 2;
        }
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        padding: 0 15px;
       
       h4{
        margin: 0 0 40px 0;
        font-size: 24px;
        font-weight: normal;
        line-height: 32px;
        letter-spacing: 0.5px;
        text-transform: capitalize;
        color: #FFFFFF;
       }
        ul{
            li{
                 a{
                  font-size: 16px;
                  font-weight: 300;
                  line-height: 26px;
                  display: block;
                  margin-bottom: 15px;
                  text-transform: capitalize;
                  color: #ffffff;
                  &:hover{
                    text-decoration: underline;
                  }
                 }
               &:nth-last-of-type(1) a{
                 margin-bottom: 0;
               }  
            }
        }
      }
    }
    
    .footer__bottom{
      padding: 17px 15px;
      p{
      font-weight: 300;
      font-size: 14px;
      margin-bottom: 3px;
      color: #E8E8E8;
        &:nth-last-of-type(1){
        margin-bottom: 0;
        }
        a{
        color: #008c45;
        &:hover{
              text-decoration: underline !important;
            }
          }
      }
      
    }
    
    @media(min-width:767px){
        .mobile-menu{
            display: none;
        }
    }
    @media(max-width:767px){
        .desktop-menu{
            display: none;
        }
        .mobile-menu{
            display: block;
            .mobile-menu__logo{
                text-align: center;
                & > img{
                    width: 120px;
                }
            }
            .mobile-menu__social{
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin-top: 30px;
                margin-bottom: 60px;
                li{
                    padding: 0 7.5px;
                }
            }
            .mobile-menu__items{
                margin-bottom: 30px;
                h4{
                    font-size: 20px;
                    font-weight: normal;
                    line-height: 27px;
                    letter-spacing: 0.25px;
                    color: #FFFFFF;
                }
                ul{
                    margin-top: 20px;
                    margin-bottom: 30px;
                    li{
                        a{
                            font-size: 14px;
                            font-weight: 300;
                            line-height: 26px;
                            letter-spacing: .25px;
                            color: #FFFFFF;
                        }
                    }
                }
            }
        }
    }
    @media(max-width:414px){
    
    }
    
    @media (max-width:1030px){
      .footer__menu{
        flex-wrap: wrap;
        width: 100%;
      }
      .footer__menu__single{
        min-width: 25%;
        max-width: 25%;
        margin-bottom: 40px;
      }
    }
`;

function mapDispatchToProps(dispatch) {
    return {
        getFooterOffset: data => dispatch(setFooterOffset(data)),
        setSectionId: data => dispatch(setSectionId(data))
    }
}

export default connect(null, mapDispatchToProps)(Footer);







