import {COST} from "../../constants/cost";

let initialState = {
    cost: [],
    costLoading: false,
    costErr: ''
};

export default function costReducer(state = initialState, action) {
    switch (action.type) {
        case COST.FETCH_COST.MAIN:
            return {
                ...state,
                cost: [],
                costLoading: true,
                costErr: ''
            };

        case COST.FETCH_COST.SUCCESS:
            return {
                ...state,
                cost: action.result,
                costLoading: false,
                costErr: ''
            };

        case COST.FETCH_COST.FAILURE:
            return {
                ...state,
                cost: [],
                costLoading: false,
                costErr: 'err from server'
            };
        default:
            return state
    }
}