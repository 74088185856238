import React from 'react';
import {hover, darkGreen} from '../globalStyleVars';
import styled from 'styled-components';
import VisibilitySensor from "react-visibility-sensor";

const subTitle = ({text, fontSize, fontWeight, color, letterSpacing, lineHeight, margin, textTransform}) => {
    return (
        <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
            {({isVisible}) =>
                <StyledSubTitle
                    fontSize={fontSize}
                    fontWeight={fontWeight}
                    color={color}
                    lineHeight={lineHeight}
                    letterSpacing={letterSpacing}
                    textTransform={textTransform}
                    margin={margin}
                    className={`${isVisible ? 'anim-active' : ''} fade-up sub-title`} >{text}
                </StyledSubTitle>
            }
        </VisibilitySensor>
    )
};


const StyledSubTitle = styled.h3`
    font-size: ${props => props.fontSize || '14px'};
    font-weight:${props => props.fontWeight || 500};
    margin:${props => props.margin || '0'}; ;
    line-height:${props => props.lineHeight || '21px'};
    color:${props => props.color || `${hover}`};
    text-transform: ${props => props.textTransform || 'inherit'};
    letter-spacing:${props => props.letterSpacing || '3px'};
    @media (max-width:767px){
      font-size: 16px;
      font-weight: normal;
      margin-bottom: 30px;
    }
`;

export default subTitle;
