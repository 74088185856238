export const GALLERY = {
    FETCH_GALLERY: {
        MAIN: 'FETCH_GALLERY',
        SUCCESS: 'FETCH_GALLERY_SUCCESS',
        FAILURE: 'FETCH_GALLERY_FAILURE'
    },
    FETCH_PHOTO_GALLERY: {
        MAIN: 'FETCH_PHOTO_GALLERY',
        SUCCESS: 'FETCH_PHOTO_GALLERY_SUCCESS',
        FAILURE: 'FETCH_PHOTO_GALLERY_FAILURE'
    },
    FETCH_VIDEO_GALLERY: {
        MAIN: 'FETCH_VIDEO_GALLERY',
        SUCCESS: 'FETCH_VIDEO_GALLERY_SUCCESS',
        FAILURE: 'FETCH_VIDEO_GALLERY_FAILURE'
    }

};