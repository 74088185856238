import {takeLatest, put, call} from 'redux-saga/effects'
import Logger from '../../../helpers/logger'
import {AxiosServices} from '../../../network/AxiosService';
import {ApiServices} from '../../../network/ApiServices';
import {SERVICES} from "../../constants/services";

function* fetchServicesPageData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_PAGE_BY_TYPE_DATA,
      actions.params
    )

    yield put({
      type: SERVICES.FETCH_SERVICES_PAGE.SUCCESS,
      result:  result.data
    })
  } catch(err) {
    Logger(err)
    yield put({
      type: SERVICES.FETCH_SERVICES_PAGE.FAILURE
    })
  }
}

function* fetchServiceListData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_CHILD_BY_PAGE_ID,
      actions.params
    )

    yield put({
      type: SERVICES.FETCH_SERVICES_LIST.SUCCESS,
      result:  result.data
    })
  } catch(err) {
    Logger(err)
    yield put({
      type: SERVICES.FETCH_SERVICES_LIST.FAILURE
    })
  }
}

function* fetchServiceListChildData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_CHILD_BY_PAGE_ID,
      actions.params
    )

    yield put({
      type: SERVICES.FETCH_SERVICES_CHILD.SUCCESS,
      result:  result.data
    })
  } catch(err) {
    Logger(err)
    yield put({
      type: SERVICES.FETCH_SERVICES_CHILD.FAILURE
    })
  }
}

export default function* serviceSaga() {
  yield takeLatest(SERVICES.FETCH_SERVICES_PAGE.MAIN, fetchServicesPageData)
  yield takeLatest(SERVICES.FETCH_SERVICES_LIST.MAIN, fetchServiceListData)
  yield takeLatest(SERVICES.FETCH_SERVICES_CHILD.MAIN, fetchServiceListChildData)
}
