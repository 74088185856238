import { combineReducers } from 'redux'
import homeReducer from './home'
import divisionReducer from "./division";
import footerReducer from "./footer";
import aboutReducer from "./about";
import serviceReducer from "./services";
import careerReducer from "./career";
import mediaReducer from "./media";
import formReducer from './form'
import contactReducer from './contact'
import menuReducer from "./menu";
import equipmentsReducer from "./equipments";
import qualityReducer from "./qualityAssurance";
import keyInformationReducer from './keyInformations';
import concernReducer from './concern';
import clientReducer from './client';
import newsReducer from './news-events';
import productReducer from './product';
import galleryReducer from './gallery';
import costReducer from './cost';

export default combineReducers({
  homeReducer,
  divisionReducer,
  footerReducer,
  aboutReducer,
  serviceReducer,
  careerReducer,
  mediaReducer,
  formReducer,
  contactReducer,
  menuReducer,
  equipmentsReducer,
  qualityReducer,
  keyInformationReducer,
  concernReducer,
  clientReducer,
  newsReducer,
  productReducer,
  galleryReducer,
  costReducer
})
