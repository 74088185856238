import React, {Component} from 'react';
// components
import Slider from './components/BannerSlider'
import AboutUs from './components/AboutUs'
import FeaturedProduct from './components/FeaturedProduct'
import ClientSlider from './components/ClientSlider'
import ContactUs from './components/ContactUs'
import Menu from "../../components/Menu";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import Counter from "./components/Counter";

class HomePage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                <Menu/>
                <Slider/>
                <AboutUs/>
                <FeaturedProduct />
                <Counter/>
                <ClientSlider/>
                <ContactUs/>
                {/*<Map />*/}
            </motion.div>
        );
    }
};

export default HomePage;



