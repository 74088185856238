import React from 'react';
import {Modal} from "react-bootstrap";
import {Table} from "react-bootstrap";
import ReactHtmlParser from 'react-html-parser'


const Popup = ({show, handleClose, post, title}) => {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title><h2>{title}</h2></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table responsive>
                    {ReactHtmlParser(post)}
                </Table>
            </Modal.Body>
        </Modal>

    )
};


export default Popup;
