export const DIVISION = {
  FETCH_DIVISION_PAGE: {
    MAIN: 'FETCH_DIVISION_PAGE',
    SUCCESS: 'FETCH_DIVISION_PAGE_SUCCESS',
    FAILURE: 'FETCH_DIVISION_PAGE_FAILURE'
  },
  FETCH_DIVISION_LIST: {
    MAIN: 'FETCH_DIVISION_LIST',
    SUCCESS: 'FETCH_DIVISION_LIST_SUCCESS',
    FAILURE: 'FETCH_DIVISION_LIST_FAILURE'
  },
  FETCH_DIVISION_LIST_CHILD: {
    MAIN: 'FETCH_DIVISION_LIST_CHILD',
    SUCCESS: 'FETCH_DIVISION_LIST_CHILD_SUCCESS',
    FAILURE: 'FETCH_DIVISION_LIST_CHILD_FAILURE'
  },
  FETCH_DIVISION_LIST_CHILD_PAGE: {
    MAIN: 'FETCH_DIVISION_LIST_CHILD_PAGE',
    SUCCESS: 'FETCH_DIVISION_LIST_CHILD_PAGE_SUCCESS',
    FAILURE: 'FETCH_DIVISION_LIST_CHILD_PAGE_FAILURE'
  }
}
