import React from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {Link} from "react-router-dom";
import Button from '../../../components/Button';
import Text from '../../../components/Text';
import SubTitle from '../../../components/SubTitle';
import BlurImg from "../../../assets/images/blur.jpg";

import moment from "moment";
import {baseUrl} from "../../../components/BaseUrl";
import Title from "../../../components/Title";

const NewsListing = ({post}) => {
    return (
        <StyledNewsListing className="news-listing pt-80">
            <Container>

                {post ? post.map((data, index) => (
                    <Row key={index}>
                        <Col sm={12}>
                            <Title margin={'0 0 40px 0'} text={data.page_data ? data.page_data.title : ''}/>
                        </Col>
                        <Col key={'data.data.id'} md={12}>

                            <div className="news-listing__single">
                                {data.posts ? data.posts.list ? data.posts.list.map((pos, i) => {
                                    let thumbImageData = pos?.images ? pos.images.find(e => e.is_thumb === 'on') : '';
                                    let thumbImagePath = thumbImageData?.path ? baseUrl + thumbImageData?.path + thumbImageData?.img_name : BlurImg;
                                        return (
                                            <Row key={i}>
                                                <Col md={4}>
                                                    <div className="news-listing__single__image">
                                                        <img
                                                            src={thumbImagePath}
                                                            alt=""/>
                                                    </div>
                                                </Col>
                                                <Col md={8}>
                                                    <div className="news-listing__single__content">
                                                        {/*<span>{moment(data.data.created_at.split(" ")[0]).format("Do MMM  YYYY")}</span>*/}
                                                        <span>{pos.data.Date ? moment(pos.data.Date).format("Do MMM  YYYY") : moment(pos.data.created_at.split(" ")[0]).format("Do MMM  YYYY")}</span>
                                                        <SubTitle text={pos.data.title}
                                                                  fontSize={'24px'} fontWeight={'500'} color={'#314550'}
                                                                  lineHeight={'36px'} letterSpacing={'1px'}
                                                                  margin={'30px 0 20px 0'}/>
                                                        {/*<Text fontWeight={'normal'} fontSize={'16px'} color={'#000000'}*/}
                                                        {/*      lineHeight={'27px'}>*/}
                                                        {/*    <p>{pos.data.short_des}</p>*/}
                                                        {/*</Text>*/}
                                                        <Button color={'#008C45'}
                                                                link={`/news-events/${pos.data.slug}`}
                                                                background={'transparent'}
                                                                text={'Read More'}
                                                                fontSize={'16px'}
                                                                fontWeight={'normal'}
                                                                letterSpacing={'.5px'} border={'none'}
                                                                hoverBackground={'transparent'}
                                                                width={'140px'}
                                                                lineHeight={'16px'}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        )
                                    }
                                ) : "Loading...." : ''}

                            </div>
                            <hr/>
                        </Col>
                    </Row>
                )) : 'Loading'}


            </Container>
        </StyledNewsListing>
    )
};

const StyledNewsListing = styled.section`
  &.news-listing {
    padding-bottom: 20px;

    .news-listing__single {
      .col-md-4 {
        margin-bottom: 30px;
      }

      &__image {
        position: relative;
        padding-top: calc(250 / 370 * 100%);
        background-color: #F9F9F9;

        img {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }


    .news-listing__single__content {
      position: relative;
      height: 100%;

      > span {
        font-size: 16px;
        font-weight: normal;
        line-height: 16px;
        letter-spacing: 0;
        color: #008C45;
        margin-bottom: 30px;
      }

      .dc-btn {
        position: absolute;
        bottom: 35px;
        left: 0;

        a {
          height: 20px;
        }
      }
    }

    hr {
      border-top: 1px solid #CECECE;
      margin-top: 35px;
      margin-bottom: 60px;
    }
  }

  @media (max-width: 767px) {
    &.news-listing {
      padding-top: 60px;
      padding-bottom: 0;

      .news-listing__single__image {
        margin-bottom: 20px;
      }

      .news-listing__single__content {
        position: static;

        > span {
          font-size: 14px;
          font-weight: normal;
          line-height: 21px;
        }

        .sub-title {
          font-size: 20px;
          font-weight: 500;
          line-height: 26px;
          margin-top: 20px;
          margin-bottom: 20px;
        }

        .dc-btn {
          position: static;
          margin-top: 20px;

          a {
            height: 20px;
          }
        }
      }
    }
  }
`;

export default NewsListing;
