import React, {Component} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import moment from "moment";
import Button from "../../../components/Button";
import Title from '../../../components/Title';
import ArrowWhite from '../../../assets/images/btn-arrow-white.svg';
import htmlParser from 'react-html-parser';

const JobListing = ({jobs}) => {
    return (
        <div>
            <StyledJobItems className="pt-80 JobItem">
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="job__list">
                                <Title text={'Available Jobs'} color={'#314550'} fontSize={'36px'}
                                       fontWeight={'500'} lineHeight={'46px'} letterSpacing={'1px'}
                                       margin={'0 0 40px 0'}
                                />
                                <Row>
                                    {jobs ? jobs.map(data => (
                                        <Col md={6} key={data.page_data.id}>
                                            <div className="job__list__single">
                                                <div className="job__list__single__date">
                                                    <p>{moment(data.page_data.created_at.split(" ")[0]).format('MMMM')}</p>
                                                    <h4>{moment(data.page_data.created_at.split(" ")[0]).format('DD')}</h4>
                                                    <p>Deadline</p>
                                                </div>

                                                <div className="job__list__single__desc">
                                                    <h4>{data.page_data.title}</h4>

                                                    {htmlParser(data.page_data.description)}

                                                    <Button text={'View Details'} fontSize={'14px'}
                                                            fontWeight={'normal'}
                                                            color={'#008C45'} border={'2px solid #008C45'}
                                                            img={ArrowWhite}
                                                            background={'#008C45'} hoverBackground={'#008C45'}
                                                            hoverColor={'#ffffff'} margin={'15px 0 0 0'} width={'200px'}
                                                            link={`/career/apply/${data.page_data.slug}`}
                                                    />

                                                </div>
                                            </div>
                                        </Col>
                                    )):''}


                                </Row>
                            </div>
                        </Col>
                    </Row>

                </Container>

            </StyledJobItems>

        </div>
    )
};

//items
const StyledJobItems = styled.section`
    padding-bottom: 50px;
    .job__list{
        &__single{
            background-color: #F9F9F9;
            padding: 35px 40px 65px 70px;
            //box-shadow: 0 0 10px rgba(0,0,0,0.06);
            display: inline-flex;
            width: 100%;
            margin-bottom: 30px;
            align-items: center;
            &__date{
                padding-right: 45px;                
                p{
                    font-size: 14px;
                    font-weight: 300;
                    height: 21px;
                    color: #314550;
                    margin: 0;
                }
                h4{
                    font-size: 48px;
                    color: #008C44;
                    margin: 0;
                    line-height: 58px;
                    font-weight: bold;
                }
            }
            &__desc{
                padding-left: 30px;
                width: 100%;
                border-left:1px solid #EBEBEB;
                h4{
                    font-size: 20px;
                    line-height: 24px;
                    margin: 0 0 20px 0;
                    font-weight: 500;
                    color: #314550;
                }
                li{
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 300;
                    color: #000000;
                    margin-bottom: 5px;
                    width: 100%;
                    display: flex;
                    span{
                        display: inline-flex;
                        &:nth-of-type(1){
                            flex:1;                     
                        }
                        &:nth-of-type(2){
                            flex:1;
                        }
                    }
                }
            }
        }
    }
  
  
    @media(max-width:991px){
        .job__list__single{
            padding: 30px 20px;
            &__date{
                padding-right: 15px;
            }
            &__desc{
                padding-left: 15px;
            }
        }
    }
    @media(max-width:767px){
        padding-top: 80px !important;
        .job__list__single{
            padding: 30px 20px;
            &__desc{
                padding-left: 15px;
                h4{
                    font-size: 20px;
                }
                li{
                    font-size: 14px;
                    font-weight: 400;
                    span{
                        &:nth-of-type(1){
                            flex: 2;
                        }
                        &:nth-of-type(2){
                            flex: 1;
                            padding-left: 20px;
                        }
                    }
                }
            }
        }
        &__date{
            padding-right: 20px;
            h4{
                font-size: 36px;
                line-height: 40px;
            }
        }
    }
    @media (max-width: 414px){
        .job__list__single {
            display: flex;
            flex-wrap: wrap;
            &__date{
                width: 100%;
                padding-right: 0;
                text-align: center;
                padding-bottom: 15px;
                margin-bottom: 15px;
                border-bottom: 1px solid #EBEBEB;;
            }
            &__desc{
                padding-left: 0;
                border-left: 0;
                li{
                    font-size: 14px;
                    font-weight: 400;
                    span{
                        &:nth-of-type(1){
                            flex: 1;
                        }
                        &:nth-of-type(2){
                            flex: 1;
                            padding-left: 20px;
                        }
                    }
                }
            }
        }
    } 
   
`;

export default JobListing;
