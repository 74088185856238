import {takeLatest, put, call} from 'redux-saga/effects'
import Logger from '../../../helpers/logger'
import {AxiosServices} from '../../../network/AxiosService';
import {MEDIA} from "../../constants/media";
import {ApiServices} from "../../../network/ApiServices";

function* fetchMediaPageData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_PAGE_BY_TYPE_DATA,
      actions.params
    )

    yield put({
      type: MEDIA.FETCH_MEDIA_PAGE.SUCCESS,
      result:  result.data
    })
  } catch(err) {
    Logger(err)
    yield put({
      type: MEDIA.FETCH_MEDIA_PAGE.FAILURE
    })
  }
}

function* fetchMediaListData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_CHILD_BY_PAGE_ID,
      actions.params
    )

    yield put({
      type: MEDIA.FETCH_MEDIA_LIST.SUCCESS,
      result:  result.data
    })
  } catch(err) {
    Logger(err)
    yield put({
      type: MEDIA.FETCH_MEDIA_LIST.FAILURE
    })
  }
}

function* fetchMediaDetailsData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_PAGE_BY_PAGE_SLUG,
      actions.params
    )

    yield put({
      type: MEDIA.FETCH_DETAILS_PAGE.SUCCESS,
      result:  result.data
    })
  } catch(err) {
    Logger(err)
    yield put({
      type: MEDIA.FETCH_DETAILS_PAGE.FAILURE
    })
  }
}

export default function* mediaSaga() {
  yield takeLatest(MEDIA.FETCH_MEDIA_PAGE.MAIN, fetchMediaPageData)
  yield takeLatest(MEDIA.FETCH_MEDIA_LIST.MAIN, fetchMediaListData)
  yield takeLatest(MEDIA.FETCH_DETAILS_PAGE.MAIN, fetchMediaDetailsData)
}
