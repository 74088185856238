import {takeLatest, put, call} from 'redux-saga/effects'
import {AxiosServices} from "../../../network/AxiosService";
import {ApiServices} from "../../../network/ApiServices";
import {COST} from "../../constants/cost";
import Logger from "../../../helpers/logger";

function* fetchCost(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.GET_PAGE_BY_TYPE_DATA,
            actions.params
        )

        yield put({
            type: COST.FETCH_COST.SUCCESS,
            result:  result.data
        })
    } catch(err) {
        Logger(err)
        yield put({
            type: COST.FETCH_COST.FAILURE
        })
    }
}

export default function* costSagas() {
    yield takeLatest(COST.FETCH_COST.MAIN, fetchCost);
}