import {takeLatest, put, call} from 'redux-saga/effects'
import {AxiosServices} from "../../../network/AxiosService";
import {ApiServices} from "../../../network/ApiServices";
import {GALLERY} from "../../constants/gallery";
import Logger from "../../../helpers/logger";

function* fetchGallery(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.GET_PAGE_BY_TYPE_DATA,
            actions.params
        )

        yield put({
            type: GALLERY.FETCH_GALLERY.SUCCESS,
            result:  result.data
        })
    } catch(err) {
        Logger(err)
        yield put({
            type: GALLERY.FETCH_GALLERY.FAILURE
        })
    }
}
function* fetchPhoto(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.GET_PAGE_BY_TYPE_DATA,
            actions.params
        )

        yield put({
            type: GALLERY.FETCH_PHOTO_GALLERY.SUCCESS,
            result:  result.data
        })
    } catch(err) {
        Logger(err)
        yield put({
            type: GALLERY.FETCH_PHOTO_GALLERY.FAILURE
        })
    }
}
function* fetchVideo(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.GET_PAGE_BY_TYPE_DATA,
            actions.params
        )

        yield put({
            type: GALLERY.FETCH_VIDEO_GALLERY.SUCCESS,
            result:  result.data
        })
    } catch(err) {
        Logger(err)
        yield put({
            type: GALLERY.FETCH_VIDEO_GALLERY.FAILURE
        })
    }
}

export default function* gallerySaga() {
    yield takeLatest(GALLERY.FETCH_GALLERY.MAIN, fetchGallery);
    yield takeLatest(GALLERY.FETCH_PHOTO_GALLERY.MAIN, fetchPhoto);
    yield takeLatest(GALLERY.FETCH_VIDEO_GALLERY.MAIN, fetchVideo);
}