import {takeLatest, put, call} from 'redux-saga/effects'
import {AxiosServices} from "../../../network/AxiosService";
import {ApiServices} from "../../../network/ApiServices";
import {QUALITY} from "../../constants/qualityAssurance";
import Logger from "../../../helpers/logger";

function* fetchQuality(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.GET_PAGE_BY_TYPE_DATA,
            actions.params
        )

        yield put({
            type: QUALITY.FETCH_QUALITY.SUCCESS,
            result:  result.data
        })
    } catch(err) {
        Logger(err)
        yield put({
            type: QUALITY.FETCH_QUALITY.FAILURE
        })
    }
}

export default function* qualitySagas() {
    yield takeLatest(QUALITY.FETCH_QUALITY.MAIN, fetchQuality);
}