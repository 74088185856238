import {MEDIA} from "../../constants/media";

let initialState = {
  mediaPage: [],
  mediaPageLoading: false,
  mediaPageErr: '',
  mediaPageList: [],
  mediaPageListLoading: false,
  mediaPageListErr: '',
  mediaPageDetails: [],
  mediaDetailsLoading: false,
  mediaDetailsErr: ''
}

export default function mediaReducer(state = initialState, action) {
  switch(action.type) {
    case MEDIA.FETCH_MEDIA_PAGE.MAIN:
      return {
        ...state,
        mediaPage: [],
        mediaPageLoading: true,
        mediaPageErr: ''
      }

    case MEDIA.FETCH_MEDIA_PAGE.SUCCESS:
      return {
        ...state,
        mediaPage: action.result,
        mediaPageLoading: false,
        mediaPageErr: ''
      }

    case MEDIA.FETCH_MEDIA_PAGE.FAILURE:
      return {
        ...state,
        mediaPage: [],
        mediaPageLoading: false,
        mediaPageErr: 'Error from media page fetching'
      }

    case MEDIA.FETCH_MEDIA_LIST.MAIN:
      return {
        ...state,
        mediaPageList: [],
        mediaPageListLoading: true,
        mediaPageListErr: ''
      }

    case MEDIA.FETCH_MEDIA_LIST.SUCCESS:
      return {
        ...state,
        mediaPageList: action.result,
        mediaPageListLoading: false,
        mediaPageListErr: ''
      }

    case MEDIA.FETCH_MEDIA_LIST.FAILURE:
      return {
        ...state,
        mediaPageList: [],
        mediaPageListLoading: false,
        mediaPageListErr: 'Error from media list fetching'
      }

    case MEDIA.FETCH_DETAILS_PAGE.MAIN:
      return {
        ...state,
        mediaPageDetails: [],
        mediaDetailsLoading: true,
        mediaDetailsErr: ''
      }

    case MEDIA.FETCH_DETAILS_PAGE.SUCCESS:
      return {
        ...state,
        mediaPageDetails: action.result,
        mediaDetailsLoading: false,
        mediaDetailsErr: ''
      }

    case MEDIA.FETCH_DETAILS_PAGE.FAILURE:
      return {
        ...state,
        mediaPageDetails: [],
        mediaDetailsLoading: false,
        mediaDetailsErr: ''
      }

    default:
      return state
  }
}
