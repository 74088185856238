import React, {Component} from 'react';
import InnerBanner from '../../components/InnerBanner';
import Accordion from './components/Accordion';
import InfoList from './components/InfoList';
import Video from './components/Video';
import Menu from "../../components/Menu";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import KeyInformationBannerImg from '../../assets/images/dynamic/key-information/Banner Img.jpg'
import {fetchKeyInformation} from "../../redux/actions/keyInformations";
import {connect} from "react-redux";
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import BlurImg from "../../assets/images/blur.jpg";
import {Helmet} from "react-helmet";
import {Loading} from "../../components/Loading";


class KeyInformation extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        let mainParam = {
            [ApiParams.page_type]: 'key-informations'
        };

        this.props.getKey_information(mainParam);
    }


    render() {

        let {key_information} = this.props;

        let pageData = key_information ? key_information.page_data : '';
        let bannerImage = key_information ? key_information.images ? key_information.images.list[0] : '' : '';
        let Posts = key_information ? key_information.posts ? key_information.posts.list : '' : '';

        let videoThumb = key_information ? key_information.images ? key_information.images.list.find(f => f.is_thumb === 'on') : '' : "";

        return (
            <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                {this.props.loading && <Loading/>}
                <Helmet>
                    <meta name="description"
                          content={key_information?.page_data?.meta_description}/>
                    <link rel="canonical" href="http://mirconcreteblock.com/key-information"/>
                    <title>{key_information?.page_data?.meta_title ? key_information.page_data.meta_title : 'Key Informations | Mir Concrete Block'}</title>
                    <meta property="og:title"
                          content={key_information?.page_data?.og_title ? key_information.page_data.og_title : 'Key Informations | Mir Concrete Block'}/>
                    <meta property="og:description" content={key_information?.page_data?.og_description}/>
                </Helmet>
                <Menu menuType={'menu-fixed'}/>
                <InnerBanner
                    title={pageData ? pageData.title : ''}
                    image={bannerImage ? baseUrl + bannerImage.path + bannerImage.img_name : BlurImg}
                />
                <Accordion post={Posts}/>
                <Video thumb={videoThumb}/>
                <InfoList pageData={pageData}/>

            </motion.div>
        )
    }
}


function mapStateToProps(state) {
    return {
        key_information: state.keyInformationReducer.key_information,
        loading: state.keyInformationReducer.key_informationLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getKey_information: params => dispatch(fetchKeyInformation(params)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(KeyInformation);




