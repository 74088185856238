import {takeLatest, put } from 'redux-saga/effects'
import {MENU} from "../../constants/menu";

function* setSectionId(action) {
  try {
    yield put({
      type: MENU.SCROLL_TO_VIEW.SUCCESS,
      value: action.data
    })
  } catch (err) {
    yield put({
      type: MENU.SCROLL_TO_VIEW.FAILURE
    })
  }
}

export default function* menuSaga () {
  yield takeLatest(MENU.SCROLL_TO_VIEW.MAIN, setSectionId)
}
