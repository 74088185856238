import React, {Component} from 'react';
import InnerBanner from '../../components/InnerBanner';
import ProductListing from './components/ProductListing';
import Menu from "../../components/Menu";
import {connect} from 'react-redux'
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import PageDescription from "../../components/PageDescription";
import BlurImg from "../../assets/images/blur.jpg";
import {
    fetchProductList,
    fetchProductPage,
} from "../../redux/actions/product";
import {Helmet} from "react-helmet";
import {Loading} from "../../components/Loading";

class Product extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        let productPage = {
            [ApiParams.page_type]: 'product'
        };
        let productList = {
            [ApiParams.page_id]: '97'
        };
        this.props.getProductPage(productPage);
        this.props.getProductList(productList);

    }

    render() {


        let {ProductPage} = this.props;
        let {productList} = this.props;

        let PageData = ProductPage ? ProductPage.page_data : [];
        let BannerImage = ProductPage ? ProductPage.images ? ProductPage.images.list[0] : [] : [];


        return (
            <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                {this.props.loading && <Loading/>}
                <Helmet>
                    <meta name="description"
                          content={ProductPage?.page_data?.meta_description}/>
                    <link rel="canonical" href="http://mirconcreteblock.com/product"/>
                    <title>{ProductPage?.page_data?.meta_title ? ProductPage.page_data.meta_title : 'Products | Mir Concrete Block'}</title>
                    <meta property="og:title"
                          content={ProductPage?.page_data?.og_title ? ProductPage.page_data.og_title : 'Products | Mir Concrete Block'}/>
                    <meta property="og:description" content={ProductPage?.page_data?.og_description}/>
                </Helmet>
                <Menu menuType={'menu-fixed'}/>
                <InnerBanner
                    title={PageData ? PageData.title : ''}
                    image={BannerImage ? baseUrl + BannerImage.path + BannerImage.img_name : BlurImg}
                />
                <PageDescription pageDesc={{
                    title: `${PageData ? PageData.short_desc : ''}`,
                    description: `${PageData ? PageData.description : ''}`
                }}/>
                <>
                    {productList ? productList.length ? (<>
                            <ProductListing page={productList}/>
                        </>
                    ) : null : null}
                </>
            </motion.div>
        )
    }
}

function mapStateToProps(state) {
    return {
        ProductPage: state.productReducer.ProductPage,
        productList: state.productReducer.productList,
        loading: state.productReducer.productListLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getProductPage: params => dispatch(fetchProductPage(params)),
        getProductList: params => dispatch(fetchProductList(params)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Product);

