import React, {Component, useEffect, useState, useRef} from 'react';
import Slick from "react-slick";
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {hover, darkGreen} from '../../../globalStyleVars';
import Button from '../../../components/Button';
import {baseUrl} from '../../../components/BaseUrl';
import {connect} from 'react-redux';
import {fetchHomeBannerSlider} from "../../../redux/actions/home";
import {ApiParams} from "../../../constants/apiParams";
import $ from 'jquery';
import {Helmet} from 'react-helmet';

// css
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// img
import LeftIcon from '../../../assets/images/navLeft.svg'
import RightIcon from '../../../assets/images/navRight.svg'
import {Loading} from "../../../components/Loading";

function RightArrow(p) {
    const {className, style, onClick} = p;
    return (
        <li className="go-right" onClick={onClick}><img src={RightIcon} height="51" width="51" alt=""/></li>
    );
}

function LeftArrow(p) {
    const {className, style, onClick} = p;
    return (
        <li className="go-left" onClick={onClick}><img src={LeftIcon} height="51" width="51" alt=""/></li>
    );
}

const BannerSlider = (props) => {
    let {bannerData, footerOffset} = props;
    let [offsetLeftInit, setOffsetLeft] = useState([]);
    const bannerArray = bannerData ? bannerData.images ? bannerData.images.list : [] : [];


    let len = bannerArray.length;
    const offset = useRef();
    useEffect(() => {
        let params = {
            [ApiParams.page_type]: 'home-banner-slider'
        };
        props.getHomeBannerSliderData(params);

        let getOffsetLeft = offset.current.offsetLeft;
        setOffsetLeft(getOffsetLeft);


        $('#ScrollTo').click(function () {

            var $target = $(this.hash);
            $target = $target.length && $target ||
                $('[name=' + this.hash.slice(1) + ']');
            if ($target.length) {
                var targetOffset = $target.offset().top;
                $('html,body')
                    .animate({scrollTop: targetOffset}, 1000);
                return false;
            }

        });
    }, [])


    const settings = {
        dots: false,
        fade: true,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 1000000,
        autoplay: true,
        pauseOnHover: false,
        nextArrow: <RightArrow/>,
        prevArrow: <LeftArrow/>
    };

    return (
        <>
            <StyledSlide offset={offsetLeftInit} className="banner-slider">
                {props.loading && <Loading/>}
                <Helmet>
                    <meta name="description"
                          content={bannerData?.page_data?.meta_description}/>
                    <link rel="canonical" href="http://mirconcreteblock.com/"/>
                    <title>{bannerData?.page_data?.meta_title ? bannerData.page_data.meta_title : 'Mir Concrete Block | Concrete Manufacturing Company in Bangladesh'}</title>
                    <meta property="og:title" content={bannerData?.page_data?.meta_title}/>
                    <meta property="og:description" content={bannerData?.page_data?.og_description}/>
                </Helmet>

                <Container ref={offset}/>
                {len && len === bannerArray.length ?
                    <Slick {...settings}>
                        {bannerArray ? bannerArray.map((item) => (
                            <div key={item.id} className="banner-slider__single-item">
                                <img src={baseUrl + item.path + item.img_name} alt=""/>
                                <Container id="main-container"
                                           className="banner-slider__single-item__content">
                                    <div className="banner-slider__single-item__content__inner">
                                        <h2 dangerouslySetInnerHTML={{__html: item.short_title}}/>
                                        <Button link={'/product'} margin={'40px 0 0 0'} text={'Our Products'}
                                                color={'#FFF'}/>
                                    </div>
                                </Container>
                            </div>
                        )) : ''}
                    </Slick>
                    : ""
                }
            </StyledSlide>

        </>
    );
}

const StyledSlide = styled.div`
  &.banner-slider {
    height: 100vh;
    position: relative;

    .banner-slider__single-item {
      height: 100vh;
      position: relative;
      z-index: 2;

      &:before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1;
      }

      > img {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      &__content {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        bottom: 70px;
        z-index: 25;

        &__inner {
          width: 60%;

          h2 {
            font-size: 40px;
            font-weight: 600;
            margin: 0 0 60px 0;
            line-height: 52px;
            color: #FFF;
            transform: translateY(35px);
            transition: all .8s cubic-bezier(0.16, 1, 0.3, 1);
            opacity: 0;
            letter-spacing: 1px;
          }

          p {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            color: #FFF;
            transform: translateY(35px);
            //transition-delay:.3s;
            transition: all .8s cubic-bezier(0.16, 1, 0.3, 1);
            opacity: 0;
            margin: 0;
          }
        }

        .dc-btn {
          transform: translateY(35px);
          opacity: 0;
          transition: all .8s cubic-bezier(0.16, 1, 0.3, 1);
        }
      }
    }

    .slick-current {
      opacity: 1 !important;
      transition-delay: 0s !important;
      //transition: opacity 100ms ease 0s, visibility 100ms ease 0s !important;
    }

    .slick-active, .slick-current {
      h2, p, .sub-title, .dc-btn {
        transform: none;
        opacity: 1;
      }

      .sub-title {
        transition-delay: .4s;
      }

      h2 {
        transition-delay: .7s;
      }

      p {
        transition-delay: 1s;
      }

      .dc-btn {
        cubic-bezier(0.16, 1, 0.3, 1);
        transition-delay: 1.3s;
      }
    }

    .go-left {
      position: absolute;
      z-index: 2;
      right: ${props => props.offset + 15 || '100'}px;
      top: 0;
      bottom: 0;
      height: fit-content;
      margin: auto;
      cursor: pointer;
    }

    .go-right {
      position: absolute;
      z-index: 2;
      right: ${props => props.offset + 15 || '100'}px;;
      top: 0;
      bottom: -128px;
      height: fit-content;
      margin: auto;
      cursor: pointer;
    }


    @media (max-width: 767px) {
      .banner-slider__single-item__content {
        bottom: 60px;
        height: fit-content;

        &__inner {
          width: 95% !important;
        }
      }

      h2 {
        font-size: 34px !important;
        line-height: 40px !important;
      }

      .sub-title {
        margin-bottom: 20px;
        font-size: 16px;
      }

      .go-right {
        top: auto;
        bottom: 65px;
        right: 15px;
      }

      .go-left {
        top: auto;
        bottom: 130px;
        right: 15px;
      }

      .dc-btn {
        margin-top: 40px;
      }
    }
  }

  @media (min-width: 1430px) {
    .banner-slider__single-item__content {
      top: 100px;
      height: fit-content;
      margin: auto;
    }
  }
`;


function mapStateToProps(state) {
    return {
        message: state.homeReducer.message,
        bannerData: state.homeReducer.bannerData,
        loading: state.homeReducer.loading,
        footerOffset: state.footerReducer.footerOffset
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getHomeBannerSliderData: (params) => dispatch(fetchHomeBannerSlider(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BannerSlider);
