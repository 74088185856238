import React, {useContext} from 'react';
import styled from 'styled-components';
import {Container, Row, Col, Accordion, Card, useAccordionToggle, AccordionContext} from 'react-bootstrap';
import Title from '../../../components/Title';
import htmlParser from 'react-html-parser';
import {baseUrl} from "../../../components/BaseUrl";

// img
import IconCheck from '../../../assets/images/icon-check-green.svg';
import Text from "../../../components/Text";


const LabFacilities = ({post}) => {

    let getPost = post ? post.find(postfilter => postfilter.data.slug === 'lab-facilities') : '';
    let getImg = getPost ? getPost.images ? getPost.images[0] : '' : '';

    return (
        <StyleLabFacilities className="pt-80 pb-80 lab-facilities">
            <Container>
                <Title text={getPost? getPost.data.title:''} fontSize={'36px'} letterSpacing={'1px'}
                       lineHeight={'46px'} fontWeight={'500'} color={'#314550'} margin={'0 0 40px 0'}/>
                <Row>
                    <div className="lab-facilities__single">
                        <Col md={8}>
                            <div className="lab-facilities__single__left">
                                <Text color={'#314550'} fontSize={'16px'} fontWeight={'300'} lineHeight={'27px'}
                                      letterSpacing={'.5px'}>
                                    {htmlParser(getPost ? getPost.data.description:'')}
                                </Text>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="lab-facilities__single__right">
                                <img src={baseUrl + getImg.path + getImg.img_name} alt=""/>
                            </div>
                        </Col>
                    </div>
                </Row>
            </Container>
        </StyleLabFacilities>
    )
};

const StyleLabFacilities = styled.section`
    background-color: #FFFFFF;
    &.lab-facilities{
        .lab-facilities__single{
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            //align-items: center;
            &:not(:last-child){
                margin-bottom: 80px;
            }
            &:nth-of-type(odd){
                flex-direction: row-reverse;
                .lab-facilities__single__left{
                    margin-left: 40px;
                }
            }
            &__left{
                .texts{
                    p{
                        margin-bottom: 30px;
                    }
                    strong{
                        font-size: 16px;
                        font-weight: 500;
                        margin-bottom: 20px;
                    }
                    ul{
                        padding-left: 40px;
                        li{
                            position:relative;
                            margin-bottom: 20px;
                            &:before{
                                content: '';
                                position: absolute;
                                left: -40px;
                                top: 0;
                                width: 20px;
                                height: 15px;
                                background-image: url(${IconCheck});
                            }
                        }
                    }
                }
            }
            &__right{
                position:relative;
                padding-top: calc(362 / 400 * 100%);
                img{
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    object-fit: cover;
                }
            }
        }
    }
    @media(max-width:767px){
        &.lab-facilities{
            padding-top: 60px !important;
            padding-bottom: 60px !important;
            .lab-facilities__single{
            &:not(:last-child){
                margin-bottom: 60px;
            }
                &:nth-of-type(odd){
                    .lab-facilities__single__left{
                        margin-left: 0;
                    }
                }
                &__left{
                margin-bottom: 20px;
                }
            }
        }
    }
`;
export default LabFacilities;
