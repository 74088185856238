import React, {Component} from 'react';
import InnerBanner from '../../components/InnerBanner';
import Jobs from './components/Jobs';
import Menu from "../../components/Menu";
import {fetchCareerPageData, fetchCareerListData} from "../../redux/actions/career";
import {connect} from 'react-redux'
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import {motion} from 'framer-motion';
import {PageAnimation} from "../../components/PageAnimation";
import PageDescription from "../../components/PageDescription";
import BlurImg from "../../assets/images/blur.jpg";
import {Helmet} from "react-helmet";
import {Loading} from "../../components/Loading";

class Career extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);

        let param = {
            [ApiParams.page_type]: 'career'
        }
        let child = {
            [ApiParams.page_id]: '62'
        }
        this.props.getCareerPageData(param);
        this.props.getCareerChildPage(child)
    };

    render() {
        let {careerPage} = this.props;
        let {careerPageList} = this.props;

        let careerData = careerPage ? careerPage.page_data : null;
        let careerPageSub = careerData ? careerData.title : '';
        let careerPageSubTitle = careerData ? careerData.sub_title : '';
        let careerPageDescription = careerData ? careerData.description : '';

        let careerPageImg = careerPage ?
            careerPage.images ?
                careerPage.images.list ? careerPage.images.list[0] : null
                : null
            : null;

        let careerBanner = careerPageImg ? baseUrl + careerPageImg.path + careerPageImg.img_name : BlurImg


        return (
            <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                {this.props.loading && <Loading/>}
                <Helmet>
                    <meta name="description"
                          content={careerPage?.page_data?.meta_description}/>
                    <link rel="canonical" href="http://mirconcreteblock.com/career"/>
                    <title>{careerPage?.page_data?.meta_title ? careerPage.page_data.meta_title : 'Career | Mir Concrete Block'}</title>
                    <meta property="og:title"
                          content={careerPage?.page_data?.og_title ? careerPage.page_data.og_title : 'Career | Mir Concrete Block'}/>
                    <meta property="og:description" content={careerPage?.page_data?.og_description}/>
                </Helmet>
                <Menu menuType={'menu-fixed'}/>
                <InnerBanner
                    title={careerPageSub}
                    image={careerBanner}
                />
                <PageDescription pageDesc={{
                    title: `${careerPageSubTitle}`,
                    description: `${careerPageDescription}`
                }}/>
                <>
                    <Jobs jobs={careerPageList}/>
                </>
            </motion.div>
        )
    }
};

function mapStateToProps(state) {
    return {
        careerPage: state.careerReducer.careerPage,
        careerPageList: state.careerReducer.careerPageList,
        loading: state.careerReducer.loading
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getCareerPageData: params => dispatch(fetchCareerPageData(params)),
        getCareerChildPage: params => dispatch(fetchCareerListData(params))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Career);
