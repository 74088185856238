import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col, ListGroup} from 'react-bootstrap';
import VisibilitySensor from "react-visibility-sensor";
import Title from '../../../components/Title';
import htmlParser from 'react-html-parser';

const Accordion = ({pageData}) => {
    return (
        <StyleInfoList className="pt-80 pb-80">
            <Container>
                <Title text={'General Instructions for Laying Concrete Block'} fontSize={'36px'} letterSpacing={'1px'}
                       lineHeight={'46px'} fontWeight={'500'} color={'#314550'} margin={'0 0 40px 0'}/>
                <Row>
                    <Col md={12}>
                        <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                            <ListGroup variant="flush" className="info-list">
                                {htmlParser(pageData ? pageData.description : '')}
                            </ListGroup>
                        </VisibilitySensor>
                    </Col>
                </Row>
            </Container>
        </StyleInfoList>
    )
};

const StyleInfoList = styled.section`
  .info-list {
    .list-group-item {
      padding: 20px;
      border: 0;
      font-size: 16px;
      font-weight: 300;
      line-height: 27px;
      letter-spacing: .25px;
      color: #000000;

      &:nth-of-type(odd) {
        background-color: #F9F9F9;
      }
    }
  }
`;
export default Accordion;
