import {CAREER} from "../../constants/career";

let initialState = {
    careerPage: [],
    careerPageLoading: false,
    careerPageErr: '',
    careerPageList: [],
    careerPageListLoading: false,
    careerPageListErr: '',
    loading: false,
}

export default function careerReducer(state = initialState, action) {
    switch (action.type) {
        case CAREER.FETCH_CAREER_PAGE.MAIN:
            return {
                ...state,
                careerPage: [],
                careerPageLoading: true,
                careerPageErr: '',
                loading: true
            }

        case CAREER.FETCH_CAREER_PAGE.SUCCESS:
            return {
                ...state,
                careerPage: action.result,
                careerPageLoading: false,
                careerPageErr: '',
                loading: false
            }

        case CAREER.FETCH_CAREER_PAGE.FAILURE:
            return {
                ...state,
                careerPage: [],
                careerPageLoading: false,
                careerPageErr: 'Error from career page fetching',
                loading: false
            }

        case CAREER.FETCH_CAREER_LIST.MAIN:
            return {
                ...state,
                careerPageList: [],
                careerPageListLoading: true,
                careerPageListErr: ''
            }

        case CAREER.FETCH_CAREER_LIST.SUCCESS:
            return {
                ...state,
                careerPageList: action.result,
                careerPageListLoading: false,
                careerPageListErr: ''
            }

        case CAREER.FETCH_CAREER_LIST.FAILURE:
            return {
                ...state,
                careerPageList: [],
                careerPageListLoading: false,
                careerPageListErr: 'Error from career list fetching'
            }

        default:
            return state
    }
}
