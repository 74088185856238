import {MEDIA} from "../../constants/media";

export function fetchMediaPageData(params) {
  return {
    type: MEDIA.FETCH_MEDIA_PAGE.MAIN,
    params
  }
}

export function fetchMediaListData(params) {
  return {
    type: MEDIA.FETCH_MEDIA_LIST.MAIN,
    params
  }
}

export function fetchMediaDetailsData(params) {
  return {
    type: MEDIA.FETCH_DETAILS_PAGE.MAIN,
    params
  }
}
