import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {baseUrl} from "../../../components/BaseUrl";


import {
    LightgalleryProvider,
    LightgalleryItem,
    withLightgallery,
    useLightgallery
} from "react-lightgallery";
import PT from "prop-types";

import SubTitle from '../../../components/SubTitle';

// css
import "lightgallery.js/dist/css/lightgallery.css";


const PhotoItem = ({image, thumb, group}) => (
    <div style={{maxWidth: "250px", width: "200px", padding: "5px"}}>
        <LightgalleryItem group={group} src={image} thumb={thumb}>
            <img src={image} style={{width: "100%"}}/>
        </LightgalleryItem>
    </div>
);
PhotoItem.propTypes = {
    image: PT.string.isRequired,
    thumb: PT.string,
    group: PT.string.isRequired
};


const OpenButtonWithHoc = withLightgallery(({group, openGallery, ...props}) => {
    return (
        <button
            {...props}
            onClick={() => {
                openGallery(group);
            }}
            className={["btn gallery__single__img", props.className || ""].join(" ")}
        >
            <img src={props.thumbImg} alt=""/>
        </button>
    );
});


const PhotoGallery = ({data}) => {
    return (
        <StyledPhotoGallery className="gallery pt-80">
            <Container>
                <Row>
                    <Col md={12} className="gallery__tab">
                        <Link to={'#'} className={'active'}>Photos</Link>
                        <Link to={'/gallery/videos'}>Videos</Link>
                    </Col>
                    <LightgalleryProvider
                        onBeforeOpen={() => console.info("onBeforeOpen")}
                        onAfterOpen={() => console.info("onAfterOpen")}
                        onSlideItemLoad={() => console.info("onSlideItemLoad")}
                        onBeforeSlide={() => console.info("onBeforeSlide")}
                        onAfterSlide={() => console.info("onAfterSlide")}
                        onBeforePrevSlide={() => console.info("onBeforePrevSlide")}
                        onBeforeNextSlide={() => console.info("onBeforeNextSlide")}
                        onDragstart={() => console.info("onDragstart")}
                        onDragmove={() => console.info("onDragmove")}
                        onDragend={() => console.info("onDragend")}
                        onSlideClick={() => console.info("onSlideClick")}
                        onBeforeClose={() => console.info("onBeforeClose")}
                        onCloseAfter={() => console.info("onCloseAfter")}
                    >
                        {data ? data.map((posts, index) => (
                            <Col key={posts.page_data ? posts.page_data.id : ''} sm={6} xs={6} md={4}>
                                <div className="gallery__single">

                                    {posts.images ? posts.images.slice(0, 1).map(i => (
                                        <OpenButtonWithHoc thumbImg={baseUrl + i.path + i.img_name}
                                                           group={'group' + index}/>
                                    )) : []}

                                    <div className="gallery__single__title">
                                        <SubTitle text={posts.data.title} fontSize={'20px'} fontWeight={'500'}
                                                  color={'#314550'} lineHeight={'26px'} letterSpacing={'.25px'}/>
                                        <span>

                                         <span className="item-number">
                                             {posts.images ? posts.images.length : '0'}+
                                         </span>


                                        <div className={'gallery-group'}
                                             style={{
                                                 display: "none"
                                             }}
                                        >
                                            {posts.images ? posts.images.map((img) => (
                                                <PhotoItem key={img.id} image={baseUrl + img.path + img.img_name}
                                                           group={'group' + index}/>
                                            )) : ''}


                                        </div>

                                    </span>
                                    </div>

                                </div>
                            </Col>
                        )) : ''}


                    </LightgalleryProvider>
                </Row>
            </Container>
        </StyledPhotoGallery>
    )
};

const StyledPhotoGallery = styled.section`
  &.gallery {
    .gallery__tab {
      text-align: center;
      margin-bottom: 60px;

      a {
        padding: 6px 50px;
        background-color: #F9F9F9;
        font-size: 20px;
        color: #314550;
        font-weight: 300;
        line-height: 26px;
        letter-spacing: .25px;

        &.active {
          background-color: #008C45;
          color: #FFFFFF;
        }
      }
    }

    .gallery__single {
      button {
        background-color: #DDD;
      }

      &__img {
        position: relative;
        padding-top: calc(250 / 370 * 100%);
        width: 100%;

        img {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__title {
        display: flex;
        justify-content: space-between;

        span {
          font-size: 16px;
          font-weight: 300;
          line-height: 27px;
          color: #314550;
        }

        padding-top: 10px;
        padding-bottom: 20px;
        margin-bottom: 60px;
        border-bottom: 1px solid #DBDBDB;
      }
    }
  }

  @media (max-width: 767px) {
    &.gallery {
      .gallery__single {
        &__title {
          margin-bottom: 30px;
          padding-bottom: 10px;

          h3 {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  @media (max-width: 414px) {
    &.gallery {
      .gallery__tab {
        display: flex;

        a {
          width: 100%;
        }
      }
    }
  }

  .item-number {
    font-size: 16px;
    font-weight: 300;
  }
`;

export default PhotoGallery;
