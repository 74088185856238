import React, {Component} from 'react';
import InnerBanner from '../../components/InnerBanner';
import PhotoGallery from './components/PhotoGallery';
import Menu from "../../components/Menu";
import BannerImg from "../../assets/images/dynamic/inner-banner.jpg";
import {connect} from 'react-redux'
import {fetchServicesPageData} from "../../redux/actions/services";
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import PageDescription from "../../components/PageDescription";
import BlurImg from "../../assets/images/blur.jpg";
import NewsBannerImg from "../../assets/images/dynamic/news/Banner Img.jpg";
import {fetchGallery, fetchPhoto} from "../../redux/actions/gallery";
import {Helmet} from "react-helmet";
import {Loading} from "../../components/Loading";

class Gallery extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);

        if (this.props.photoGallery === null || this.props.photoGallery.length === 0) {
            let mainParam = {
                [ApiParams.page_type]: 'gallery'
            };
            let photo = {
                [ApiParams.page_type]: 'image-gallery'
            };

            this.props.getGallery(mainParam);
            this.props.getPhoto(photo);
        } else {
            // console.log("Api not Calling......")
        }
    }


    render() {

        let {gallery} = this.props;
        let {photoGallery} = this.props;

        let PhotoPageData = photoGallery ? photoGallery.page_data : '';
        let photoGalleryBanner = photoGallery ? photoGallery.images ? photoGallery.images.list[0] : '' : '';
        let postList = photoGallery ? photoGallery.posts ? photoGallery.posts.list : '' : '';

        return (
            <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                {this.props.loading && <Loading/>}
                <Helmet>
                    <meta name="description"
                          content={gallery?.page_data?.meta_description}/>
                    <link rel="canonical" href="http://mirconcreteblock.com/gallery"/>
                    <title>{gallery?.page_data?.meta_title ? gallery.page_data.meta_title : 'Photo Gallery | Mir Concrete Block'}</title>
                    <meta property="og:title"
                          content={gallery?.page_data?.og_title ? gallery.page_data.og_title : 'Photo Gallery | Mir Concrete Block'}/>
                    <meta property="og:description" content={gallery?.page_data?.og_description}/>
                </Helmet>
                <Menu menuType={'menu-fixed'}/>
                <InnerBanner
                    title={PhotoPageData ? PhotoPageData.title : ''}
                    image={photoGalleryBanner ? baseUrl + photoGalleryBanner.path + photoGalleryBanner.img_name : BlurImg}

                />
                <>
                    <PhotoGallery data={postList}/>
                </>
            </motion.div>
        )
    }
}

function mapStateToProps(state) {
    return {
        gallery: state.galleryReducer.gallery,
        photoGallery: state.galleryReducer.photoGallery,
        loading: state.galleryReducer.galleryLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getGallery: params => dispatch(fetchGallery(params)),
        getPhoto: params => dispatch(fetchPhoto(params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Gallery);

