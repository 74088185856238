import {EQUIPMENTS} from "../../constants/equipments";

let initialState = {
    equipments: [],
    equipmentsLoading: false,
    equipmentsErr: ''
};

export default function equipmentsReducer(state = initialState, action) {
    switch (action.type) {
        case EQUIPMENTS.FETCH_EQUIPMENTS.MAIN:
            return {
                ...state,
                equipments: [],
                equipmentsLoading: true,
                equipmentsErr: ''
            };

        case EQUIPMENTS.FETCH_EQUIPMENTS.SUCCESS:
            return {
                ...state,
                equipments: action.result,
                equipmentsLoading: false,
                equipmentsErr: ''
            };

        case EQUIPMENTS.FETCH_EQUIPMENTS.FAILURE:
            return {
                ...state,
                equipments: [],
                equipmentsLoading: false,
                equipmentsErr: 'Error from Server'
            };
        default:
            return state
    }
}