import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col, Form} from 'react-bootstrap';
import Title from '../../../components/Title';
import {fetchFormData, postFormSubmit} from '../../../redux/actions/form';
import {connect} from "react-redux";
import {ApiParams} from "../../../constants/apiParams";
import Message from '../../../components/PopupMessage';
import {Loading} from '../../../components/Loading';
import {emailValidation, emptyValidation, emptyNumber} from '../../../config/validator';
import Button from "../../../components/Button";
import BtnArrowWhite from "../../../assets/images/btn-arrow-white.svg";


const ContactForm = (props) => {

    useEffect(() => {

        let mainParam = {
            [ApiParams.form_slug]: 'contact-form'

        };
        props.getFormData(mainParam);

    }, []);

    let {formFields, submitForm, formSubmitErr, popup} = props;
    let [state, setState] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    let handleChange = e => {
        let value = e.target.value
        setState({
            ...state,
            [e.target.name]: value
        })
    };

    let handleSelect = (name, value) => {
        setState({
            ...state,
            [name]: value
        })
    }

    let handleInit = () => {
        setState({
            name: '',
            email: '',
            phone: '',
            message: ''
        })
    }


    let handleSubmit = (e) => {
        e.preventDefault()

        e.preventDefault()
        let formField = {
            form_id: 'contact-form'
        }
        let len = props.formFields.length;

        for (let i = 0; i < len; i++) {
            formField[props.formFields[i]["field_key"]] = ""
        }

        formField["name"] = state.name;
        formField["email"] = state.email;
        formField["phone"] = state.phone;
        formField["message"] = state.message;

        let bodyFormData = new FormData()

        for (let key in formField) {
            bodyFormData.append(`${key}`, formField[key])
        }

        props.postFormSubmit(bodyFormData);
        // submitForm(formField)
        // handleInit()
        handleInit();
        handleEmailBlur();
        handleEmptyName();
        handleEmptyNum();
        handleLoaderBeforeSubmit();
    };

    let [emailErr, setEmailErr] = useState(true);
    let [empty, setEmpty] = useState(false);
    let [emptyNum, setEmptyNum] = useState('');

    let handleEmailBlur = () => {
        let isValid = emailValidation(state.email);
        isValid ? setEmailErr(true) : setEmailErr(false)
    };

    let handleEmptyName = () => {
        let isEmpty = emptyValidation(state.name);
        isEmpty ? setEmpty(true) : setEmpty(false)
    };

    let handleEmptyNum = () => {
        let isEmptyNum = emptyValidation(state.phone);
        isEmptyNum ? setEmptyNum(true) : setEmptyNum(false)
    };

    let [loading, setLoading] = useState(false);

    let handleLoaderBeforeSubmit = () => {
        setLoading(true)
    };


    return (
        <StyleContactForm className="contact-form pb-80 pt-80">

            {!popup ? loading ? <Loading/> : '' : null}
            <Message/>

            <Container>
                <Row className="contact-form__row">
                    <Col sm={6}>
                        <div className="job-form">
                            <Title text={'Get in touch'} fontSize={'36px'} fontWeight={500}
                                   lineHeight={'46px'} letterSpacing={'1px'} color={'#314550'} margin={'0 0 40px 0'}/>

                            <div className="job-form-fields">
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group>
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            type='text'
                                            placeholder="Enter your name here"
                                            name="name"
                                            value={state.name}
                                            onChange={handleChange}
                                            className={empty ? 'has-error' : ''} onBlur={handleEmptyName}
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Email ID</Form.Label>
                                        <Form.Control
                                            type='email'
                                            placeholder="Enter your email ID here"
                                            name="email"
                                            value={state.email}
                                            onChange={handleChange}
                                            className={!emailErr ? 'has-error' : ''} onBlur={handleEmailBlur}
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Contact number</Form.Label>
                                        <Form.Control
                                            type='text'
                                            placeholder="Enter your contact number here"
                                            name="phone"
                                            value={state.phone}
                                            onChange={handleChange}
                                            className={emptyNum ? 'has-error' : ''} onBlur={handleEmptyNum}
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Message</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            placeholder='Enter your message here'
                                            name="message"
                                            value={state.message}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <div className='bb' onClick={handleSubmit}>
                                            <Button onSubmit={handleSubmit} img={BtnArrowWhite} margin={'40px 0 0 0'}
                                                    text={'Send'}
                                                    background={'#008C45'} width={'100%'}
                                                    color={'#008C45'} border={'2px solid #008C45'}
                                                    hoverBackground={'#008C45'} hoverColor={'#ffffff'}/>
                                        </div>
                                        {/*<button onSubmit={handleSubmit}>submit</button>*/}
                                    </Form.Group>

                                </Form>
                            </div>
                        </div>
                    </Col>

                </Row>
            </Container>
        </StyleContactForm>
    )
};

const StyleContactForm = styled.section`
        &.contact-form{
            .contact-form__row{
                justify-content: center;
            }
            .job-form{
                .job-form-fields{
                    label{
                        font-size: 14px;
                        line-height: 24px;
                        font-weight: 300;
                        color: #000000;
                    }
                    input, textarea{
                        height: 50px;
                        border-radius: 0;
                        background-color: transparent;
                        border-color: #D9D9D9;
                        &::placeholder {
                            font-size: 14px;
                            line-height: 24px;
                            color: rgba(0,0,0,0.51);
                        }
                    }
                    textarea{
                        height: 150px;                
                    }
                    &__select{
                        .css-yk16xz-control , .css-1pahdxg-control{
                            background-color: transparent;
                        }
                        .css-g1d714-ValueContainer{
                            padding: 0;
                            .css-b8ldur-Input{
                                margin: 0;
                                padding: 0;
                            }
                        }
                    }
                    &__file{
                        border: 1px solid #D9D9D9;
                        height: 50px;
                        overflow: hidden;
                        .form-file-label{
                            margin-bottom: 0;
                            display: block;
                            height: 100%;
                            line-height: 50px;
                            text-align: center;
                            font-size: 14px;
                            color: rgba(0,0,0,0.51);
                        }
                    }
                }
            }
        }
  
`;


function mapStateToProps(state) {
    return {
        formFields: state.formReducer.formFields,
        popup: state.formReducer.popup
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getFormData: params => dispatch(fetchFormData(params)),
        postFormSubmit: data => dispatch(postFormSubmit(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);


