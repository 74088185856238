import {PRODUCT} from "../../constants/product";

let initialState = {
    ProductPage:[],
    ProductPageLoading: false,
    ProductPageErr: '',
    productList: [],
    productListLoading: false,
    productListErr: '',
};

export default function productReducer(state = initialState, action) {
    switch (action.type) {
        case PRODUCT.FETCH_PRODUCT_PAGE.MAIN:
            return {
                ...state,
                ProductPage: [],
                ProductPageLoading: true,
                ProductPageErr: ''
            };

        case PRODUCT.FETCH_PRODUCT_PAGE.SUCCESS:
            return {
                ...state,
                ProductPage: action.result,
                ProductPageLoading: false,
                ProductPageErr: ''
            };

        case PRODUCT.FETCH_PRODUCT_PAGE.FAILURE:
            return {
                ...state,
                ProductPage: [],
                ProductPageLoading: false,
                ProductPageErr: 'err from server'
            };
        case PRODUCT.FETCH_PRODUCT_LIST.MAIN:
            return {
                ...state,
                productList: [],
                productListLoading: true,
                productListErr: ''
            };

        case PRODUCT.FETCH_PRODUCT_LIST.SUCCESS:
            return {
                ...state,
                productList: action.result,
                productListLoading: false,
                productListErr: ''
            };

        case PRODUCT.FETCH_PRODUCT_LIST.FAILURE:
            return {
                ...state,
                productList: [],
                productListLoading: false,
                productListErr: 'err from server'
            };
        default:
            return state
    }
}