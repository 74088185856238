import {ABOUT} from "../../constants/about";

let initialState = {
  aboutMain: [],
  aboutMainLoading: false,
  aboutMainErr: '',
  aboutChairmanMessage: [],
  aboutChairmanMessageLoading: false,
  aboutChairmanMessageErr: '',
  aboutAchievement: [],
  aboutAchievementLoading: false,
  aboutAchievementErr: '',
  aboutDirectors: [],
  aboutDirectorsLoading: false,
  aboutDirectorsErr: ''
};

export default function aboutReducer(state = initialState, action) {
  switch (action.type) {
    case ABOUT.FETCH_ABOUT_PAGE.MAIN:
      return {
        ...state,
        aboutMain: [],
        aboutMainLoading: true,
        aboutMainErr: ''
      };

    case ABOUT.FETCH_ABOUT_PAGE.SUCCESS:
      return {
        ...state,
        aboutMain: action.result,
        aboutMainLoading: false,
        aboutMainErr: ''
      };

    case ABOUT.FETCH_ABOUT_PAGE.FAILURE:
      return {
        ...state,
        aboutMain: [],
        aboutMainLoading: false,
        aboutMainErr: 'Error from Server'
      };

    case ABOUT.FETCH_CHAIRMAN_MESSAGE.MAIN:
      return {
        ...state,
        aboutChairmanMessage: [],
        aboutChairmanMessageLoading: true,
        aboutChairmanMessageErr: ''
      };

    case  ABOUT.FETCH_CHAIRMAN_MESSAGE.SUCCESS:
      return {
        ...state,
        aboutChairmanMessage: action.result,
        aboutChairmanMessageLoading: false,
        aboutChairmanMessageErr: ''
      };

    case ABOUT.FETCH_CHAIRMAN_MESSAGE.FAILURE:
      return {
        ...state,
        aboutChairmanMessage: [],
        aboutChairmanMessageLoading: false,
        aboutChairmanMessageErr: 'Error from server'
      };

    case ABOUT.FETCH_ACHIEVEMENTS.MAIN:
      return {
        ...state,
        aboutAchievement: [],
        aboutAchievementLoading: true,
        aboutAchievementErr: ''
      };

    case  ABOUT.FETCH_ACHIEVEMENTS.SUCCESS:
      return {
        ...state,
        aboutAchievement: action.result,
        aboutAchievementLoading: false,
        aboutAchievementErr: ''
      };

    case ABOUT.FETCH_ACHIEVEMENTS.FAILURE:
      return {
        ...state,
        aboutAchievement: [],
        aboutAchievementLoading: false,
        aboutAchievementErr: 'Error from server'
      };

    case ABOUT.FETCH_DIRECTORS.MAIN:
      return {
        ...state,
        aboutDirectors: [],
        aboutDirectorsLoading: true,
        aboutDirectorsErr: ''
      };

    case  ABOUT.FETCH_DIRECTORS.SUCCESS:
      return {
        ...state,
        aboutDirectors: action.result,
        aboutDirectorsLoading: false,
        aboutDirectorsErr: ''
      };

    case ABOUT.FETCH_DIRECTORS.FAILURE:
      return {
        ...state,
        aboutDirectors: [],
        aboutDirectorsLoading: false,
        aboutDirectorsErr: 'Error from server'
      };

    default:
      return state
  }
}
