import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import Title from '../../../components/Title';
import htmlParser from 'react-html-parser';
import {baseUrl} from "../../../components/BaseUrl";
import BlurImg from "../../../assets/images/blur.jpg";

const ConcernList = ({post}) => {
    return (
        <StyledConcern className="pt-80">
            <Container>
                <Title text={'List of Concerns'} fontSize={'36px'} letterSpacing={'1px'}
                       lineHeight={'46px'} fontWeight={'500'} color={'#314550'} margin={'0 0 40px 0'}/>
                <Row>
                    {post ? post.map(data=>(
                        <Col key={data.data.id} md={6}>
                            <div className="concern-single">
                                <a href={data.data.short_des} target="_blank">
                                    <span>{htmlParser(data.data.title)}</span>
                                </a>
                                <div className="concern-single__image">
                                    {
                                        data?.images?
                                            <img src={baseUrl+data.images[0].path+data.images[0].img_name} alt=""/>
                                            :
                                            <img src={BlurImg} alt=""/>
                                    }

                                </div>
                            </div>
                        </Col>
                    )):[]}


                </Row>
            </Container>
        </StyledConcern>
    )
};

const StyledConcern = styled.section`
    padding-bottom: 50px;
    .concern-single{
        position: relative;
        margin-bottom: 30px;
        .concern-single__image{
            padding-top: calc(300 / 570 * 100%);
            position: relative;
            overflow: hidden;
            img{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all 1s ease-in-out;
            }
        }
        a{
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: rgba(0, 0, 0, .5);
            display: flex;
            padding: 50px 40px;
            align-items: flex-end;
            z-index: 1;
            span{
                color: #ffffff
            }
            &:hover{
                & + .concern-single__image{
                    img{
                        transform:scale(1.05);
                    }
                }
            }
        }
    }
`;
export default ConcernList;
