import React, {Component} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import Title from '../../../components/Title';
import Links from '../../../components/Link';
import {fetchHomeAboutUsData} from '../../../redux/actions/home/';
import {ApiParams} from "../../../constants/apiParams";
import {connect} from "react-redux";
import {baseUrl} from '../../../components/BaseUrl';
// img
import leftBg from '../../../assets/images/home-about-pattern.svg';
import Button from "../../../components/Button";
import Text from "../../../components/Text";

class AboutUs extends Component {
    constructor(props){
        super(props)
        this.containerOffset = React.createRef();
        this.state = {offSet: null};
    }
    componentDidMount() {
        let params = {
            [ApiParams.page_type]: 'home-about'
        };
        this.props.getHomeAboutUsData(params);
        this.setState({
            offSet: this.containerOffset.current.offsetLeft
        });
    }

    render() {
        // const node = this.container.current;
        let {about} = this.props;
        const aboutPageData = about ? about.page_data : null;
        const aboutImage = about ? about.images ? about.images.list : [] : [];
        const len = aboutImage.length;

        return (
            <StyledAboutUs offset={this.state.offSet}>
                <Container ref={this.containerOffset}/>
                <Container fluid>

                    <Row>
                        <Col sm={6} className="about-us-left pt-110 pb-110">

                            <img src={leftBg} alt=""/>
                            <Title
                                color={'#FFF'}
                                fontSize={'35px'}
                                fontWeight={'500'}
                                lineHeight={'46px'}
                                letterSpacing={'1px'}
                                margin={'0 0 40px 0'}
                                text={<span
                                dangerouslySetInnerHTML={{__html: aboutPageData ? aboutPageData.title : ''}}/>}/>
                            <div className="about-us-left__content">
                                {/*<h4>Green Environment Initiative</h4>*/}
                                {aboutPageData ?
                                    <Text color={'#ffffff'} fontSize={'16px'} fontWeight={'300'} lineHeight={'27px'}>
                                        <div
                                            dangerouslySetInnerHTML={{__html: aboutPageData ? aboutPageData.short_desc : ''}}/>
                                    </Text>
                                    : ''}
                            </div>
                            <Button link={'/about-us'} margin={'40px 0 0 0'} text={'Explore'} background={'#ffffff'}
                                    color={'#ffffff'}/>
                        </Col>

                        <Col sm={6} className="about-us-right">
                            {len && len === aboutImage.length ? (
                                <>
                                    {aboutImage.map(img =>
                                        <img key={img.id} src={baseUrl + img.path + img.img_name} alt=""/>
                                    )}
                                </>
                            ) : null}


                        </Col>

                    </Row>


                </Container>
            </StyledAboutUs>
        )
    }


};

const StyledAboutUs = styled.div`
     .about-us-left{
        background-color: #008C45;
        position: relative;
        padding-left:${props => props.offset + 15 || '100'}px;
        overflow: hidden;
        
      >img{
        height: 100%;
        //width: 100%;
        position: absolute;
        object-fit: contain;
        top: 0;
        left: -50px;
      }
      .title{
        z-index: 2;
        position: relative;
      }
      &__content{
        padding-right: 55px;
        position: relative;
        z-index: 2;
        padding-bottom: 20px;
        &:nth-last-of-type(1){
          margin-bottom: 0;
        }
        h4{
          font-size: 20px;
          font-weight: bold;
          margin: 0 ;
          color: #ffffff;
          text-transform: capitalize;
          line-height: 30px;
        }
        a:hover{
          letter-spacing: 0;
        }
        
      }
     }
     .about-us-right{
      position: relative;
        >img{
          height: 100%;
          width: 100%;
          position: absolute;
          object-fit: cover;
          top: 0;
          left: 0;
        }
     }
     @media(max-width:1024px){
      .about-us-left{
        padding-left: 45px;
      }
     }
     @media(max-width:991px){
      .about-us-left{
        padding-left: 40px;
      }
     }
     
     @media(max-width:767px){
        .title{
          margin-bottom: 40px;
        }
        .about-us-left{
            min-width: 100%;
            padding: 60px 15px;
            &__content{
               padding-right: 0;
            }
            .dc-btn{
                a{
                  width: 100%;
                }
            }
        }
        .about-us-right{
            //display: none;
            min-width: 100%;
            min-height: 400px;
        }
        .about-us-left__content:after{
          right: 0;
        }
     }
   
`;


function mapStateToProps(state) {
    return {
        footerOffset: state.footerReducer.footerOffset,
        about: state.homeReducer.aboutData
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getHomeAboutUsData: (params) => dispatch(fetchHomeAboutUsData(params))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
