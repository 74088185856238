import {CONCERN} from "../../constants/concern";

let initialState = {
    concern: [],
    concernLoading: false,
    concernErr: ''
};

export default function concernReducer(state = initialState, action) {
    switch (action.type) {
        case CONCERN.FETCH_CONCERN.MAIN:
            return {
                ...state,
                concern: [],
                concernLoading: true,
                concernErr: ''
            };

        case CONCERN.FETCH_CONCERN.SUCCESS:
            return {
                ...state,
                concern: action.result,
                concernLoading: false,
                concernErr: ''
            };

        case CONCERN.FETCH_CONCERN.FAILURE:
            return {
                ...state,
                concern: [],
                concernLoading: false,
                concernErr: 'err from server'
            };
        default:
            return state
    }
}