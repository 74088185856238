import React from 'react';
import {hover, darkGreen} from '../globalStyleVars';
import styled from 'styled-components';
import VisibilitySensor from "react-visibility-sensor";

const Text = ({children, text, fontSize, fontWeight, color, letterSpacing, lineHeight, textTransform, margin, textAlign}) => {
    return (
        <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
            {({isVisible}) =>
                <StyledText
                    fontSize={fontSize}
                    fontWeight={fontWeight}
                    color={color}
                    lineHeight={lineHeight}
                    letterSpacing={letterSpacing}
                    textTransform={textTransform}
                    margin={margin}
                    textAlign={textAlign}
                    className={`${isVisible ? 'anim-active' : ''} fade-up texts`}>{text}
                    {children}
                </StyledText>
            }
        </VisibilitySensor>
    )
};

const StyledText = styled.div`
    p{
        font-size: ${props => props.fontSize || '16px'};
        font-weight:${props => props.fontWeight || '400'};
        margin:${props => props.margin || '0 0 20px 0'};
        line-height:${props => props.lineHeight || '24px'};
        color:${props => props.color || '#000000'};
        text-transform: ${props => props.textTransform || 'initial'};
        text-align: ${props => props.textAlign || 'left'};
        letter-spacing: ${props => props.letterSpacing || 'normal'};
        &:nth-last-of-type(1){
          margin-bottom: 0;
        }
    }
    
    @media(max-width:767px){
      p{
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
      }
    }
    
`;

export default Text;
